import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dateFns from 'date-fns'
import Loader from '../../components/PatientData/Loader'
import {
  A_GetClinicsList,
  A_SetViewingClinic,
  A_FetchClinicScheduleConflictList,
  A_PassScheduleData,
  A_ResetDeletionProp,
  A_checkCanDeleteScheduleChange,
  A_DeleteBookedSchedule,
  A_DeleteBookedScheduleChange,
} from '../../actions/index'
import ScheduleHeader from './ScheduleHeader'
import ScheduleChange from './ScheduleChange'
import moment from 'moment'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import * as func from '../ModalOverlay'
import {
  getInitials,
  getDateForMonthStart,
  getCurrentDateandWeek,
  getFullName,
} from '../../utilities/ReusableFunctions'
import Avatar from '../../components/Avatar'
import AlertModal from '../../components/Modal/Alert'
import TutorialBtn from '../Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'
import { TutorialContext } from '../../contexts'
import Placeholder from '../../components/Tutorials/Placeholder'

import { fetchClinicScheduleMonth } from '../../services/ClinicScheduleService'

let colours = []

class MonthlySchedule extends Component {
  constructor(props) {
    super(props)

    this.scheduleCalendarRef = null

    this.state = {
      weeks: 0,
      cells: [],
      dates: [],
      clinicians: [],
    }

    colours = []
    this.filterClinic = this.filterClinic.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.onScheduleChange = this.onScheduleChange.bind(this)
    this.showBlankScheduleChangeModal = this.showBlankScheduleChangeModal.bind(
      this,
    )
    this.setScheduleCalendarRef = this.setScheduleCalendarRef.bind(this)
    this.closeAlert = this.closeAlert.bind(this)
  }

  filterClinic(id) {
    const { actions } = this.props

    actions.A_SetViewingClinic(Number(id))
  }

  componentDidMount() {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      this.getClinicianSchedules(this.props)
    })
  }

  componentDidUpdate(props) {
    const { scheduleCalendarDateHeader, global } = this.props

    if (
      props.global.currentClinicID !== global.currentClinicID ||
      scheduleCalendarDateHeader.month !==
        props.scheduleCalendarDateHeader.month ||
      scheduleCalendarDateHeader.year !== props.scheduleCalendarDateHeader.year
    ) {
      this.getClinicianSchedules(this.props)
      this.scheduleCalendarRef.scrollTop = 0
    }
  }

  getClinicianSchedules(props) {
    const {
      global: { clinicianIDs },
      scheduleCalendarDateHeader: { year, month },
    } = props
    const monthFormat = 'MM'
    const yearFormat = 'YYYY'
    const date = getDateForMonthStart(month, year)
    let selectedMonth = dateFns.format(date, monthFormat)
    let selectedYear = dateFns.format(date, yearFormat)

    this.setState(
      {
        weeks: 0,
        dates: [],
        cells: [],
        clinicians: [],
      },
      () => {
        if (clinicianIDs.length > 0) {
          fetchClinicScheduleMonth(
            selectedYear,
            selectedMonth,
            clinicianIDs,
          ).then(response => {
            this.setState(response)
          })
        }
      },
    )
  }

  mapClinicColour(clinic) {
    let count = colours.length + 1
    if (!colours.filter(colour => colour.id === clinic.id)[0]) {
      let colourClass = `schedule__item--secondary-${count}`
      colours.push({
        id: clinic.id,
        name: clinic.name,
        colourClass: colourClass,
      })
    }
  }

  getScheduleItemColour(scheduleItem) {
    let scheduleItemColourClass = 'schedule__item--primary'
    if (
      scheduleItem.location &&
      scheduleItem.location.id !== this.props.global.currentClinicID
    ) {
      let col = colours.filter(colour => colour.id === scheduleItem.location.id)
      if (col.length > 0) {
        scheduleItemColourClass = col[0].colourClass
      }
    } else if (scheduleItem.option === 1) {
      scheduleItemColourClass = 'schedule__item--tertiary'
    } else if (scheduleItem.option === 2) {
      scheduleItemColourClass = 'schedule__item--unavailable'
    }
    return scheduleItemColourClass
  }

  showBlankScheduleChangeModal() {
    const {
      global: { timezone },
    } = this.props
    this.showScheduleChangeModal(
      {
        date: moment(getCurrentDateandWeek().currentDate).tz(timezone),
        option: 0,
        change: null,
      },
      -1,
    )
  }

  showScheduleChangeModal(cell, clinician) {
    const {
      actions,
      global: { timezone },
    } = this.props

    // Is the date in the past
    if (cell.change !== null) {
      if (cell.date.diff(moment().tz(timezone), 'day') < 0) {
        this.setState({
          alertTitle: 'Error',
          alertMessage:
            "You can't make changes to this scheduled day as it is in the past",
          leftButtons: [],
        })
      } else {
        actions.A_checkCanDeleteScheduleChange(cell.change).then(response => {
          const format = 'DD.MM.YYYY'

          const selected_date = moment(cell.change.start_date)
            .tz(timezone)
            .format(format)

          let start_date = moment(cell.change.schedule.start_date).tz(timezone)

          if (
            moment()
              .tz(timezone)
              .isAfter(start_date)
          ) {
            start_date = moment().tz(timezone)
          }

          const end_date = moment(cell.change.schedule.end_date)
            .tz(timezone)
            .format(format)

          let leftButtons = []
          let alertMessage = ''

          if (response.all) {
            alertMessage = `Deleting all would delete from ${start_date.format(
              format,
            )} to ${end_date}.`
            leftButtons.push({
              type: 'button',
              style: 'alert',
              label: 'All',
              size: 'small',
              events: {
                onClick: this.deleteBookedSchedule,
              },
            })
          }

          if (response.single) {
            alertMessage += `Deleting only selected date would delete ${selected_date}`
            leftButtons.push({
              type: 'button',
              style: 'tertiary',
              label: 'Only selected date',
              size: 'small',
              events: {
                onClick: this.deleteBookedScheduleChange,
              },
            })
          }

          this.setState({
            alertTitle: 'Would you like to delete schedule change(s)?',
            alertMessage: alertMessage,
            leftButtons: leftButtons,
            selectedCell: cell,
          })
        })
      }

      return
    }

    actions.A_ResetDeletionProp()
    actions.A_PassScheduleData(true)

    this.setState(
      {
        selectedCell: cell,
        selectedClinician: clinician,
        selectedDate: cell.date.toDate(),
      },
      () => {
        func.showModal('scheduleCalendarModal')
      },
    )
  }

  deleteBookedScheduleChange = () => {
    const { actions } = this.props
    const { selectedCell } = this.state

    this.closeAlert()

    actions.A_DeleteBookedScheduleChange(selectedCell.change).then(() => {
      this.onScheduleChange()
    })
  }

  deleteBookedSchedule = () => {
    const { actions } = this.props
    const { selectedCell } = this.state

    this.closeAlert()

    actions.A_DeleteBookedSchedule(selectedCell.change.schedule.id).then(() => {
      this.onScheduleChange()
    })
  }

  hideModal() {
    const { actions } = this.props
    actions.A_PassScheduleData(false)
  }

  onScheduleChange() {
    const { actions } = this.props
    actions.A_PassScheduleData(false)
    this.getClinicianSchedules(this.props)
  }

  renderWeeklyHeader(index, dates) {
    const {
      global: { timezone },
      scheduleCalendarDateHeader: { month, year },
    } = this.props
    const selectedDate = moment(getDateForMonthStart(month, year)).tz(timezone)

    return dates.map(date => {
      const moment_date = moment(date).tz(timezone)

      return (
        <div
          key={date}
          className={`schedule__day_header gridCol ${
            index === 0 ? 'day_header--top' : ''
          }`}
        >
          <span
            className={`schedule__day_header__item ${
              selectedDate.isSame(moment_date, 'month')
                ? ''
                : 'schedule__day_header__item--fade'
            }`}
          >
            {moment_date.format(' ddd DD ')}
          </span>
        </div>
      )
    })
  }

  clinician_location_class(index) {
    const { clinicians } = this.state
    let location = 'single'

    if (clinicians.length > 1) {
      if (index === 0) {
        location = 'top'
      } else if (index === clinicians.length - 1) {
        location = 'bottom'
      }
    }

    return 'schedule__item--' + location
  }

  renderCell(clinicianIndex, row, cell, i, ensure_generation = false) {
    const {
      global: { timezone },
    } = this.props
    const { clinicians } = this.state

    let clinician = clinicians[clinicianIndex]

    let classes = 'schedule__item'

    if (cell.option === -1) {
      classes += ' schedule__item--unspecified'
    }

    if (cell.duration < 2) {
      classes += ' schedule__item--half'

      if (cell.duration === 0) {
        classes += ' schedule__item--half-left'
      } else {
        classes += ' schedule__item--half-right'
      }
    }

    classes += ' ' + this.clinician_location_class(clinicianIndex)

    const isStart = cell.show_title
    let isEnd = true
    let next = null

    if (i + 1 < row.length - 1) {
      next = row[i + 1]

      isEnd = next.show_title
    }

    let locationTitle

    if (cell.location) {
      locationTitle = cell.location.name
      this.mapClinicColour(cell.location)
    } else {
      if (cell.option === 1) {
        locationTitle = 'Holiday'
      } else {
        locationTitle = 'Unavailable'
      }
    }

    let scheduleItemColourClass = this.getScheduleItemColour(cell)

    if (cell.option === -1) {
      return <div className={classes} />
    }

    let afternoon = ''

    if (cell.duration === 0 && next !== null) {
      afternoon = this.renderCell(clinicianIndex, row, next, i + 1, true)
    }

    if (cell.duration === 1 && ensure_generation === false) {
      return ''
    }

    cell.date = moment(cell.date).tz(timezone)

    return (
      <React.Fragment>
        <button
          onClick={() => this.showScheduleChangeModal(cell, clinician.id)}
          className={`${classes} ${scheduleItemColourClass}`}
        >
          {isStart ? (
            <span
              className={`singleLine_truncatedText ${
                isStart && !isEnd ? 'schedule__item--multi-start' : ''
              } ${isEnd && !isStart ? 'schedule__item--multi-end' : ''}`}
            >
              {isStart ? locationTitle : ''}
            </span>
          ) : (
            ''
          )}
        </button>
        {afternoon}
      </React.Fragment>
    )
  }

  renderSchedule(index, clinicianIndex) {
    const { clinicians, cells } = this.state

    const count = clinicians.length
    const row = cells[count * index + clinicianIndex]
    return row.map((cell, i) => {
      const generated_cell = this.renderCell(clinicianIndex, row, cell, i)

      if (generated_cell === '') {
        return generated_cell
      }

      return (
        <div
          key={cell.date}
          data-date={cell.date}
          className="schedule__day_item gridCol"
        >
          {generated_cell}
        </div>
      )
    })
  }

  renderScheduleWeek(index, dates) {
    const { clinicians } = this.state

    let practitionerSchedules = clinicians.map((clinician, clinicianIndex) => {
      return (
        <section
          key={clinician.id}
          data-practitioner={`practitioner${clinician.id}`}
          className="practitioner_schedule"
        >
          <article className="practitioner_selector">
            <button
              type="button"
              className={`roundedToggleButton roundedToggleButton--image roundedToggleButton--flatRight roundedToggleButton--selectPractitioner ${this.clinician_location_class(
                clinicianIndex,
              )}`}
            >
              <Avatar
                src={clinician.signed_avatar}
                type="practitionerProfileHeader"
                isSmall={true}
                isToggleButton={true}
                initials={getInitials(clinician)}
              />

              <span className="roundedToggleButton__text roundedToggleButton__text--schedule">
                <span className="roundedToggleButton__title roundedToggleButton__title--practitioner">
                  {getFullName(clinician)}
                </span>
              </span>
            </button>
          </article>
          <article className="practitioner_schedule_items">
            {this.renderSchedule(index, clinicianIndex)}
          </article>
        </section>
      )
    })

    return (
      <section className="schedule__week_item">
        <div className="calendar_header calendar_header--schedule">
          <div
            className={`calendar_initial_cell calendar_initial_cell--day_header ${
              index === 0 ? 'day_header--top' : ''
            }`}
          />
          <article className="practitioner_header practitioner_header--schedule">
            {this.renderWeeklyHeader(index, dates)}
          </article>
        </div>
        <div className="schedule_body">{practitionerSchedules}</div>
      </section>
    )
  }

  setScheduleCalendarRef(element) {
    this.scheduleCalendarRef = element
  }

  closeAlert() {
    this.setState({
      alertTitle: null,
      alertMessage: null,
      leftButtons: [],
      selectedCell: null,
    })
  }

  render() {
    const {
      showModal,
      data,
      global: { visibleClinicians },
    } = this.props
    const { weeks, dates, clinicians } = this.state

    let scheduleWeeks = []

    if (weeks > 0 || visibleClinicians.length === 0) {
      for (let i = 0; i < weeks; i++) {
        const days = dates.slice(i * 7, i * 7 + 7)
        scheduleWeeks.push(
          <section key={i} className="schedule__week_item">
            {this.renderScheduleWeek(i, days)}
          </section>,
        )
      }
    } else {
      scheduleWeeks.push(<Loader key={0} />)
    }
    return (
      <main className="main main--calendar">
        {!this.props.hasCompletedAllTasks ? (
          <Placeholder activeTasks={this.props.activeTasks} />
        ) : null}
        <ScheduleHeader
          showScheduleChangeModal={this.showBlankScheduleChangeModal}
          changeClinic={this.filterClinic}
          enableHeaderButton={clinicians.length > 0}
        />
        <section className="main__inner--calendar main__inner--schedule">
          <div className="calendar__wrapper calendar__wrapper--schedule">
            <div
              ref={this.setScheduleCalendarRef}
              className="schedule__calendar"
            >
              {scheduleWeeks}
            </div>
          </div>
        </section>
        {showModal ? (
          <ScheduleChange
            selectedClinician={this.state.selectedClinician}
            selectedDate={this.state.selectedDate}
            onClose={this.hideModal}
            onSave={this.onScheduleChange}
            selectedCell={this.state.selectedCell}
            selectedReason={this.state.selectedCell.option}
            hasScheduledOption={this.state.selectedCell.change !== null}
          />
        ) : (
          ''
        )}
        {data.deleteSuccess ? (
          <FeedbackNotification
            hasMargin={false}
            message="Schedule Deleted Successfully"
          />
        ) : (
          ''
        )}
        {this.state.alertMessage ? (
          <AlertModal
            message={this.state.alertMessage}
            closeAlert={this.closeAlert}
            title={this.state.alertTitle}
            rightButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Cancel',
                size: 'small',
                events: {
                  onClick: this.closeAlert,
                },
              },
            ]}
            leftButtons={this.state.leftButtons}
            modalClassWidth="col__12-5"
          />
        ) : null}
        {isTutorialFeatureEnabled ? (
          <TutorialContext.Consumer>
            {({
              toggleTutorial,
              setTutorial,
              tutorialList,
              history,
              activeTutorial,
            }) => (
              <TutorialBtn
                tutorialList={tutorialList}
                toggleTutorial={toggleTutorial}
                setTutorial={setTutorial}
                activeTutorial={activeTutorial}
                history={history}
              />
            )}
          </TutorialContext.Consumer>
        ) : null}
      </main>
    )
  }
}
//

MonthlySchedule.propTypes = {
  actions: PropTypes.object.isRequired,
  scheduleCalendarDateHeader: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  global: PropTypes.object.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
  return {
    scheduleCalendarDateHeader: state.schedule.scheduleCalendarDateHeader,
    data: state.schedule,
    showModal: state.schedule.showModal,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_FetchClinicScheduleConflictList,
        A_PassScheduleData,
        A_SetViewingClinic,
        A_ResetDeletionProp,
        A_checkCanDeleteScheduleChange,
        A_DeleteBookedSchedule,
        A_DeleteBookedScheduleChange,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MonthlySchedule),
)
