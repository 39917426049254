import PropTypes from 'prop-types'

const UploadDocumentButton = ({
  id,
  title,
  type,
  canDelete,
  onDelete,
  onOpen,
  size,
  isPreload,
  customClasses,
}) => {
  return (
    <div
      className={`upload__info__container ${
        size === 'full' ? 'upload__info__container--full' : ''
      } ${customClasses}`}
    >
      <button
        type="button"
        className={`buttonTransparent upload__info__button ${
          size === 'full' ? 'upload__info__container--full' : ''
        }`}
        onClick={onOpen}
        disabled={isPreload ? 'disabled' : ''}
      >
        <span className={`upload__info__icon icon-${type}`} />
        <span
          className={`upload__info__text ${
            canDelete ? 'upload__info__text--right-margin' : ''
          }`}
        >
          {title}
        </span>
      </button>
      {canDelete ? (
        <button
          data-fileid={id}
          type="button"
          className="buttonTransparent upload__info__delete icon-cross"
          onClick={onDelete}
        />
      ) : (
        ''
      )}
    </div>
  )
}

UploadDocumentButton.defaultProps = {
  id: null,
  title: '',
  type: 'document',
  canDelete: false,
  onDelete: () => {},
  onOpen: () => {},
  size: 'auto',
  customClasses: '',
  isPreload: false,
}

UploadDocumentButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  type: PropTypes.oneOf(['document', 'image']),
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onOpen: PropTypes.func,
  size: PropTypes.oneOf(['auto', 'full']),
  customClasses: PropTypes.string,
  isPreload: PropTypes.bool,
}

export default UploadDocumentButton
