import { useState, useEffect } from 'react'

import SupportController from '../../components/Support/SupportController'
import SupportPage from '../../components/Support/SupportPage'
import SupportModal from '../../components/Support/SupportModal'

import { checkAttachmentType } from '../../utilities/ReusableFunctions'

import { getSupportData } from '../../services/OnBoarding/auth'

export default function SupportContainer() {
  const [activePage, setActivePage] = useState({})
  const [activeAttachment, setActiveAttachment] = useState({})
  const [pages, setPages] = useState([])
  const [isFetching, setFetching] = useState(true)
  const [isModalOpen, toggleModal] = useState(false)

  useEffect(() => {
    async function fetchSupportData() {
      const supportData = await getSupportData()
      setFetching(false)
      const formattedSupportData = formatSupportData(supportData)
      const initialActivePage = formattedSupportData.find(
        ({ sections }) => sections.length,
      )
      setPages(formattedSupportData)
      setActivePage(initialActivePage)
    }
    fetchSupportData()
  }, [])

  const formatSupportData = ({ results }) =>
    results.map(page => ({
      ...page,
      sections: page.sections.map(section => ({
        ...section,
        attachments: section.attachments.map(attachment => ({
          ...attachment,
          type: checkAttachmentType(attachment.file),
        })),
      })),
    }))

  return (
    <main className="main">
      <section className="main__inner">
        {isModalOpen && (
          <SupportModal
            attachment={activeAttachment}
            toggleModal={toggleModal}
            resetAttachment={setActiveAttachment}
          />
        )}
        <div className="settings--container">
          {!isFetching && activePage ? (
            <SupportController
              activePage={activePage}
              setPage={setActivePage}
              pages={pages}
            />
          ) : (
            ''
          )}

          <SupportPage
            isFetching={isFetching}
            sections={
              activePage && activePage.sections ? activePage.sections : []
            }
            toggleModal={toggleModal}
            setAttachment={setActiveAttachment}
          />
        </div>
      </section>
    </main>
  )
}
