import { useState } from 'react'
import PropTypes from 'prop-types'
import TextInput from '../../../../forms/Input'
import Loader from '../../../../PatientData/Loader'

const ClinicGroup = ({
  account: { company_name, company_website, isFetching },
  updateGroupDetails,
}) => {
  const [clinicGroup, setClinicGroup] = useState(company_name)
  const [clinicSite, setClinicSite] = useState(company_website)

  const changeGroupName = name => setClinicGroup(name)

  const changeSiteName = site => setClinicSite(site)

  return (
    <div className="settings--view settings--view-lower">
      <h2>Update your clinic group details</h2>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', paddingRight: '5px' }}>
          <TextInput
            label="Clinic group name"
            value={clinicGroup}
            onChange={({ target: { value } }) => changeGroupName(value)}
          />
        </div>
        <div style={{ width: '50%', paddingLeft: '5px' }}>
          <TextInput
            label="Website"
            value={clinicSite}
            onChange={({ target: { value } }) => changeSiteName(value)}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <button
          data-testid="update-clinic-details-btn"
          className="buttonSecondary"
          onClick={() => updateGroupDetails(clinicGroup, clinicSite)}
        >
          Update clinic details
        </button>
        {isFetching && <Loader isSetting />}
      </div>
    </div>
  )
}

ClinicGroup.defaultProps = {
  account: {},
}

ClinicGroup.propTypes = {
  account: PropTypes.object,
  updateGroupDetails: PropTypes.func.isRequired,
}

export default ClinicGroup
