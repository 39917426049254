import { Component } from 'react'
import PropTypes from 'prop-types'

class Avatar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValid: true,
    }
  }

  imageClasses = () => {
    const { type, isToggleButton } = this.props
    let classList = 'avatar_main'
    switch (type) {
      case 'formImage':
        classList = 'form__label__image'
        break
      case 'userDetailsNav':
        classList += ' avatar--right-margin avatar--fade'
        break
      case 'userProfile':
        classList += ' avatar--right-margin avatar--large'
        break
      case 'practitionerProfileHeader':
        classList += ' avatar--right-margin avatar--extra-small'
        break
      case 'practitionerProfileHeaderCalendar':
        classList += ' practitioner--header__profilePicture'
        break
      case 'formLabel':
        classList += ' avatar--right-margin avatar--medium'
        break
      case 'editProfile':
        classList = ' form__imageUploadPreview'
        break
      default:
        return classList
    }

    if (isToggleButton) {
      classList += ' avatar--inset-top roundedToggleButton__image'
    }
    return classList
  }

  showInitials = () => {
    const { type, initials, isSmall } = this.props
    let initialsType =
      type === 'formImage' ? (
        <span
          className={`${this.imageClasses()} avatar_noImg ${
            isSmall ? 'avatar_noImg--small' : ''
          } `}
        >
          {initials}
        </span>
      ) : (
        <p>
          <span
            className={`${this.imageClasses()} avatar_noImg ${
              isSmall ? 'avatar_noImg--small' : ''
            } `}
          >
            {initials}
          </span>
        </p>
      )
    return initialsType
  }

  handleError = () => {
    this.setState({ isValid: false })
  }

  render() {
    const { src } = this.props
    return src && src.length > 0 && this.state.isValid ? (
      <img
        className={this.imageClasses()}
        src={src}
        alt="Profile"
        onError={this.handleError}
      />
    ) : (
      this.showInitials()
    )
  }
}

Avatar.defaultProps = {
  src: '',
  type: 'main',
  isToggleButton: false,
  isSmall: false,
  initials: '',
}

Avatar.propTypes = {
  src: PropTypes.string,
  type: PropTypes.string,
  isToggleButton: PropTypes.bool,
  isSmall: PropTypes.bool,
  initials: PropTypes.string,
}

export default Avatar
