import { useDispatch } from "react-redux";
import {
  closeAppointmentModal,
  createdAppointment,
  deletedAppointment,
  handleDateChange,
  updatedAppointment,
} from "./actions";
import { useCallback } from "react";

export const useCloseAppointmentModal = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(closeAppointmentModal());
  }, [dispatch]);
};

export const useCreatedAppointment = () => {
  const dispatch = useDispatch();

  return useCallback(
    (appointment) => dispatch(createdAppointment(appointment)),
    [dispatch]
  );
};

export const useUpdatedAppointment = () => {
  const dispatch = useDispatch();

  return useCallback(
    (appointment) => dispatch(updatedAppointment(appointment)),
    [dispatch]
  );
};

export const useDeletedAppointment = () => {
  const dispatch = useDispatch();
  const closeModal = useCloseAppointmentModal();

  return useCallback(
    (appointment) => {
      dispatch(deletedAppointment(appointment));
      closeModal();
    },
    [dispatch, closeModal]
  );
};

export const useHandleDateChange = () => {
  const dispatch = useDispatch();

  return useCallback((date) => dispatch(handleDateChange(date)), [dispatch]);
};

export const useHandleMedicalHistory = (history) => {
  const closeModal = useCloseAppointmentModal();

  return useCallback(
    (id) => {
      history.push(`/clinics/patients/${id}/medical-history`);
      closeModal();
    },
    [closeModal, history]
  );
};
