import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../Buttons'
import InventoryOptionOverviewItem from '../InventoryOptionOverviewItem'

const InventoryOverviewItem = ({
  headerText,
  product,
  showCreateOption,
  showEditOption,
  showEdit,
  locale,
}) => {
  return (
    <React.Fragment>
      <section className="treatment primaryMarginBottom">
        <article className="treatment__section treatment__header">
          <div className="treatment__header__info">
            <div className="treatment__header__title">
              <span className="treatment__header__text multiline_wordbreak">
                {headerText}
              </span>
              {product.locked ? (
                <span className="treatmentItem__header__editStatus treatment__header__editStatus--top">
                  <span className="icon-lock" />
                </span>
              ) : (
                ''
              )}
            </div>
            {product.locked ? (
              ''
            ) : (
              <div className="treatment__info__edit">
                <button
                  className="buttonCircle buttonCircle--card icon-pencil"
                  onClick={e => showEdit(e, product)}
                />
              </div>
            )}
          </div>
        </article>
        <article className="treatment__body">
          <p className="form__label">PRODUCT OPTIONS</p>
          {product.options.length === 0 ? (
            <p className="empty">No product options added</p>
          ) : (
            product.options.map(option => {
              let price =
                option.levels.length !== 0
                  ? Number(option.levels[0].price).toFixed(2)
                  : null
              return (
                <InventoryOptionOverviewItem
                  key={option.id}
                  headerText={option.title}
                  price={price}
                  toggleModal={e => showEditOption(e, product, option)}
                  locale={locale}
                />
              )
            })
          )}
        </article>
        <article className="treatment__section treatment__footer">
          <MainButton
            type="button"
            label="New product option"
            id={product.id}
            styleType="primary"
            size="small"
            clickHandler={e => showCreateOption(e, product)}
          />
        </article>
      </section>
    </React.Fragment>
  )
}

const ProductPropType = PropTypes.shape({
  id: PropTypes.number,
  locked: PropTypes.bool,
  options: PropTypes.array,
})

InventoryOverviewItem.defaultProps = {
  headerText: '',
  //toggleModal: () => {},
  showCreateOption: () => {},
  showEditOption: () => {},
  showEdit: () => {},
}

InventoryOverviewItem.propTypes = {
  headerText: PropTypes.string,
  product: ProductPropType.isRequired,
  showCreateOption: PropTypes.func,
  showEditOption: PropTypes.func,
  showEdit: PropTypes.func,
  locale: PropTypes.string.isRequired,
}

export default InventoryOverviewItem
