import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_TogglePasswordView,
  A_ResetProfileValues,
  A_VerifyPassword,
  A_FinishAccountSetup,
  A_UpdatedAvatar,
  A_GetClinicianDetails,
} from '../../actions'
import ErrorToolTipCard, {
  errorSpan,
  requiredSpan,
} from '../OnBoarding/FormErrorValidations'
import TooltipCardValidator from '../../components/Tooltip/TooltipCardValidator'
import * as func from '../../utilities/ReusableFunctions'
import TextInput from '../../components/forms/Input'
import Avatar from '../../components/Avatar'
import MainButton from '../../components/Buttons'
import SelectInput from '../../components/forms/Select'
import UploadFile from '../../components/forms/fileUpload'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import {
  validatePassword,
  validatePhoneNumber,
} from '../../containers/_/Validators'
import { TutorialContext } from '../../contexts'
import TutorialBtn from '../Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

import PhoneNumberInput from '../../components/forms/PhoneNumber'
import { appointmentLengthArray } from '../../utilities/ReusableObjects'

class EditPractitionerProfile extends Component {
  constructor(props) {
    super(props)

    let lengths = appointmentLengthArray.map(minutes => {
      return {
        id: minutes,
        title: `${minutes} minutes`,
      }
    })
    lengths.push({ id: 60, title: '1 hour' })

    this.state = {
      showToolTipCard: false,
      appointmentLength: lengths,
      locationOptions: [],
      selectedFiles: null,
      locationId: '',
      avatar: '',
      clinicLogo: '',
      passwordUpdated: false,
      changesSaved: false,
      validNewPassword: true,
      user: {},
      error: {
        avatar: false,
        firstName: false,
        lastName: false,
        phone: false,
        altPhone: false,
      },
      dataLoaded: false,
    }
    this.password = React.createRef()
  }

  componentDidMount = () => {
    const { history, global, data, actions } = this.props
    const { clinicianId } = this.props.match.params

    if (clinicianId !== global.currentUserID && !data) {
      history.push(`/clinics/profile/${clinicianId}`)
      return
    }

    actions.A_GetClinicianDetails(clinicianId).then(response => {
      let user = response

      delete user.avatar

      let locationOptions = user.clinic_roles.map(role => {
        return {
          id: role.clinic.id,
          title: role.clinic.name,
          isDisabled: false,
        }
      })

      this.setState({
        user: user,
        avatar: user.signed_avatar,
        locationOptions: locationOptions,
        originalUsername: user.username,
        dataLoaded: true,
      })
    })
  }

  showToolTipCard = () => {
    this.setState({ showToolTipCard: true })
  }

  onChangeProfileDetails = ({ target }) => {
    const { user } = this.state

    if (target.id === 'primary_clinic') {
      user.clinic_roles.forEach(clinic => {
        if (clinic.clinic.id === Number(target.value)) {
          user.primary_clinic = {
            id: clinic.clinic.id,
            name: clinic.clinic.name,
          }
        }
      })
    } else {
      user[target.id] = target.value
    }

    this.setState({ user: user })
  }

  validatePhone = (value, key, required) => {
    let { error } = this.state

    if ((value === null || value === '') && required === false) {
      error[key] = false
    } else if (value.length === 0) {
      error[key] = true
    } else {
      error[key] = !validatePhoneNumber(value).valid
    }

    this.setState({ error })
  }

  saveProfileInfo = e => {
    e.preventDefault()
    const { actions, history } = this.props
    let user = this.state.user
    let error = this.state.error

    error.username = user.username.trim().length === 0
    error.firstName = user.first_name.trim().length === 0
    error.lastName = user.last_name.trim().length === 0

    this.setState({ error: error, changesNotSaved: false })
    if (
      !error.username &&
      !error.username &&
      !error.firstName &&
      !error.lastName &&
      !error.phone &&
      !error.altPhone
    ) {
      //////// ADD AVATAR TO INITIAL CALL IF SETTING TO NULL
      let userData = { ...user }

      if (
        (this.state.avatar === null || this.state.avatar === '') &&
        !this.state.avatarFD
      ) {
        userData = { ...user, avatar: null }
      }

      actions.A_FinishAccountSetup(userData).then(response => {
        if (response.status && response.status >= 300) {
          this.setState({ changesNotSaved: true })
        } else {
          if (this.state.avatarFD !== null) {
            actions.A_UpdatedAvatar(this.state.avatarFD)
          }
          this.setState({ changesSaved: true, changesNotSaved: false }, () => {
            setTimeout(() => {
              if (user.user_type !== 2) {
                history.push(`/clinics/profile/${user.id}`)
              }
              this.setState({ changesSaved: false })
            }, 2000)
          })
        }
      })
    } else {
      this.setState({ changesNotSaved: true })
    }
  }

  toolTipValidation = () => {
    const {
      current: { value },
    } = this.password
    this.setState({ password: validatePassword(value).valid })
  }

  validateCurrentPassword = () => {
    const { actions } = this.props
    var username = this.state.originalUsername
    var password = this.password.current.value

    actions.A_VerifyPassword({ username, password }).then(res => {
      if (res.token) {
        this.password.current.value = ''
        this.setState({ password: false })
      }
    })
  }

  saveNewPassword = e => {
    e.preventDefault()
    const { actions } = this.props
    var password = this.password.current.value

    this.setState(
      {
        validNewPassword: true,
        showToolTipCard: false,
        passwordUpdated: false,
      },
      () => {
        actions.A_ResetProfileValues()
        actions.A_FinishAccountSetup({ password }).then(() => {
          this.password.current.value = ''
          this.setState({
            passwordUpdated: true,
            changesSaved: false,
            password: false,
          })
        })
      },
    )
  }

  selectImagePreview = async imageSource => {
    let file = imageSource.files[0]

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      var avatarFD = new FormData()
      avatarFD.append('avatar', file)
      this.setState({ avatarFD: avatarFD }, () => {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          this.setState({ avatar: e.target.result })
        }
      })
    }
  }

  clearImagePreview = () => {
    this.setState({ avatar: null, avatarFD: null })
  }

  render() {
    const { profile, data, actions } = this.props
    return (
      <React.Fragment>
        {this.state.dataLoaded ? (
          <section className="main">
            <article className="main__inner">
              <section className="col__12-6 directionHeading__wrapper">
                <form className="form fadeInFlyAnimate" noValidate>
                  <section className="form__group--topStackable">
                    <div className="form__imageUploadWrapper">
                      <Avatar
                        src={this.state.avatar}
                        type="editProfile"
                        initials={func.getInitials(this.state.user)}
                      />
                      {this.state.avatar === null ||
                      this.state.avatar === '' ? (
                        <UploadFile
                          label="Upload"
                          styleType="tertiary"
                          size="small"
                          labelID="uploadProfilePicture"
                          inputID="inputProfilePicture"
                          accept="image/png, image/jpeg"
                          fileHandler={e => {
                            this.selectImagePreview(e.target)
                          }}
                        />
                      ) : (
                        <React.Fragment>
                          <div className="button--marginRight">
                            <UploadFile
                              label="Replace"
                              styleType="tertiary"
                              size="small"
                              labelID="uploadProfilePicture"
                              inputID="inputProfilePicture"
                              accept="image/png, image/jpeg"
                              fileHandler={e => {
                                this.selectImagePreview(e.target)
                              }}
                            />
                          </div>
                          <MainButton
                            type="button"
                            label="Remove"
                            styleType="destructive"
                            size="small"
                            onClick={e => {
                              this.clearImagePreview(e)
                            }}
                          />
                        </React.Fragment>
                      )}
                    </div>
                    <TextInput
                      type="text"
                      id="username"
                      label="Username"
                      placeholder="Username"
                      value={this.state.user.username}
                      onChange={e => this.onChangeProfileDetails(e)}
                      error={this.state.error.username ? 'Required' : ''}
                    />
                    <TextInput
                      type="text"
                      id="first_name"
                      label="Forename"
                      placeholder="First name"
                      value={this.state.user.first_name}
                      onChange={e => this.onChangeProfileDetails(e)}
                      error={this.state.error.first_name ? 'Required' : ''}
                    />
                    <TextInput
                      type="text"
                      id="last_name"
                      label="surname"
                      placeholder="Last name"
                      value={this.state.user.last_name}
                      onChange={e => this.onChangeProfileDetails(e)}
                      error={this.state.error.last_name ? 'Required' : ''}
                    />
                  </section>
                  {this.state.user &&
                  this.state.user.user_type === 1 &&
                  this.state.locationOptions.length > 0 ? (
                    <React.Fragment>
                      <section className="form__group--stackable">
                        <div className="form__selectWrapper">
                          <SelectInput
                            id="primary_clinic"
                            label="Primary Location"
                            options={this.state.locationOptions}
                            value={
                              this.state.user.primary_clinic
                                ? this.state.user.primary_clinic.id
                                : this.state.locationOptions[0].id
                            }
                            hasOwnWrapper={true}
                            onChange={e => this.onChangeProfileDetails(e)}
                          />
                        </div>
                      </section>
                      <section className="form__group--stackable">
                        <div className="form__selectWrapper">
                          <SelectInput
                            label="Default appointment length"
                            id="default_appointment_length"
                            options={this.state.appointmentLength}
                            value={this.state.user.default_appointment_length}
                            hasOwnWrapper={true}
                            onChange={e => this.onChangeProfileDetails(e)}
                          />
                        </div>
                      </section>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  <section className="form__group--stackable">
                    <TextInput
                      type="text"
                      id="email"
                      label="Email"
                      noMargin={false}
                      placeholder="Email"
                      value={this.state.user.email}
                      disabled={true}
                    />
                  </section>
                  <section className="form__group--stackable">
                    <PhoneNumberInput
                      key="main_phone"
                      id="main_phone"
                      label="Main Phone"
                      placeholder="Main Phone"
                      value={this.state.user.main_phone}
                      hasOwnWrapper={true}
                      onChange={value => {
                        const { user } = this.state
                        user.main_phone = value

                        this.setState({ user: user })
                        this.validatePhone(value, 'phone', true)
                      }}
                      customClasses={`${
                        this.state.error.phone ? 'form__textField--error' : ''
                      }`}
                      className={`form__textField `}
                      error={this.state.error.phone ? 'Required' : ''}
                    />
                  </section>
                  <section className="form__group--stackable">
                    <PhoneNumberInput
                      key="alternative_phone"
                      id="alternative_phone"
                      label="Alternative Phone"
                      placeholder="(Optional)"
                      value={this.state.user.alternative_phone}
                      hasOwnWrapper={true}
                      onChange={value => {
                        const { user } = this.state
                        user.alternative_phone = value
                        this.setState({ user: user })

                        this.validatePhone(value, 'altPhone', false)
                      }}
                      customClasses={`${
                        this.state.error.altPhone
                          ? 'form__textField--error'
                          : ''
                      }`}
                      className={`form__textField `}
                    />
                  </section>
                  <div className="form__submit--morePadding form__submit--center">
                    <MainButton
                      type="button"
                      label="Save changes"
                      styleType="secondary"
                      size="medium"
                      onClick={e => {
                        this.saveProfileInfo(e)
                      }}
                    />
                  </div>
                </form>
                <form className="form fadeInFlyAnimate gutterMarginTop">
                  <section className="form__group">
                    <h2 className="h3">Security info</h2>
                  </section>
                  <section className="form__group--stackable changePasswordForm">
                    <article
                      className={
                        !profile.validPassword
                          ? 'changePasswordForm__left'
                          : 'changePasswordForm__full'
                      }
                    >
                      <label className="form__label" htmlFor="userPassword1">
                        {this.state.password === false &&
                        this.password.current.value !== ''
                          ? requiredSpan()
                          : profile.noError && this.state.validNewPassword
                          ? ''
                          : errorSpan()}
                        Change Password
                      </label>
                      <input
                        className={`form__textField ${
                          profile.noError &&
                          this.state.password &&
                          !this.state.validNewPassword
                            ? 'form__textField--error'
                            : ''
                        }`}
                        type={data.type}
                        id="userPassword1"
                        ref={this.password}
                        placeholder={
                          !profile.validPassword
                            ? 'Current password'
                            : 'New Password'
                        }
                        autoComplete="off"
                        onFocus={this.showToolTipCard}
                        onChange={this.toolTipValidation}
                        onBlur={() => {
                          this.setState({ showToolTipCard: false })
                        }}
                      />
                      <button
                        type="button"
                        className="buttonTransparent passwordToggleView passwordToggleView--short showPassword"
                        onClick={actions.A_TogglePasswordView}
                      >
                        {data.Show ? 'Hide' : 'Show'}
                        <span
                          className={`${
                            data.Show ? 'icon-hide' : 'icon-show'
                          } passwordToggleView__icon`}
                        />
                      </button>
                      {this.state.showToolTipCard ? (
                        <TooltipCardValidator
                          validators={func.passwordValidators(
                            this.password.current.value,
                          )}
                        />
                      ) : (
                        ''
                      )}
                    </article>
                    {!profile.validPassword ? (
                      <article className="changePasswordForm__right">
                        <MainButton
                          type="button"
                          label="Unlock"
                          styleType="secondary"
                          size="small"
                          isDisabled={!this.state.password}
                          onClick={this.validateCurrentPassword}
                        />
                      </article>
                    ) : (
                      ''
                    )}
                  </section>
                  {!profile.noError ? (
                    <ErrorToolTipCard errorMsg="Incorrect Password" />
                  ) : (
                    ''
                  )}
                  <section className="form__group--stackable">
                    {profile.validPassword ? (
                      <div className="form__submit--morePadding form__submit--center">
                        <MainButton
                          type="button"
                          label="Save changes"
                          styleType="secondary"
                          size="medium"
                          isDisabled={!this.state.password}
                          onClick={this.saveNewPassword}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </section>
                </form>
              </section>
            </article>
            {isTutorialFeatureEnabled ? (
              <TutorialContext.Consumer>
                {({
                  toggleTutorial,
                  setTutorial,
                  tutorialList,
                  history,
                  activeTutorial,
                }) => (
                  <TutorialBtn
                    toggleTutorial={toggleTutorial}
                    tutorialList={tutorialList}
                    setTutorial={setTutorial}
                    activeTutorial={activeTutorial}
                    history={history}
                  />
                )}
              </TutorialContext.Consumer>
            ) : null}
          </section>
        ) : null}
        {this.state.passwordUpdated || this.state.changesSaved ? (
          <FeedbackNotification
            id="feedbackMessage"
            message={
              this.state.changesSaved
                ? 'Changes saved successfully'
                : 'Password changed successfully'
            }
            isWarning={false}
          />
        ) : this.state.changesNotSaved ? (
          <FeedbackNotification
            id="feedbackMessage"
            message="Oops, something went wrong!"
            isWarning={true}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

EditPractitionerProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    data: state.newAccount,
    profile: state.profile,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_TogglePasswordView,
        A_VerifyPassword,
        A_FinishAccountSetup,
        A_UpdatedAvatar,
        A_ResetProfileValues,
        A_GetClinicianDetails,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPractitionerProfile),
)
