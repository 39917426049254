import PropTypes from 'prop-types'

const ZoomINOUT = ({ updateCurrentTime, setScrollPos, zoomStatus, onZoom }) => {
  const zoom = type => {
    setScrollPos()
    onZoom(type)
    updateCurrentTime()
  }

  return (
    <div className="calendar_zoom_control">
      <button
        type="button"
        className={`buttonTransparent buttonZoom buttonZoom--zoomin icon-zoom-in ${
          zoomStatus === 'zoomedin' ? ' buttonZoom--selected' : ''
        }`}
        onClick={() => {
          zoom('zoomedin')
        }}
      />
      <div className="zoom_divider_container">
        <div className="zoom_divider" />
      </div>
      <button
        type="button"
        className={`buttonTransparent buttonZoom buttonZoom--zoomout icon-zoom-out ${
          zoomStatus === 'zoomedout' ? ' buttonZoom--selected' : ''
        }`}
        onClick={() => {
          zoom('zoomedout')
        }}
      />
    </div>
  )
}

ZoomINOUT.defaultProps = {
  updateCurrentTime: () => {},
  setScrollPos: () => {},
  zoomStatus: 'zoomedin',
  onZoom: () => {},
}

ZoomINOUT.propTypes = {
  updateCurrentTime: PropTypes.func,
  setScrollPos: PropTypes.func,
  onZoom: PropTypes.func,
  zoomStatus: PropTypes.oneOf(['zoomedin', 'zoomedout']),
}

export default ZoomINOUT
