import PropTypes from 'prop-types'

const SettingContent = ({ children, name, desc }) => (
  <div className="settings--view--container">
    <div className="settings--view">
      <h2>{name}</h2>
      <p className="settings--view-desc">{desc}</p>
    </div>
    {children}
  </div>
)

SettingContent.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}

export default SettingContent
