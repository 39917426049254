import React from 'react'
import PropTypes from 'prop-types'

import { formatCurrency } from '../../../utilities/ReusableFunctions'

const InventoryOptionOverviewItem = ({
  headerText,
  price,
  toggleModal,
  locale,
}) => {
  return (
    <React.Fragment>
      <button className="treatmentItem" onClick={() => toggleModal()}>
        <span className="treatmentItem__section treatmentItem__header noMargin">
          <span className="inventoryItem__header__text treatment__header__info">
            {headerText}
          </span>
          <span className="text-blue">
            {price === null ? (
              <span className="treatmentItem__header__alerts">
                <span className="treatmentItem__alert">Missing pricing</span>
              </span>
            ) : (
              formatCurrency(price, locale)
            )}
          </span>
        </span>
      </button>
    </React.Fragment>
  )
}

InventoryOptionOverviewItem.defaultProps = {
  price: null,
}

InventoryOptionOverviewItem.propTypes = {
  headerText: PropTypes.string.isRequired,
  price: PropTypes.number,
  toggleModal: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
}

export default InventoryOptionOverviewItem
