import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { A_GetClinicsList } from '../../actions'

import Placeholder from '../../components/Tutorials/Placeholder'
import { getTasks } from '../../services/OnBoarding/auth'
import { getAppointmentsOverview } from '../../services/WeeklyCalendar/CalendarWeeklyService'
import OverviewHeader from './OverviewHeader'
import OverviewAppointments from './OverviewAppointments'
import moment from 'moment'

const CalendarOverviewContainer = props => {
  useEffect(() => {
    const { actions, formatTasks } = props

    async function fetchTasks() {
      const tasks = await getTasks()
      formatTasks(tasks)
    }

    actions.A_GetClinicsList()

    fetchTasks()
    refreshAppointmentsOverview(clinicID, startDate, endDate)
  }, [])

  const [clinicID, setClinicID] = useState(props.clinicID)
  const [selectedInput, setSelectedInput] = useState(null)
  const [startDate, setStartDate] = useState(
    moment
      .tz('UTC')
      .add(1, 'week')
      .startOf('isoWeek'),
  )
  const [endDate, setEndDate] = useState(
    moment
      .tz('UTC')
      .add(1, 'week')
      .startOf('isoWeek')
      .add(6, 'days'),
  )
  const [isLoading, setIsLoading] = useState(true)
  const [appointments, setAppointments] = useState([])
  const [visibleClinician, setVisibleClinician] = useState(0)

  const refreshAppointmentsOverview = async (
    clinicID,
    start_date,
    end_date,
  ) => {
    setIsLoading(true)
    setAppointments([])
    const appointments = await getAppointmentsOverview(clinicID, {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD'),
    })

    setIsLoading(false)
    setAppointments(appointments)
  }

  return (
    <main className="main main--calendar">
      {!props.hasCompletedAllTasks ? (
        <Placeholder activeTasks={props.activeTasks} />
      ) : null}
      <OverviewHeader
        clinicID={clinicID}
        setClinicID={setClinicID}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedInput={selectedInput}
        setSelectedInput={setSelectedInput}
        refreshAppointmentsOverview={refreshAppointmentsOverview}
      />
      <OverviewAppointments
        global={props.global}
        isLoading={isLoading}
        clinicians={props.clinicians}
        appointments={appointments}
        visibleClinician={visibleClinician}
        setVisibleClinician={setVisibleClinician}
        startDate={startDate}
        endDate={endDate}
      />
    </main>
  )
}

CalendarOverviewContainer.defaultProps = {
  clinicID: 0,
}

CalendarOverviewContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  formatTasks: PropTypes.func.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
  clinicID: PropTypes.number,
  clinicians: PropTypes.array.isRequired,
  global: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  clinicID: state.global.currentClinicID,
  clinicians: state.global.visibleClinicians,
  global: state.global,
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalendarOverviewContainer),
)
