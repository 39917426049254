import React from 'react'
import PropTypes from 'prop-types'
import ModalHeader from './Header'
import ModalFooter from './Footer'
import ToolTip from '../Library/Treatment/ViewTreatment/ToolTip'

const Modal = ({
  id,
  title,
  rightButtons,
  leftButtons,
  children,
  ...props
}) => {
  return (
    <React.Fragment>
      <article
        id={id}
        className="modal modal--overlay col__12-6 fadeInFlyAnimate"
        style={{ display: 'block' }}
      >
        <div className="modal_flex_content">
          {props.treatment ? (
            <ModalHeader
              title={title}
              allowEdit={props.treatment.locked}
              closeHandler={props.handleEditTreatClose}
            />
          ) : (
            <ModalHeader title={title} closeHandler={props.closeModal} />
          )}
          {children}

          <ModalFooter rightButtons={rightButtons} leftButtons={leftButtons}>
            <ToolTip
              handleDelete={props.handleDelete}
              handleCancel={props.handleCancel}
              titleHeader={props.toolTipHeader}
              titleBody={props.toolTipBody}
            />
          </ModalFooter>
        </div>
      </article>
      <section className="overlay fadeInAnimate" style={{ display: 'block' }} />
    </React.Fragment>
  )
}

Modal.defaultProps = {
  id: 'modalForm',
  title: 'Title',
  rightButtons: [],
  leftButtons: [],
  treatment: '',
  handleEditTreatClose: () => {},
  closeModal: () => {},
  handleDelete: () => {},
  handleCancel: () => {},
  toolTipHeader: '',
  toolTipBody: '',
}

Modal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  rightButtons: PropTypes.array,
  leftButtons: PropTypes.array,
  children: PropTypes.object.isRequired,
  treatment: PropTypes.string,
  handleEditTreatClose: PropTypes.func,
  closeModal: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  toolTipHeader: PropTypes.string,
  toolTipBody: PropTypes.string,
}

export default Modal
