import PropTypes from 'prop-types'

const UploadFile = ({
  label,
  styleType,
  size,
  customClasses,
  labelID,
  inputID,
  fileHandler,
  accept,
}) => {
  const inputClasses = () => {
    let classList = customClasses
    switch (styleType) {
      case 'primary':
        classList += ' buttonPrimary'
        break
      case 'secondary':
        classList += ' buttonSecondary'
        break
      case 'tertiary':
        classList += ' buttonTertiary'
        break
      case 'destructive':
        classList += ' buttonDestructive'
        break
      default:
        break
    }
    if (size === 'small') {
      classList += ' button--small'
    }
    return classList
  }

  return (
    <>
      <label id={labelID} className={inputClasses()} htmlFor={inputID}>
        {label}
      </label>
      <input
        id={inputID}
        type="file"
        className="hidden"
        onChange={fileHandler}
        accept={accept}
      />
    </>
  )
}

UploadFile.defaultProps = {
  label: '',
  labelID: '',
  styleType: '',
  customClasses: '',
  size: 'medium',
  inputID: '',
  accept: '',
  fileHandler: () => {},
}

UploadFile.propTypes = {
  label: PropTypes.string,
  labelID: PropTypes.string,
  styleType: PropTypes.string,
  customClasses: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  inputID: PropTypes.string,
  accept: PropTypes.string,
  fileHandler: PropTypes.func,
}

export default UploadFile
