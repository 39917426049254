import PropTypes from 'prop-types'
import LoadingIcon from '../../../assets/loader.svg'

const Loader = ({ text, isSetting, isCreate, minHeight }) => {
  return (
    <section
      className={`${isSetting ? 'settings--loader' : 'loading__container'}`}
      style={{ minHeight: `${isCreate ? `${minHeight}px` : ''}` }}
    >
      {text ? <p className="form__label">{text}</p> : ''}
      <img
        src={LoadingIcon}
        alt="Loading..."
        className="patientImport__loading"
      />
    </section>
  )
}

Loader.defaultProps = {
  text: '',
  isSetting: false,
  isCreate: false,
  minHeight: 0,
}

Loader.propTypes = {
  text: PropTypes.string,
  isSetting: PropTypes.bool,
  isCreate: PropTypes.bool,
  minHeight: PropTypes.number,
}

export default Loader
