import { Component } from 'react'
import PropTypes from 'prop-types'

class DatePickerInput extends Component {
  render() {
    const {
      classList,
      value,
      id,
      refVal,
      onChange,
      onClick,
      disabled,
      noLabel,
    } = this.props
    return (
      <>
        <input
          className={classList}
          type="text"
          id={id}
          readOnly="readonly"
          value={value}
          ref={refVal}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
        <span
          id={id}
          role="button"
          tabIndex="0"
          className={`form__fieldIcon form__fieldIcon--right form__fieldIcon--datePicker icon-nav-calendar ${
            noLabel ? 'form__fieldIcon--no-label' : ''
          }`}
          onClick={onClick}
          onKeyDown={onClick}
        />
      </>
    )
  }
}

DatePickerInput.defaultProps = {
  classList: '',
  id: '',
  value: '',
  noLabel: false,
  onChange: () => {},
  disabled: false,
}

DatePickerInput.propTypes = {
  classList: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  noLabel: PropTypes.bool,
  refVal: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default DatePickerInput
