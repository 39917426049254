export default {
  //account
  ACCOUNT_SETUP: {
    REQ: '[account] account setup \u{1F4A4}',
    OK: '[account] account setup \u{2705}',
    FAIL: '[account] account setup \u{274C}',
  },

  GET_UPDATED_DETAILS: {
    REQ: '[account] update details \u{1F4A4}',
    OK: '[account] update details \u{2705}',
    FAIL: '[account] update detail \u{274C}',
  },

  GET_USER_DETAILS: {
    REQ: '[account] user details \u{1F4A4}',
    OK: '[account] user details \u{2705}',
    FAIL: '[account] user details \u{274C}',
  },

  //analytics
  GET_GRAPH_DETAILS: {
    REQ: '[analytics] graph details \u{1F4A4}',
    OK: '[analytics] graph details \u{2705}',
    FAIL: '[analytics] graph details \u{274C}',
  },

  VALIDATE_FILTER_DATA: '[analytics] validate filter data',

  DISABLE_ANALYTICS_APPLY_BUTTON: '[analytics] toggle analytics apply button',

  GET_APPOINTMENT_SUMMARY_DATA: {
    REQ: '[analytics] get appointment summary \u{1F4A4}',
    OK: '[analytics] get appointment summary \u{2705}',
    FAIL: '[analytics] get appointment summary \u{274C}',
  },

  GET_APPOINTMENT_TREATMENT_SUMMARY_DATA: {
    REQ: '[analytics] get appointment treatment summary \u{1F4A4}',
    OK: '[analytics] get appointment treatment summary \u{2705}',
    FAIL: '[analytics] get appointment treatment summary \u{274C}',
  },

  FETCH_CLINICLIST: {
    REQ: '[clinic setup] get user accessible healthXchange clinics \u{1F4A4}',
    OK: '[clinic setup]  get user accessible healthXchange clinics \u{2705}',
    FAIL: '[clinic setup] get user accessible healthXchange clinics \u{274C}',
  },

  //clinic appointments

  FETCH_TREATMENT_LIST: {
    REQ: '[clinic appointments] get all treatments list \u{1F4A4}',
    OK: '[clinic appointments] get all treatments list \u{2705}',
    FAIL: '[clinic appointments] get all treatments list \u{274C}',
  },

  FETCH_CONSULTATION_TREATMENT_LIST: {
    REQ:
      '[clinic appointments] get treatments available for consultations \u{1F4A4}',
    OK:
      '[clinic appointments] get treatments available for consultations \u{2705}',
    CLEAR:
      '[clinic appointments] clear treatments available for consultations \u{2705}',
    FAIL:
      '[clinic appointments] get treatments available for consultations \u{274C}',
  },

  FETCH_TREATMENT_TYPE_LIST: {
    REQ:
      '[clinic appointments] get treatment types available for consultations \u{1F4A4}',
    OK:
      '[clinic appointments] get treatments types available for consultations \u{2705}',
    FAIL:
      '[clinic appointments] get treatments types available for consultations \u{274C}',
  },

  GET_CLINIC_APPOINTMENT_DETAIL: {
    REQ: '[clinic appointments] get booked appointment details \u{1F4A4}',
    OK: '[clinic appointments] get booked appointment details \u{2705}',
    FAIL: '[clinic appointments] get booked appointment details \u{274C}',
  },

  GET_CLINIC_PATIENTS_LIST: {
    REQ: '[clinic appointments] get all patients in clinic group \u{1F4A4}',
    OK: '[clinic appointments] get all patients in clinic group \u{2705}',
    FAIL: '[clinic appointments] get all patients in clinic group \u{274C}',
  },

  GET_FILTERED_PATIENTS_LIST: {
    REQ:
      '[clinic appointments] get patients in clinic group by search value \u{1F4A4}',
    OK:
      '[clinic appointments] get patients in clinic group by search value \u{2705}',
    FAIL:
      '[clinic appointments] get patients in clinic group by search value \u{274C}',
    CLEAR: '[clinic appointments] clear patients in clinic group \u{2705}',
  },

  STORE_INITIAL_STATE_VALUE: '[clinic appointments] save initial state',

  GET_BASIC_DETAILS: {
    REQ: '[clinic appointments] get clinic details \u{1F4A4}',
    OK: '[clinic appointments] get clinic details \u{2705}',
    FAIL: '[clinic appointments] get clinic details \u{274C}',
  },

  STORE_SLOT_LENGTH: '[clinic appointments] store slot length',

  GET_AVALIABLE_SLOTS_LIST: {
    REQ:
      '[clinic appointments] get clinician slots available for unselected appointment \u{1F4A4}',
    OK:
      '[clinic appointments] get clinician slots available for unselected appointment \u{2705}',
    CLEAR:
      '[clinic appointments] get clinician slots vailable for unselected appointment \u{2705}',
    FAIL:
      '[clinic appointments] get clinician slots available for unselected appointment \u{274C}',
  },

  FETCH_BOOKED_APPOINTMENT_DETAILS: {
    REQ: '[clinic appointments] get details of selected appointment \u{1F4A4}',
    OK: '[clinic appointments] get details of selected appointment \u{2705}',
    FAIL: '[clinic appointments] get details of selected appointment \u{274C}',
    CLEAR:
      '[clinic appointments] clear details of selected appointment \u{2705}',
  },

  POST_NEW_CLINIC_NOTES: {
    REQ: '[clinic appointments] post notes for booked appointment \u{1F4A4}',
    OK: '[clinic appointments] post notes for booked appointment \u{2705}',
    FAIL: '[clinic appointments] post notes for booked appointment \u{274C}',
  },

  UPDATE_BOOKED_APPOINTMENT: {
    REQ: '[clinic appointments] patch booked appointment details \u{1F4A4}',
    OK: '[clinic appointments] patch booked appointment details \u{2705}',
    FAIL: '[clinic appointments] patch booked appointment details \u{274C}',
  },

  VALIDATE_BOOKED_APPOINTMENT_DETAIL:
    '[clinic appointments] set booked appointment details valid state',

  //Data import
  GET_IMPORTED_DATA: {
    REQ: '[data import] get import data \u{1F4A4}',
    OK: '[data import] get import data \u{2705}',
    FAIL: '[data import] get import data \u{274C}',
  },

  FILE_IMPORT: {
    REQ: '[data import] upload file \u{1F4A4}',
    OK: '[data import] upload file \u{2705}',
    FAIL: '[data import] upload file \u{274C}',
  },

  DATA_IMPORT: {
    REQ: '[data import] send data keys for data to upload from csv \u{1F4A4}',
    OK: '[data import] send data keys for data to upload from csv \u{2705}',
    FAIL: '[data import] send data keys for data to upload from csv \u{274C}',
  },

  //Inventory
  GET_INVENTORY_LIST: {
    REQ: '[inventory] get inventory item list \u{1F4A4}',
    OK: '[inventory] get inventory item list \u{2705}',
    FAIL: '[inventory] get inventory item list \u{274C}',
  },

  POST_NEW_INVENTORY: {
    REQ: '[inventory] post new inventory item \u{1F4A4}',
    OK: '[inventory] post new inventory item \u{2705}',
    FAIL: '[inventory] post new inventory item \u{274C}',
  },

  DELETE_INVENTORY: {
    REQ: '[inventory] delete inventory item \u{1F4A4}',
    OK: '[inventory] delete inventory item \u{2705}',
    FAIL: '[inventory] delete inventory item \u{274C}',
  },

  PATCH_INVENTORY_DETAILS: {
    REQ: '[inventory] update inventory item details \u{1F4A4}',
    OK: '[inventory] update inventory item details \u{2705}',
    FAIL: '[inventory] update inventory item details \u{274C}',
  },

  GET_INVENTORY_OPTION_LIST: {
    REQ: '[inventory] get inventory item options list \u{1F4A4}',
    OK: '[inventory] get inventory item options list \u{2705}',
    FAIL: '[inventory] get inventory item options list \u{274C}',
  },

  POST_NEW_INVENTORY_OPTION: {
    REQ: '[inventory] post new inventory item option \u{1F4A4}',
    OK: '[inventory] post new inventory item option \u{2705}',
    FAIL: '[inventory] post new inventory item option \u{274C}',
  },

  DELETE_INVENTORY_OPTION: {
    REQ: '[inventory] delete inventory item option \u{1F4A4}',
    OK: '[inventory] delete inventory item option \u{2705}',
    FAIL: '[inventory] delete inventory item option \u{274C}',
  },

  PATCH_INVENTORY_OPTION_DETAILS: {
    REQ: '[inventory] update inventory item option details \u{1F4A4}',
    OK: '[inventory] update inventory item option details \u{2705}',
    FAIL: '[inventory] update inventory item option details \u{274C}',
  },

  //Notifications

  GET_NOTIFICATION_UNREAD: '[notifications] get notifications unread status',

  GET_NOTIFICATION_LIST: {
    REQ: '[notifications] get notification list \u{1F4A4}',
    OK: '[notifications] get notification list \u{2705}',
    FAIL: '[notifications] get notification list \u{274C}',
  },

  MARK_NOTIFICATION_READ: {
    REQ: '[notifications] update notification read status \u{1F4A4}',
    OK: '[notifications] update notification read status \u{2705}',
    FAIL: '[notifications] update notification read status \u{274C}',
  },

  POST_NOTIFICATION: {
    REQ: '[notifications] post new notification message \u{1F4A4}',
    OK: '[notifications] post new notification message \u{2705}',
    FAIL: '[notifications] post new notification message \u{274C}',
  },

  //OnBoarding
  LOGIN: {
    REQ: '[onboarding] user login \u{1F4A4}',
    OK: '[onboarding] user login \u{2705}',
    FAIL: '[onboarding] user login \u{274C}',
  },

  GET_ACCOUNT: {
    REQ: '[onboarding] get additional account details \u{1F4A4}',
    OK: '[onboarding] get addtional account details \u{2705}',
    FAIL: '[onboarding] get additional account details \u{274C}',
  },

  CHECK_EMAIL: {
    REQ: '[onboarding] check user does not exist \u{1F4A4}',
    OK: '[onboarding] check user does not exist \u{2705}',
    FAIL: '[onboarding] check user does not exist \u{274C}',
  },

  EMAIL_VALIDATION: {
    REQ: '[onboarding] submit forgotten password request \u{1F4A4}',
    OK: '[onboarding] submit forgotten password request \u{2705}',
    FAIL: '[onboarding] submit forgotten password request \u{274C}',
  },

  TOKEN_UPGRADE: {
    OK: '[Token Upgrade] token upgrade complete',
    FAIL: '[Token Upgrade] token upgrade complete',
  },

  ACCOUNT_CREATION: {
    REQ: '[onboarding] create new user account \u{1F4A4}',
    OK: '[onboarding] create new user account \u{2705}',
    FAIL: '[onboarding] create new user account \u{274C}',
  },

  CLEAR_TOKEN: '[onboarding] clear token data from local storage',

  RESET_AUTH_VALUES: '[onboarding] reset credentials',

  VALIDATE_TOOLTIPCARD: '[onboarding] toggle password info tooltip',

  TOGGLE_PASSWORD_VIEW: '[onboarding] toggle password change view',

  USER_DETAILS_REQUIRED: '[onboarding] check new user account details entered',

  //Patient Finance

  GET_PATIENT_CONSULTATION_LIST: {
    REQ: '[patient finance] get consultations requiring invoice \u{1F4A4}',
    OK: '[patient finance] get consultations requiring invoice \u{2705}',
    FAIL: '[patient finance] get consultations requiring invoice \u{274C}',
  },

  GET_PATIENT_CONSULTATION_DETAIL: {
    REQ: '[patient finance] get consultation details \u{1F4A4}',
    OK: '[patient finance] get consultation details \u{2705}',
    FAIL: '[patient finance] get consultation details \u{274C}',
  },

  GET_PATIENT_BILLING_DATA: {
    REQ: '[patient finance] get patient invoices \u{1F4A4}',
    OK: '[patient finance] get patient invoices \u{2705}',
    FAIL: '[patient finance] get patient invoices \u{274C}',
  },

  CREATE_NEW_INVOICE_PDF: {
    REQ: '[patient finance] post invoice data \u{1F4A4}',
    OK: '[patient finance] post invoice data \u{2705}',
    FAIL: '[patient finance] post invoice data \u{274C}',
  },

  SET_TO_DEFAULT_VALUES: '[patient finance] reset invoice data',

  //Patient record

  GET_PATIENTS_LIST: {
    REQ: '[patient record] get patients list \u{1F4A4}',
    OK: '[patient record] get patients list \u{2705}',
    FAIL: '[patient record] get patients list \u{274C}',
  },

  PATIENT_SEARCH_VALUE: '[patient record] set patient search value',

  GET_FILTERED_PATIENTS: {
    REQ: '[patient record] get patients list based on search value \u{1F4A4}',
    OK: '[patient record] get patients list based on search value \u{2705}',
    FAIL: '[patient record] get patients list based on search value \u{274C}',
  },

  GET_DOCUMENTS_LIST: {
    REQ: '[patient record] get patient documents \u{1F4A4}',
    OK: '[patient record] get patient documents \u{2705}',
    FAIL: '[patient record] get patient documents \u{274C}',
  },

  GET_FILTERED_DOCUMENTS: {
    REQ:
      '[patient record] get patient documents based on filter value \u{1F4A4}',
    OK: '[patient record] get patient documents based on filter value \u{2705}',
    FAIL:
      '[patient record] get patient documents based on filter value \u{274C}',
  },

  SET_FILTERED_DOCUMENT_LIST:
    '[patient record] set patient documents based on filter value',

  SET_SELECTED_DOCUMENT: '[patient record] set patient document selected',

  GET_PATIENT_DETAIL: {
    REQ: '[patient record] get patient record data \u{1F4A4}',
    OK: '[patient record] get patient record data \u{2705}',
    FAIL: '[patient record] get patient record data \u{274C}',
  },

  GET_PATIENT_MEDICAL_HISTORY_OPTIONS_LIST: {
    REQ: '[patient record] get patient medical history options list \u{1F4A4}',
    OK: '[patient record] get patient medical history options list \u{2705}',
    FAIL: '[patient record] get patient medical history options list \u{274C}',
  },

  SET_SELECTED_PATIENT: '[patient record] set patient selected',

  RESET_SELECTED_PATIENT: '[patient record] reset patient selected',

  UPDATE_PATIENT_DETAIL: {
    REQ: '[patient record] patch patient record data \u{1F4A4}',
    OK: '[patient record] patch patient record data \u{2705}',
    FAIL: '[patient record] patch patient record data \u{274C}',
  },

  GET_PATIENT_TIMELINE_INTERACTIONS: {
    REQ: '[patient record] get patient timeline interactions \u{1F4A4}',
    OK: '[patient record] get patient timeline interactions \u{2705}',
    FAIL: '[patient record] get patient timeline interactions \u{274C}',
  },

  SET_INTERACTION_SEARCH_VALUE:
    '[patient record] set patient timeline interaction search value',

  ADD_PATIENT_DOCUMENT: {
    REQ: '[patient record] upload patient document \u{1F4A4}',
    OK: '[patient record] upload patient document \u{2705}',
    FAIL: '[patient record] upload patient document \u{274C}',
  },

  ADD_CORRESPONDENCE: {
    REQ: '[patient record] upload patient correspondence \u{1F4A4}',
    OK: '[patient record] upload patient correspondence \u{2705}',
    FAIL: '[patient record] upload patient correspondence \u{274C}',
  },

  GET_OUTSTANDING_ACTIONS: {
    REQ: '[patient record] get patient outstanding actions \u{1F4A4}',
    OK: '[patient record] get patient outstanding actions \u{2705}',
    FAIL: '[patient record] get patient outstanding actions \u{274C}',
  },

  GET_CONSULTATION_RECORD: {
    REQ: '[patient record] get patient medical record html \u{1F4A4}',
    OK: '[patient record] get patient medical record html \u{2705}',
    FAIL: '[patient record] get patient medical record html \u{274C}',
  },

  //Practitioner profile
  VALID_PASSWORD: {
    REQ: '[practitioner profile] check pracitioner password valid \u{1F4A4}',
    OK: '[practitioner profile] check pracitioner password valid \u{2705}',
    FAIL: '[practitioner profile] check pracitioner password valid \u{274C}',
  },

  RESET_VALUES: '[practitioner profile] reset prop values',

  RESET_PROFILE_VALUES: '[practitioner profile] reset profile values',

  GET_CLINICIAN_DETAILS: {
    REQ: '[practitioner profile] fetch clinician profile details \u{1F4A4}',
    OK: '[practitioner profile] fetch clinician profile details \u{2705}',
    FAIL: '[practitioner profile] fetch clinician profile details \u{274C}',
  },

  SET_TIME_SLOT:
    '[practitioner profile] set practitioner calendar availability',

  GET_REPORT_TABLE: {
    REQ: '[reports] fetch report details \u{1F4A4}',
    OK: '[reports] fetch report details \u{2705}',
    FAIL: '[reports] fetch report details \u{274C}',
  },

  STORE_REPORT_DATA: '[reports] backup report state',

  GET_REPORT_RECALL_TABLE: {
    REQ: '[report recalls] fetch report details \u{1F4A4}',
    OK: '[report recalls] fetch report details \u{2705}',
    FAIL: '[report recalls] fetch report details \u{274C}',
  },

  STORE_REPORT_RECALL_DATA: '[report recalls] backup report state',

  GET_REPORT_APPOINTMENTS_TABLE: {
    REQ: '[report appointments] fetch report details \u{1F4A4}',
    OK: '[report appointments] fetch report details \u{2705}',
    FAIL: '[report appointments] fetch report details \u{274C}',
  },

  STORE_REPORT_APPOINTMENTS_DATA: '[report appointments] backup report state',

  //Staff
  GET_CLINICIANS_LIST: {
    REQ: '[staff] fetch staff members \u{1F4A4}',
    OK: '[staff] fetch staff members \u{2705}',
    FAIL: '[staff] fetch staff members \u{274C}',
  },

  CLINICIAN_LIST_PATCH: {
    REQ: '[staff] patch staff members \u{1F4A4}',
    OK: '[staff] patch staff members \u{2705}',
    FAIL: '[staff] patch staff members \u{274C}',
  },

  CLINIC_LIST_PATCH: {
    REQ: '[staff] patch clinic staff \u{1F4A4}',
    OK: '[staff] patch clinic staff \u{2705}',
    FAIL: '[staff] patch clinic staff \u{274C}',
  },

  // Treatment library
  SET_TREATMENT_LIST: '[treatment library] set treatment list',

  SET_TREAT_TYPES: '[treatment library] set treatment types list',

  SET_FILTERED_LIST:
    '[treatment library] set treatment list with filter applied',

  SET_PRODUCT_SHEET: '[treatment library] set product sheet file',

  SET_CONSENT_FORM: '[treatment library] set consent form file',

  SET_PRICING: '[treatment library] set pricing options',

  SET_VIEWING_TREATMENT: '[treatment library] set current treatment value',

  SET_VIEWING_TREAT_OPTION:
    '[treatment library] set current treatment option value',

  TOGGLE_DEFAULT_TREATMENT: '[treatment library] set treatment modal status',

  POST_TREATMENT_DETAILS: {
    REQ: '[treatment library] add treatment \u{1F4A4}',
    OK: '[treatment library] add treatment \u{2705}',
    FAIL: '[treatment library] add treatment \u{274C}',
  },

  POST_TREATMENT_CONSENT: {
    REQ: '[treatment library] upload treatment consent form \u{1F4A4}',
    OK: '[treatment library] upload treatment consent form \u{2705}',
    FAIL: '[treatment library] upload treatment consent form \u{274C}',
  },

  POST_TREATMENT_PRODUCT: {
    REQ: '[treatment library] upload treatment product sheet \u{1F4A4}',
    OK: '[treatment library] upload treatment product sheet \u{2705}',
    FAIL: '[treatment library] upload treatment product sheet \u{274C}',
  },

  DELETE_TREATMENT: {
    REQ: '[treatment library] delete treatment \u{1F4A4}',
    OK: '[treatment library] delete treatment \u{2705}',
    FAIL: '[treatment library] delete treatment \u{274C}',
  },

  DELETE_CONSENT_TREATMENT: {
    REQ: '[treatment library] delete treatment consent form \u{1F4A4}',
    OK: '[treatment library] delete treatment consent form \u{2705}',
    FAIL: '[treatment library] delete treatment consent form \u{274C}',
  },

  DELETE_PRODUCT_SHEET_TREATMENT: {
    REQ: '[treatment library] delete treatment product sheet \u{1F4A4}',
    OK: '[treatment library] delete treatment product sheet \u{2705}',
    FAIL: '[treatment library] delete treatment product sheet \u{274C}',
  },

  POST_NEW_TREATMENT_OPTION_DETAILS: {
    REQ: '[treatment library] add treatment option \u{1F4A4}',
    OK: '[treatment library] add treatment option \u{2705}',
    FAIL: '[treatment library] add treatment option \u{274C}',
  },

  PATCH_EDIT_TREATMENT_OPTION_DETAILS: {
    REQ: '[treatment library] patch treatment option \u{1F4A4}',
    OK: '[treatment library] patch treatment option \u{2705}',
    FAIL: '[treatment library] patch treatment option \u{274C}',
  },

  EPHARM_LOGIN: {
    REQ: '[treatment library] epharm login \u{1F4A4}',
    OK: '[treatment library] epharm login \u{2705}',
    FAIL: '[treatment library] epharm login \u{274C}',
  },

  GET_PRODUCT_LIST_EPHARM: {
    REQ: '[treatment library] get epharm product list \u{1F4A4}',
    OK: '[treatment library] get epharm product list \u{2705}',
    FAIL: '[treatment library] get epharm product list \u{274C}',
  },

  SET_VIEWING_CLINIC: '[treatment library] set current viewing clinic',

  TREATMENT_UPDATE: '[treatment library] notify treatment updated',

  EDIT_TREATMENT: '[treatment library] set treatment being edited',

  FETCH_PRACTITIONER_APPOINTMENT: {
    REQ: '[calendar] fetch practitioner appointment list \u{1F4A4}',
    OK: '[calendar] fetch practitioner appointment list \u{2705}',
    FAIL: '[calendar] fetch practitioner appointment list \u{274C}',
  },

  FETCH_CLINICIANS_LIST: {
    REQ: '[calendar] fetch clinicians \u{1F4A4}',
    OK: '[calendar] fetch clinicians \u{2705}',
    FAIL: '[calendar] fetch clinicians \u{274C}',
  },

  FETCH_SCHEDULE_LIST: {
    REQ: '[calendar] fetch clinicians schedule \u{1F4A4}',
    OK: '[calendar] fetch clinicians schedule \u{2705}',
    FAIL: '[calendar] fetch clinicians schedule \u{274C}',
  },

  FETCH_SCHEDULE_CONFLICT_LIST: {
    REQ: '[calendar] fetch clinicians schedule conflicts \u{1F4A4}',
    OK: '[calendar] fetch clinicians schedule conflicts \u{2705}',
    FAIL: '[calendar] fetch clinicians schedule conflicts \u{274C}',
  },

  TOGGLE_DATE_PICKER: '[calendar] toggle date picker visibility',

  CALENDAR_HEADER_RENDER_DATE: '[calendar] set header date',

  GET_ZOOM_STATUS: '[calendar] set zoom state',

  SHOW_PICKER: '[calendar] show appointment date picker',

  SET_CLINICS_LIST: '[calendar] set available clinic list',

  VIEWING_CLINIC: '[calendar] set current viewing clinic',

  CLINIC_UPDATED: '[calendar] set current viewing clinic has changed',

  SET_APPOINTMENT_OPENING_TIME: '[calendar] update clinic day opening times',

  CLOSE_DATE_PICKER: '[calendar] close appointment date picker',

  APPOINTMENT_UPDATED: '[calendar] notify appointment details have changed',

  SET_APPOINTMENT_SCROLL_POS:
    '[calendar] set calendar scroll position for appointment',

  //Schedule
  CALENDAR_HEADER_RENDER_MONTH: '[schedule] set header month',

  RESET_SCHEDULE_DELETION_PROP: '[schedule] reset selected schedule',

  PASS_SCHEDULE_DETAILS: '[schedule] pass selected schedule details',

  CHECK_DELETE_SCHEDULE_CHANGE: {
    FAIL: '[schedule] check delete schedule change \u{274C}',
  },

  POST_SCHEDULE_CHANGE: {
    REQ: '[schedule] post schedule change \u{1F4A4}',
    OK: '[schedule] post schedule change \u{2705}',
    FAIL: '[schedule] post schedule change \u{274C}',
  },

  DELETE_BOOKED_SCHEDULE: {
    REQ: '[schedule] delete schedule change \u{1F4A4}',
    OK: '[schedule] delete schedule change \u{2705}',
    FAIL: '[schedule] delete schedule change \u{274C}',
  },

  //System support
  QUERY_POST: {
    REQ: '[schedule] post support query \u{1F4A4}',
    OK: '[schedule] post support query \u{2705}',
    FAIL: '[schedule] post support query \u{274C}',
  },
}
