import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_GetPatientDetail,
  A_GetTimelineInteractions,
  A_GetOutstandingActions,
  A_AddPatientDocument,
  A_AddCorrespondence,
  A_SetInteractionSearchValue,
  A_GetConsultationRecord,
  A_GetPatientConsultationsList,
  A_SetToDefaultValues,
  A_GetClinicsList,
} from '../../actions'
import * as utils from '../../utilities/ReusableFunctions'
import * as obj from '../../utilities/ReusableObjects'
import debounce from 'lodash/debounce'
import PatientRecordSidebar from '../../components/PatientRecord/Sidebar'
import PatientInteractionCard from '../../components/PatientRecord/PatientInteractionCard'
import SearchHeader from '../../components/Search/Header'
import SearchResultMessage from '../../components/Search/ResultMessage'
import DocumentUploadModal from '../../components/PatientRecord/DocumentUpload'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import Loader from '../../components/PatientData/Loader'
import FeedbackStrap from '../../components/Feedback/FeedbackStrap'
import { TutorialContext } from '../../contexts'
import TutorialBtn from '../../containers/Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

import { currentView } from '../../features/AppointmentModal/types'
import { openAppointmentModal } from '../../features/AppointmentModal/actions'
import moment from 'moment'

class PatientInteractionTimeline extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientsLoaded: false,
      actionsLoaded: false,
      isClinicUnavailable: false,
      showDocumentUploadModal: false,
      showReferralUploadModal: false,
      referralFollowUpId: null,
      selectedReferralNote: '',
      searchValue: '',
      showFeedback: false,
      appointmentActions: [],
      referralActions: [],
      invoiceActions: [],
      feedbackId: 'cancel_feedback',
      feedbackMessage:
        'Appointment has been cancelled and removed from all calendars',
      filters: [
        {
          id: '1',
          label: 'Consultations',
          name: 'Consultations',
          classContainer: 'radioInput',
          checked: true,
        },
        {
          id: '2',
          label: 'Correspondence',
          name: 'Correspondence',
          classContainer: 'radioInput',
          checked: true,
        },
      ],
    }

    this.getInitialPatientData = this.getInitialPatientData.bind(this)
    this.getInteractions = this.getInteractions.bind(this)
    this.getInteractionsList = this.getInteractionsList.bind(this)
    this.onCorrespondenceSave = this.onCorrespondenceSave.bind(this)
    this.onReferralSave = this.onReferralSave.bind(this)
    this.showDocumentUploadModal = this.showDocumentUploadModal.bind(this)
    this.hideDocumentUploadModal = this.hideDocumentUploadModal.bind(this)
    this.showReferralUploadModal = this.showReferralUploadModal.bind(this)
    this.hideReferralUploadModal = this.hideReferralUploadModal.bind(this)
    this.newAppointment = this.newAppointment.bind(this)
    this.bookAppointment = this.bookAppointment.bind(this)
    this.searchInteractions = this.searchInteractions.bind(this)
    this.search = debounce(this.search.bind(this), 200)
    this.checkHasSearchProperties = this.checkHasSearchProperties.bind(this)
    this.filterInteractions = this.filterInteractions.bind(this)
    this.viewAppointment = this.viewAppointment.bind(this)
    this.redirectToCalendar = this.redirectToCalendar.bind(this)
    this.showFeedback = this.showFeedback.bind(this)
    this.hideFeedback = this.hideFeedback.bind(this)
    this.setOutstandingActions = this.setOutstandingActions.bind(this)
    this.onOutstandingAppointmentClick = this.onOutstandingAppointmentClick.bind(
      this,
    )
    this.openInvoiceBuilder = this.openInvoiceBuilder.bind(this)
    this.getTreatmentsFromOutstandingAction = this.getTreatmentsFromOutstandingAction.bind(
      this,
    )
    this.loadNextInteractions = this.loadNextInteractions.bind(this)
  }

  getInteractions() {
    const { patient } = this.props
    let interactionCards = []
    let isFuture = true
    let isPast = false
    let interactionData = this.getInteractionsList()
    interactionData.forEach((interactionItem, index) => {
      let interaction = interactionItem.correspondence
        ? interactionItem.correspondence
        : interactionItem.appointment
      isPast =
        interactionItem.correspondence ||
        (interactionItem.appointment &&
          utils.checkAppointmentInPast(interactionItem.appointment)) ||
        (interactionItem.appointment &&
          interactionItem.appointment.status < 0) ||
        (interactionItem.appointment && interactionItem.appointment.status > 2)
      if (isPast && index === 0) {
        isFuture = false
      }
      if (isPast && isFuture) {
        isFuture = false
        interactionCards.push(
          <div
            key={`divider_${interactionItem.id}`}
            className="divider__container"
          >
            <div className="currentTimeDivisionIndicator--timeline" />
            <div className="currentTimeIndicator--timeline" />
          </div>,
        )
      }
      interactionItem.appointment
        ? interaction.consultations && interaction.consultations.length > 0
          ? interaction.consultations.forEach((consultation, i) => {
              interactionCards.push(
                <PatientInteractionCard
                  key={`consult_${consultation.id}`}
                  consultIndex={i}
                  idRef={`consult_${consultation.id}`}
                  type={
                    utils.checkAppointmentInPast(interaction)
                      ? 'appointmentPast'
                      : 'appointmentFuture'
                  }
                  view="consultation"
                  interactionData={interaction}
                  searchValue={this.state.searchValue}
                  bookAppointment={this.bookAppointment}
                  patient={patient}
                  onClick={() => {
                    this.viewAppointment(interaction)
                  }}
                />,
              )
            })
          : interactionCards.push(
              <PatientInteractionCard
                key={`treat_${interactionItem.id}`}
                consultIndex={0}
                idRef={`treat_${index}`}
                type={
                  utils.checkAppointmentInPast(interaction)
                    ? 'appointmentPast'
                    : 'appointmentFuture'
                }
                view="treatment"
                interactionData={interaction}
                searchValue={this.state.searchValue}
                patient={patient}
                onClick={() => {
                  this.viewAppointment(interaction)
                }}
              />,
            )
        : interactionCards.push(
            <PatientInteractionCard
              key={`correspondence_${interactionItem.id}`}
              consultIndex={0}
              idRef={`correspondence_${index}`}
              type="correspondence"
              view="correspondence"
              interactionData={interaction}
              patient={patient}
              searchValue={this.state.searchValue}
            />,
          )
    })
    return interactionCards
  }

  loadNextInteractions() {
    const { actions, patient, searchValue, nextQuery, global } = this.props

    let clinic = global.currentClinicID

    actions.A_GetTimelineInteractions(
      clinic,
      patient.id,
      searchValue,
      this.getFilters(),
      nextQuery,
    )
  }

  onCorrespondenceSave() {
    const { patient, actions, global } = this.props

    let clinic = global.currentClinicID

    actions.A_GetTimelineInteractions(
      clinic,
      patient.id,
      this.state.searchValue,
      this.getFilters(),
    )
    this.hideDocumentUploadModal()
  }

  onReferralSave() {
    const { actions, patient, global } = this.props

    let clinic = global.currentClinicID

    actions.A_GetTimelineInteractions(
      clinic,
      patient.id,
      this.state.searchValue,
      this.getFilters(),
    )
    this.setState(
      () => ({
        actionsLoaded: false,
      }),
      () => {
        actions.A_GetOutstandingActions(clinic, patient.id).then(response => {
          this.setOutstandingActions(response)
        })
      },
    )

    this.hideReferralUploadModal()
  }

  setOutstandingActions(actions) {
    this.setState(() => ({
      appointmentActions: actions.appointment_follow_ups,
      referralActions: actions.referral_follow_ups,
      invoiceActions: actions.consultations,
      actionsLoaded: true,
    }))
  }

  showDocumentUploadModal() {
    this.setState(() => ({
      showDocumentUploadModal: true,
    }))
  }

  hideDocumentUploadModal() {
    this.setState(() => ({
      showDocumentUploadModal: false,
    }))
  }

  showReferralUploadModal(action) {
    const note = action.notes || ''
    this.setState(() => ({
      showReferralUploadModal: true,
      selectedReferralNote: note,
      referralFollowUpId: action.id,
    }))
  }

  hideReferralUploadModal() {
    this.setState(() => ({
      showReferralUploadModal: false,
      selectedReferralNote: '',
      referralFollowUpId: null,
    }))
  }

  openInvoiceBuilder(action) {
    const { history, patient } = this.props
    history.push(
      `/clinics/patients/${patient.id}/finances/invoice/${action.id}`,
    )
  }

  showFeedback() {
    this.setState(() => ({
      showFeedback: true,
    }))
  }

  hideFeedback() {
    this.setState(() => ({
      showFeedback: false,
    }))
  }

  checkHasSearchProperties() {
    if (
      this.state.filters[0].checked &&
      this.state.filters[1].checked &&
      this.state.searchValue.length === 0
    ) {
      return false
    }
    return true
  }

  searchInteractions(event) {
    this.search(event.target.value)
  }

  search(searchVal) {
    const { actions, searchValue, patient, global } = this.props

    let clinic = global.currentClinicID

    if (searchVal && searchVal.trim().length > 0) {
      searchVal = searchVal.trim()
    } else {
      searchVal = ''
    }
    if (!this.state.isClinicUnavailable) {
      actions.A_SetInteractionSearchValue(searchVal)
      actions
        .A_GetTimelineInteractions(
          clinic,
          patient.id,
          searchVal,
          this.getFilters(),
        )
        .then(() => {
          this.setState(() => ({
            searchValue: searchValue,
          }))
        })
    }
  }

  getFilters() {
    let filters = []
    if (this.state.filters[0].checked) {
      filters.push('0')
    }
    if (this.state.filters[1].checked) {
      filters.push('1')
    }
    if (filters.length > 0) {
      return filters.join(',')
    }
    return -1
  }

  filterInteractions(filters) {
    this.setState(
      () => ({
        filters: filters,
      }),
      () => {
        this.search(this.state.searchValue)
      },
    )
  }

  getInteractionsList() {
    const { patientInteractions } = this.props
    return utils.getUniqueIdValues(patientInteractions)
  }

  redirectToCalendar() {
    const { history } = this.props
    history.push('/clinics/calendar/appointments')
  }

  onOutstandingAppointmentClick(action) {
    if (action.arrange) {
      const  { treatments } = this.getTreatmentsFromOutstandingAction(action)
      const followUpDate = utils.getFollowUpDate(action)
      const { patientId } = this.props.match.params

      const appointmentDetails = {
        patient: {id: Number(patientId)},
        purpose: action.purpose,
        treatments,
        start_date: followUpDate,
        end_date: moment.tz(followUpDate, 'UTC').add(Number(action.duration), 'm').toISOString(),
        clinician: action.consultation.appointment.clinician,
        appointment_follow_up: { id: action.id },
      }

      this.bookAppointment(appointmentDetails)
    }
  }

  getTreatmentsFromOutstandingAction(action) {
    let treatments = action.treatment_plans.map(({ treatment }) => treatment)
    let treatmentType = -1

    if (treatments.length === 0) {
      action.examination_concerns.forEach(({ treatments }) => {
        treatments.forEach(({ treatment }) => {
          treatments.push(treatment)
        })
      })
    }

    if (treatments.length === 0) {
      treatments = action.consultation.appointment.treatments
    }

    treatmentType = treatments[0].treatment_type.id

    return { treatments, treatmentType }
  }

  newAppointment() {
    const { actions, global } = this.props
    const { patientId } = this.props.match.params

    const data = {
      clinicId: global.currentClinicID,
      initialValues: {
        patient_id: Number(patientId),
        appointment_type: obj.appointmentReasonIds.APPOINTMENT
      },
      handleCreate: this.onReferralSave(),
      handleUpdate: this.onReferralSave(),
      handleDelete: this.onReferralSave()
    }

    actions.openAppointmentModal(data)
  }

  async bookAppointment(appointmentDetails) {
    const { actions, global } = this.props

    const data = {
      clinicId: global.currentClinicID,
      initialValues: {
        patient_id: appointmentDetails.patient.id,
        clinicianId: appointmentDetails.clinician.id,
        purpose: appointmentDetails.purpose,
        treatments: appointmentDetails.treatments.map(({id}) => id),
        appointment_follow_up: appointmentDetails.appointment_follow_up?.id,
        startDate: appointmentDetails.start_date,
        endDate: appointmentDetails.end_date,
      },
      handleCreate: this.onReferralSave(),
      handleUpdate: this.onReferralSave(),
      handleDelete: this.onReferralSave()
    }

    actions.openAppointmentModal(data)
  }

  viewAppointment(interaction) {
    const { actions } = this.props

    const data = {
      appointmentId: interaction.id,
      currentView: currentView['VIEW'],
      handleUpdate: this.onReferralSave(),
      handleDelete: this.onReferralSave()
    }

    actions.openAppointmentModal(data)
  }

  componentDidMount = () => {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const { patientId } = this.props.match.params
      const clinic = global.currentClinicID

      if (clinic) {
        actions.A_GetPatientDetail(clinic, patientId).then(() => {
          this.getInitialPatientData(clinic)
        })
      } else {
        this.setState(() => ({
          isLoading: false,
          isClinicUnavailable: true,
        }))
      }
    })
  }

  getInitialPatientData(clinic) {
    const { invoices, actions } = this.props
    const { patientId } = this.props.match.params
    if (invoices.createInvoiceSuccess) {
      this.setState(
        () => ({
          feedbackId: 'invoice',
          feedbackMessage: 'Invoice created successfully',
        }),
        () => {
          actions.A_SetToDefaultValues()
          this.showFeedback()
          this.hideFeedbackTimeout = setTimeout(() => {
            this.hideFeedback()
          }, 2500)
        },
      )
    }
      actions
      .A_GetTimelineInteractions(
        clinic,
        patientId,
        this.state.searchValue,
        this.getFilters(),
      )
      .then(() => {
        this.setState(() => ({
          patientsLoaded: true,
        }))
      })
    actions.A_GetOutstandingActions(clinic, patientId).then(response => {
      this.setOutstandingActions(response)
    })
  }

  componentWillUnmount() {
    clearTimeout(this.hideFeedbackTimeout)
    clearTimeout(this.closeTimeout)
  }

  render() {
    const {
      patient,
      patientInteractions,
      nextQuery,
      global: { currentClinicID : clinic, clinics },
    } = this.props

    const searchButtons = [
      {
        type: 'button',
        style: 'secondary',
        label: 'Add to Timeline',
        size: 'small',
        isDisabled: this.state.isClinicUnavailable,
        events: { onClick: this.showDocumentUploadModal },
      },
    ]
    const filteredInteractions = this.getInteractions()
    const allergies = utils.getPatientAlertData(patient, 'allergies')
    const cosmetic = utils.getPatientAlertData(patient, 'cosmetic_history')

    return (
      <React.Fragment>
        <main className="main main__patient">
          <SearchHeader
            wrapperClass="search_wrapper--hasSidebar"
            buttonSet={searchButtons}
            filterSet={this.state.filters}
            onChange={this.searchInteractions}
            onFilterChange={this.filterInteractions}
          />
          {this.props.patient &&
          this.props.patient.requires_medical_history_update ? (
            <FeedbackStrap
              isAlert={true}
              hasMargin={false}
              hasOffset={true}
              message="Medical history outdated, login on iPad to update"
            />
          ) : (
            ''
          )}
          <section className="main__inner main__inner--hasSidebar noMargin">
            <div className="patient_warning--inline">
              {allergies.map(allergy => {
                return (
                  <article
                    key={'allergy_' + allergy}
                    className="patient_warning_info patient_warning_info--inline"
                  >
                    <span className="patient_warning_icon icon-warning" />
                    <p className="patient_warning_text noMargin">{allergy}</p>
                  </article>
                )
              })}
              {cosmetic.map(cosmetic => {
                return (
                  <article
                    key={'cosmetic_' + cosmetic}
                    className="patient_warning_info patient_warning_info--inline patient_warning_info--cosmetic"
                  >
                    <span className="patient_warning_icon icon-warning" />
                    <p className="patient_warning_text noMargin">{cosmetic}</p>
                  </article>
                )
              })}
            </div>
            {this.state.patientsLoaded ? (
              this.checkHasSearchProperties() ? (
                filteredInteractions.length > 0 ? (
                  filteredInteractions
                ) : (
                  <SearchResultMessage
                    message="No patient interactions match your search"
                    searchValue={this.state.searchValue}
                  />
                )
              ) : patientInteractions.length > 0 ? (
                filteredInteractions
              ) : (
                <SearchResultMessage
                  message="No patient interactions"
                  button={{
                    label: 'Book an appointment',
                    style: 'primary',
                    clickHandler: this.newAppointment,
                  }}
                />
              )
            ) : this.state.isClinicUnavailable ? (
              ''
            ) : (
              <Loader />
            )}
            {this.state.patientsLoaded && nextQuery ? (
              <article className="pagination_wrapper">
                <button
                  className="buttonTransparent textLink"
                  onClick={e => {
                    this.loadNextInteractions(e)
                  }}
                >
                  See more
                </button>
              </article>
            ) : (
              ''
            )}
          </section>
          {this.state.showDocumentUploadModal ? (
            <DocumentUploadModal
              title="New correspondence"
              onSaveSelected={this.onCorrespondenceSave}
              onCloseSelected={this.hideDocumentUploadModal}
              clinicId={clinic}
              patientId={patient.id}
              isCorrespondence={true}
            />
          ) : null}
          {this.state.showReferralUploadModal ? (
            <DocumentUploadModal
              title="Referral Letter"
              description={this.state.selectedReferralNote}
              documentType="Referral Letter"
              onSaveSelected={this.onReferralSave}
              onCloseSelected={this.hideReferralUploadModal}
              clinicId={clinic}
              patientId={patient.id}
              isReferral={true}
              followUpId={this.state.referralFollowUpId}
            />
          ) : null}
          <PatientRecordSidebar
            title="Outstanding actions"
            infoMessage="Consultation actions sent by clinicians appear here"
            appointmentActions={this.state.appointmentActions}
            referralActions={this.state.referralActions}
            invoiceActions={this.state.invoiceActions}
            onAppointmentClick={this.onOutstandingAppointmentClick}
            onReferralClick={this.showReferralUploadModal}
            onInvoiceClick={this.openInvoiceBuilder}
            clinicList={clinics}
            isLoading={!this.state.actionsLoaded}
            history={this.props.history}
          />
          {this.state.showFeedback ? (
            <FeedbackNotification
              id={this.state.feedbackId}
              message={this.state.feedbackMessage}
              isWarning={false}
              hideFeedback={this.hideFeedback}
            />
          ) : (
            ''
          )}
        </main>
        {isTutorialFeatureEnabled ? (
          <TutorialContext.Consumer>
            {({
              toggleTutorial,
              setTutorial,
              tutorialList,
              history,
              activeTutorial,
            }) => (
              <TutorialBtn
                tutorialList={tutorialList}
                toggleTutorial={toggleTutorial}
                setTutorial={setTutorial}
                activeTutorial={activeTutorial}
                history={history}
              />
            )}
          </TutorialContext.Consumer>
        ) : null}
      </React.Fragment>
    )
  }
}

PatientInteractionTimeline.defaultProps = {
  searchValue: '',
  nextQuery: null,
  patient: null,
  appointmentAlerts: [],
}

PatientInteractionTimeline.propTypes = {
  searchValue: PropTypes.string,
  nextQuery: PropTypes.string,
  history: PropTypes.object.isRequired,
  patientInteractions: PropTypes.array.isRequired,
  invoices: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  patient: PropTypes.object,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  clinicAppointment: PropTypes.object.isRequired,
  bookedAppointment: PropTypes.object.isRequired,
  appointmentAlerts: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    patient: state.patients.selectedPatient,
    patientInteractions: state.patients.patientInteractions,
    searchValue: state.patients.patientInteractionSearchValue,
    nextQuery: state.patients.patientInteractionsNextQuery,
    invoices: state.finance,
    global: state.global,
    clinicAppointment: state.clinicAppointment,
    bookedAppointment: state.bookedAppointment.data,
    appointmentAlerts: state.bookedAppointment.alerts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetPatientDetail,
        A_GetTimelineInteractions,
        A_GetOutstandingActions,
        A_AddPatientDocument,
        A_AddCorrespondence,
        A_SetInteractionSearchValue,
        A_GetConsultationRecord,
        A_GetPatientConsultationsList,
        A_SetToDefaultValues,
        A_GetClinicsList,
        openAppointmentModal
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientInteractionTimeline),
)
