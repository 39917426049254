import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ClinicSideBar from './ClinicNavBar'
import Header from '../../components/Header/ClinicHeader'
import {
  A_GetUserDetails,
  A_GetNotificationsUnread,
  A_GetNotificationList,
  A_MarkUpdateRead,
  A_GetCliniciansList,
  A_PostNotification,
} from '../../actions'
import Avatar from '../../components/Avatar'
import ModalRightSideBody from '../../components/Modal/RightPanel/Body'
import ModalRightSide from '../../components/Modal/RightPanel/'
import { getInitials, getFullName } from '../../utilities/ReusableFunctions'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import SupportIcon from '../../assets/support.svg?react'
import { isSupportFeatureEnabled } from '../../utilities/featureToggle'
import { REDIRECT_TO_V2_APP } from '../../api_urls'
import { currentView } from '../../features/AppointmentModal/types'
import { openAppointmentModal } from '../../features/AppointmentModal/actions'

var notificationInterval
var timeStateInterval

class ClinicsHeaderPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      avatar: '',
      notifications: 0,
      curTime: new Date().toLocaleString,
      userTitle: '',
      messageActive: false,
      clinicians: [],
      staffList: [],
      messageStaffValue: '',
      messageAreaValue: '',
      selectedClinician: {},
      showFeedback: false,
    }
    this.gotoProfile = this.gotoProfile.bind(this)
    this.openNotifications = this.openNotifications.bind(this)
    this.viewAppointmentModal = this.viewAppointmentModal.bind(this)
    this.loadNextNotifications = this.loadNextNotifications.bind(this)
    this.profileOptions = React.createRef()
    this.header = React.createRef()
  }

  componentDidMount = () => {
    const { actions } = this.props
    actions.A_GetUserDetails().then(res => {
      this.setState({
        avatar: res.avatar ? res.signed_avatar : '',
        userTitle: getInitials(res),
      })
    })

    this.checkIfUnread()

    document.addEventListener('mousedown', this.handleClick, false)

    try {
      timeStateInterval = setInterval(() => {
        this.setState({
          curTime: new Date().toLocaleString(),
        })
      }, 1000) //60000 1 minute, 1000 1 second
    } catch (e) {} // eslint-disable-line
  }
  componentDidUpdate = () => {
    const { clinicSetUp } = this.props
    if (this.state.avatar !== clinicSetUp.avatar) {
      this.setState({ avatar: clinicSetUp.avatar })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
    clearInterval(notificationInterval)
    clearInterval(timeStateInterval)
    if (this.timer1) {
      clearTimeout(this.timer1)
      this.timer1 = 0
    }
    if (this.timer2) {
      clearTimeout(this.timer2)
      this.timer2 = 0
    }
  }

  checkIfUnread = () => {
    const { actions } = this.props
    actions.A_GetNotificationsUnread().then(unread => {
      this.setState({ unread: unread })

      setTimeout(() => {
        this.checkIfUnread()
      }, 30000)
    })
  }

  loadClinicians() {
    const { actions } = this.props

    actions.A_GetCliniciansList().then(response => {
      let staffRows = response.map(function(staff) {
        return {
          id: staff.invite.id,
          status: staff.invite.status,
          account_owner: staff.invite.account_owner,
          is_admin: staff.invite.is_admin,
          clinician: staff,
        }
      })
      this.setState({
        clinicians: staffRows,
      })
    })
  }

  closeNotifications = () => {
    const modal = document.getElementById('notificationPanel')
    let overlay = document.getElementById('overlayNotification')

    if (typeof modal !== 'undefined' && modal !== null) {
      modal.classList.remove('fadeInAnimateFull')
      modal.classList.add('fadeOutAnimateFull')
      this.timer1 = setTimeout(function() {
        modal.style.display = 'none'
      }, 500 /* Length of CSS Animation */)
    }
    if (typeof overlay !== 'undefined' && overlay !== null) {
      overlay.classList.add('fadeOutAnimate')
      overlay.classList.remove('fadeInAnimate')
      overlay.style.display = 'none'
    }
  }

  openNotifications() {
    this.loadNotifications()

    let overlay = document.getElementById('overlayNotification')
    const modal = document.getElementById('notificationPanel')

    if (typeof modal !== 'undefined' && modal !== null) {
      modal.classList.remove('fadeOutAnimateFull')
      modal.classList.add('fadeInAnimateFull')
      modal.style.display = 'block'
    }
    if (typeof overlay !== 'undefined' && overlay !== null) {
      overlay.classList.remove('fadeOutAnimate')
      overlay.classList.add('fadeInAnimate')
      overlay.style.display = 'block'
    }
  }

  async viewAppointmentModal(appointmentId) {
    const { actions } = this.props

    const data = {
      appointmentId,
      currentview: currentView['VIEW']
    }

    //handles need to call this.getBookedAppointments() from weekly calendar or profile calendar (if visible)

    actions.openAppointmentModal(data)
  }

  loadNotifications() {
    const { actions } = this.props
    actions.A_GetNotificationList()
  }

  loadNextNotifications() {
    const { actions, nextNotificationQuery } = this.props
    actions.A_GetNotificationList(nextNotificationQuery)
  }

  notificationClick = ({
    title,
    appointment,
    consultation,
    patient,
  }) => {
    this.closeNotifications()
    const { history } = this.props
    switch (title) {
      case 'Create Invoice':
        history.push(
          `/clinics/patients/${patient.id}/finances/invoice/${consultation.id}`,
        )
        break
      case 'Create Follow Up':
        history.push(`/clinics/patients/${patient.id}/treatments`)
        break
      default:
        return this.viewAppointmentModal(appointment.id)
    }
  }

  gotoProfile() {
    const { history, global } = this.props

    const currentUserID = global.currentUserID

    history.push(`/clinics/profile/${currentUserID}`)
  }

  handleSignOut = event => {
    event.preventDefault()
    localStorage.clear()
    sessionStorage.clear()
    window.open(`${REDIRECT_TO_V2_APP}/-/logout`, '_self')
  }

  handleClick = e => {
    if (this.profileOptions.current.classList.contains('hidden')) {
      if (this.header.current.contains(e.target)) {
        this.profileOptions.current.classList.remove('hidden')
        this.profileOptions.current.classList.remove('fadeOutAnimateFull')
        this.profileOptions.current.classList.add('fadeInAnimateFull')
      }
    } else {
      e.preventDefault()
      this.profileOptions.current.classList.add('fadeOutAnimateFull')
      this.profileOptions.current.classList.remove('fadeInAnimateFull')
      this.timer2 = setTimeout(
        function() {
          this.profileOptions.current.classList.add('hidden')
        }.bind(this),
        500,
      )
    }
  }

  toggleMessage = e => {
    if (e) {
      this.loadClinicians()
      this.setState({
        messageActive: true,
      })
    }
  }

  toggleNotification = e => {
    if (e) {
      this.setState({
        messageActive: false,
      })
    }
  }

  selectClinician = event => {
    event.preventDefault()
    let staffRows = this.state.clinicians
    let invitedStaff = staffRows.find(staff => {
      return staff.id === Number(event.currentTarget.dataset.patient)
    })

    this.setState({
      selectedClinician: {
        ...invitedStaff.clinician,
        status: invitedStaff.status,
        account_owner: invitedStaff.account_owner,
        is_admin: invitedStaff.is_admin,
      },
      messageStaffValue: getFullName(invitedStaff.clinician),
    })
  }

  sendMessage = e => {
    const { actions } = this.props
    let clinician = this.state.selectedClinician
    let messageAreaValue = this.state.messageAreaValue
    if (e) {
      this.setState({
        showFeedback: false,
      })
      if (clinician.id && messageAreaValue.length > 1) {
        let message = {
          clinician: clinician,
          detail: messageAreaValue,
        }
        actions.A_PostNotification(message).then(() => {
          this.setState({
            showFeedback: true,
            selectedClinician: {},
            messageAreaValue: '',
            messageStaffValue: '',
          })
        })
      }
    }
  }

  messageStaffAction = e => {
    const { global } = this.props

    const currentUserID = global.currentUserID

    let staff = this.state.clinicians
    let searchValue = ''
    let selectedClinician = this.state.selectedClinician

    if (e) {
      searchValue = e.target.value
      staff = staff.filter(staff => {
        return (
          staff.clinician.id !== currentUserID &&
          getFullName(staff.clinician)
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
        )
      })
      if (selectedClinician.id) {
        selectedClinician = {}
      }
      this.setState({
        staffList: staff,
        messageStaffValue: searchValue,
        selectedClinician: selectedClinician,
      })
    }
  }

  messageAreaAction = e => {
    if (e) {
      this.setState({ messageAreaValue: e.target.value })
    }
  }

  render() {
    const {
      location,
      clinicSetUp,
      notifications,
      nextNotificationQuery,
      global: {currentUserID, is_admin},
    } = this.props    
    let truncateHeader = location.pathname.indexOf('/clinics/patients') > -1
    return (
      <div className="no-print">
        <section
          className={`nav--tool ${truncateHeader ? 'flex--nowrap' : ''}`}
        >
          <Header props={this.props} />
          <article
            className={`nav--tool__userDetails ${
              truncateHeader ? 'flex--nowrap flex--noshrink' : ''
            }`}
          >
            <Link to="/clinics/support">
              {isSupportFeatureEnabled && <SupportIcon />}
            </Link>
            <span
              role="button"
              tabIndex="0"
              className={`nav--tool__userDetails__notifications icon-bell ${
                this.state.unread ? 'nav--tool__notifications--alert' : ''
              } buttonCircle`}
              onClick={this.openNotifications}
              onKeyDown={this.openNotifications}
            />
            <aside
              role="button"
              tabIndex="0"
              className="nav--tool__userDetails__profile"
              onClick={this.showProfileOptions}
              onKeyDown={this.showProfileOptions}
              ref={this.header}
            >
              <Avatar
                src={this.state.avatar}
                type="practitionerProfileHeaderCalendar"
                initials={clinicSetUp.initials}
              />
              <span className="nav--tool__userDetails__name">
                {clinicSetUp.userName}
              </span>
              <span className="nav--tool__userDetails__dropDownIcon icon-chevron-down" />
            </aside>
          </article>
        </section>
        <ul className="nav__profileOptions hidden" ref={this.profileOptions}>
          <li className="nav__profileOptions__heading">Your Clever Clinic</li>
          <li role="presentation">
            <Link
              to={`/clinics/profile/${currentUserID}`}
              className="nav__profileOptions__item"
            >
              Profile
            </Link>
          </li>
          <li>
            <a
              className="nav__profileOptions__item"
              href="/clinics/contact-support"
            >
              Contact Support
            </a>
          </li>
          {is_admin && (
            <li role="presentation">
              <Link
                to="/clinics/settings"
                className="nav__profileOptions__item"
              >
                Settings
              </Link>
            </li>
          )}
          <li
            onClick={this.handleSignOut}
            onKeyDown={this.handleSignOut}
            role="presentation"
          >
            <a className="nav__profileOptions__item" href="">
              Sign out
            </a>
          </li>
        </ul>
        <ClinicSideBar props={this.props} />
        <ModalRightSide
          toggleModal={() => this.closeNotifications()}
          date={this.state.curTime}
          messageActive={this.state.messageActive}
          toggleMessage={e => this.toggleMessage(e)}
          toggleNotification={e => this.toggleNotification(e)}
        >
          <ModalRightSideBody
            notifications={notifications.notificationsList}
            nextQuery={nextNotificationQuery}
            loadNextNotifications={this.loadNextNotifications}
            handleClick={e => this.notificationClick(e)}
            messageActive={this.state.messageActive}
            messageStaffValue={this.state.messageStaffValue}
            messageStaffAction={e => this.messageStaffAction(e)}
            staffList={this.state.staffList}
            messageAreaValue={this.state.messageAreaValue}
            messageAreaAction={e => this.messageAreaAction(e)}
            selectClinician={e => this.selectClinician(e)}
            selectedClinician={this.state.selectedClinician}
            sendMessage={e => this.sendMessage(e)}
          />
          {this.state.showFeedback ? (
            <div className="notificationFeedback">
              <FeedbackNotification
                id="feedbackMessage"
                message="Message sent"
                isWarning={false}
                hideFeedback={() => {}}
              />
            </div>
          ) : (
            ''
          )}
        </ModalRightSide>
      </div>
    )
  }
}

ClinicsHeaderPage.defaultProps = {
  nextNotificationQuery: null,
  appointmentAlerts: [],
}

ClinicsHeaderPage.propTypes = {
  actions: PropTypes.object.isRequired,
  clinicSetUp: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nextNotificationQuery: PropTypes.string,
  notifications: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  bookedAppointment: PropTypes.object.isRequired,
  appointmentAlerts: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    clinicSummary: state.clinicSummary,
    clinicSetUp: state.accountSetup,
    notifications: state.notifications,
    nextNotificationQuery: state.notifications.nextQuery,
    global: state.global,
    bookedAppointment: state.bookedAppointment.data,
    appointmentAlerts: state.bookedAppointment.alerts,
    clinicAppointment: state.cinicAppointment,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetUserDetails,
        A_GetNotificationsUnread,
        A_GetNotificationList,
        A_MarkUpdateRead,
        A_GetCliniciansList,
        A_PostNotification,
        openAppointmentModal,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClinicsHeaderPage),
)
