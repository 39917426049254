export const highlightSearchResult = (text, search) => {
  if (text && text.length > 0 && search.length > 0) {
    const items = text.toString().split(new RegExp(`(${search})`, 'gi'))
    return items.map((item, i) => {
      let indexVal = i
      return item.toLowerCase() === search.toLowerCase() ? (
        <span key={indexVal} className="searchHighlight">
          {item}
        </span>
      ) : (
        item
      )
    })
  }
  return text
}