import { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_ClearToken,
  A_CalendarHeaderActions,
} from '../../actions'

import ClinicsHeaderPage from './ClinicsHeaderPage'
import EditPractitionerProfile from '../PractitionerProfile/EditPractitionerProfile'
import SystemSupport from '../SystemSupport/SystemSupport'
import WeeklyCalendarContainer from '../Calendar/WeeklyCalendarContainer'
import MonthlySchedule from '../Schedule/MonthlySchedule'
import PractitionerProfile from '../PractitionerProfile/PractitionerProfile'
import PatientInteractionTimeline from '../PatientAdmin/PatientInteractionTimeline'
import TreatmentLibrary from '../TreatmentLibrary/TreatmentLibrary'
import PatientDocumentSearch from '../PatientAdmin/PatientDocumentSearch'
import PatientMedicalHistory from '../PatientAdmin/PatientMedicalHistory'
import PatientNotes from '../PatientAdmin/PatientNotes'
import AnalyticsFilterPanel from '../Analytics/AnalyticsFilterPanel'
import InventorySummary from '../Inventory/Inventory'
import Report from '../Reports/Report'
import ReportAppointments from '../Reports/ReportAppointments'
import FinanceReportContainer from '../Reports/FinanceReportContainer'
import ReportRecall from '../Reports/ReportRecall'
import SettingsContainer from '../../containers/Settings'
import SupportContainer from '../../containers/Support'
import { TaskContext } from '../../contexts'

import types from '../../actions/types'

import AlertModal from '../../components/Modal/Alert'
import moment from 'moment'
import CalendarOverviewContainer from '../CalendarOverview/CalendarOverviewContainer'
import CalendarPreOrdersContainer from '../CalendarPreOrders/CalendarPreOrdersContainer'
import FeatureRoute from '../../feature-route'

class ClinicsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAlert: false,
      errorMessage: '',
    }
    this.closeErrorAlert = this.closeErrorAlert.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { error, actions, history } = this.props
    if (prevProps.error !== error) {
      if (error) {
        if (error.type === types.ACCOUNT_SETUP.FAIL) {
          localStorage.clear()
          actions.A_ClearToken()
          history.push('/')
        } else if (error.error && error.error.data) {
          let errorMessage = 'No error message provided'

          const data = error.error.data

          if (data.non_field_errors) {
            errorMessage = data.non_field_errors[0]
          } else if (data.errors) {
            if (data.errors.non_field_errors) {
              errorMessage = data.errors.non_field_errors[0]
            }
          } else if (data.detail) {
            errorMessage = data.detail
          }

          this.setState(() => ({
            showAlert: true,
            errorMessage: errorMessage,
          }))
        } else {
          this.setState(() => ({
            showAlert: true,
            errorMessage:
              'An unknown error has occurred. Please check your connection and try again',
          }))
        }
      }
    }
  }

  closeErrorAlert() {
    this.setState(() => ({
      showAlert: false,
      errorMessage: '',
    }))
  }

  render() {
    const {
      global: {
        currentClinic: { timezone },
      },
      calendar: { selectedDate },
      actions,
    } = this.props
    return (
      <div className="main_page">
        <ClinicsHeaderPage />
        <Switch>
          <Route
            path="/clinics/calendar/appointments"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => {
                  let date = selectedDate

                  if (date === undefined) {
                    date = moment.tz(new Date(), timezone)
                    actions.A_CalendarHeaderActions(date)
                  }

                  return (
                    <WeeklyCalendarContainer
                      activeTasks={activeTasks}
                      hasCompletedAllTasks={hasCompletedAllTasks}
                      formatTasks={formatTasks}
                      timezone={timezone}
                      selectedDate={date}
                    />
                  )
                }}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/calendar/planner"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => {
                  let date = selectedDate

                  if (date === undefined) {
                    date = moment.tz(new Date(), timezone)
                    actions.A_CalendarHeaderActions(date)
                  }

                  return (
                    <MonthlySchedule
                      activeTasks={activeTasks}
                      hasCompletedAllTasks={hasCompletedAllTasks}
                      formatTasks={formatTasks}
                    />
                  )
                }}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/calendar/overview"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => {
                  return (
                    <CalendarOverviewContainer
                      activeTasks={activeTasks}
                      hasCompletedAllTasks={hasCompletedAllTasks}
                      formatTasks={formatTasks}
                    />
                  )
                }}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/calendar/pre-ordering"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => {
                  return (
                    <CalendarPreOrdersContainer
                      activeTasks={activeTasks}
                      hasCompletedAllTasks={hasCompletedAllTasks}
                      formatTasks={formatTasks}
                    />
                  )
                }}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/profile/:clinicianId/edit"
            component={EditPractitionerProfile}
          />
          <Route
            path="/clinics/profile/:clinicianId"
            render={() => {
              let date = selectedDate

              if (date === undefined) {
                date = moment.tz(new Date(), timezone)
                actions.A_CalendarHeaderActions(date)
              }

              return <PractitionerProfile />
            }}
          />
          <Route
            path="/clinics/patients/:patientId/treatments"
            component={PatientInteractionTimeline}
          />
          <FeatureRoute
            path="/clinics/patients/:patientId/general-info"
            enabled={true}
            v2url={'patients/:patientId/general'}
          />
          <FeatureRoute
            path="/clinics/patients/:patientId/finances/invoice/:consultationId"
            enabled={true}
            v2url={'patients/:patientId/finances/invoices/:consultationId'}
          />
          <FeatureRoute
            path="/clinics/patients/:patientId/finances"
            enabled={true}
            v2url={'patients/:patientId/finances'}
          />

          <Route
            path="/clinics/patients/:patientId/medical-history"
            component={PatientMedicalHistory}
          />
          <Route
            path="/clinics/patients/:patientId/documents"
            component={PatientDocumentSearch}
          />
          <Route
            path="/clinics/patients/:patientId/notes"
            component={PatientNotes}
          />
          <Route
            path="/clinics/treatments"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => (
                  <TreatmentLibrary
                    hasCompletedAllTasks={hasCompletedAllTasks}
                    activeTasks={activeTasks}
                    formatTasks={formatTasks}
                  />
                )}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/inventory"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => (
                  <InventorySummary
                    hasCompletedAllTasks={hasCompletedAllTasks}
                    activeTasks={activeTasks}
                    formatTasks={formatTasks}
                  />
                )}
              </TaskContext.Consumer>
            )}
          />
          <FeatureRoute
            path="/clinics/patients"
            enabled={true}
            v2url={'patients'}
          />
          <FeatureRoute
            path="/clinics/staff"
            enabled={true}
            v2url={'staff'}
          />
          <Route path="/clinics/contact-support" component={SystemSupport} />
          <Route path="/clinics/settings" component={SettingsContainer} />
          <Route path="/clinics/support" component={SupportContainer} />

          <Route
            path="/clinics/analytics/summary"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => (
                  <AnalyticsFilterPanel
                    hasCompletedAllTasks={hasCompletedAllTasks}
                    activeTasks={activeTasks}
                    formatTasks={formatTasks}
                  />
                )}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/analytics/reports"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => (
                  <Report
                    hasCompletedAllTasks={hasCompletedAllTasks}
                    activeTasks={activeTasks}
                    formatTasks={formatTasks}
                  />
                )}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/analytics/appointments"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => (
                  <ReportAppointments
                    hasCompletedAllTasks={hasCompletedAllTasks}
                    activeTasks={activeTasks}
                    formatTasks={formatTasks}
                  />
                )}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/analytics/finance"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => (
                  <FinanceReportContainer
                    hasCompletedAllTasks={hasCompletedAllTasks}
                    activeTasks={activeTasks}
                    formatTasks={formatTasks}
                  />
                )}
              </TaskContext.Consumer>
            )}
          />
          <Route
            path="/clinics/analytics/recall"
            render={() => (
              <TaskContext.Consumer>
                {({ activeTasks, hasCompletedAllTasks, formatTasks }) => (
                  <ReportRecall
                    hasCompletedAllTasks={hasCompletedAllTasks}
                    activeTasks={activeTasks}
                    formatTasks={formatTasks}
                  />
                )}
              </TaskContext.Consumer>
            )}
          />

          <FeatureRoute
            path="/clinics/create"
            enabled={true}
            v2url={'clinic'}
          />
          <FeatureRoute
            path="/clinics/:clinicId"
            enabled={true}
            v2url={'clinic/:clinicId'}
          />
          <FeatureRoute
            path="/clinics/:clinicId"
            enabled={true}
            v2url={'clinic/:clinicId'}
          />
          <FeatureRoute
            path="/clinics"
            enabled={true}
            v2url={'clinic'}
          />
        </Switch>
        {this.state.showAlert ? (
          <AlertModal
            title="Error"
            message={this.state.errorMessage}
            closeAlert={this.closeErrorAlert}
            rightButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Cancel',
                size: 'small',
                events: {
                  onClick: this.closeErrorAlert,
                },
              },
            ]}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

ClinicsPage.defaultProps = {
  error: null,
}

ClinicsPage.propTypes = {
  global: PropTypes.object.isRequired,
  error: PropTypes.object,
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  calendar: PropTypes.object.isRequired,
}

//export default ClinicsPage;
const mapStateToProps = state => {
  return {
    global: state.global,
    error: state.errorHandler,
    calendar: state.calendar,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_ClearToken,
        A_CalendarHeaderActions,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClinicsPage),
)
