import PropTypes from 'prop-types'
import styled from 'styled-components'

const CurrencyIcon = styled.span`
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  &:before {
    content: '${props => props.currencyIcon}';
  }
`

export default function CurrencyInputIcon({ currencyIcon }) {
  return (
    <CurrencyIcon
      className="form__fieldIcon form__fieldIcon--no-label"
      currencyIcon={currencyIcon}
    />
  )
}

CurrencyInputIcon.propTypes = {
  currencyIcon: PropTypes.string.isRequired,
}
