import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_ToggleDatePicker,
  A_CalendarHeaderActions,
} from '../../actions'
import DatePickerHHL from '../../components/DatePicker/DatePickerHHL'
import dateFns from 'date-fns'
import * as fun from '../../utilities/ReusableFunctions'
import { openAppointmentModal } from '../../features/AppointmentModal/actions'

class ProfileHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleLeftButtonClick = this.handleLeftButtonClick.bind(this)
    this.handleRightButtonClick = this.handleRightButtonClick.bind(this)
    this.showAppointmentModal = this.showAppointmentModal.bind(this)
    this.displayTodayCalendar = this.displayTodayCalendar.bind(this)
  }

  componentDidMount() {}

  showAppointmentModal = (clinicianId) => {
    const { actions, selectedDate, global } = this.props

    const remainder = selectedDate.minute() % 5
    const startDate = selectedDate.subtract(remainder, "minutes").format('YYYY-MM-DDTHH:mm')

    const data = {
      clinicId: global.currentClinicID,
      initialValues: {
        clinicianId,
        startDate
      },
    }

    //handles need to call this.getBookedAppointments() from profilecalendar

    actions.openAppointmentModal(data)
  }

  handleLeftButtonClick() {
    const { actions, selectedDate } = this.props
    let date = selectedDate
    let dateMinus = dateFns.subDays(date, 1)
    let selectedDateWeekDays = fun.getDateandWeek(dateMinus)
    actions.A_CalendarHeaderActions(selectedDateWeekDays, dateMinus)
  }

  handleRightButtonClick() {
    const { actions, selectedDate } = this.props
    let date = selectedDate
    let dateAdd = dateFns.addDays(date, 1)
    let selectedDateWeekDays = fun.getDateandWeek(dateAdd)
    actions.A_CalendarHeaderActions(selectedDateWeekDays, dateAdd)
  }

  getFormattedDate = date => {
    let formatDate = dateFns.format(date, 'DD')
    return formatDate
  }

  displayTodayCalendar() {
    const { actions, selectedDate } = this.props
    const currentWeeksDays = fun.getCurrentDateandWeek().array
    const todaysDate = fun.getCurrentDateandWeek().currentDate
    let today = new Date()
    let isSameDay = dateFns.isSameDay(selectedDate, today)

    if (isSameDay) {
      let ele = document.getElementById('currentTimeProfile')
      if (ele) {
        ele.scrollIntoView()
      }
    } else {
      actions.A_CalendarHeaderActions(currentWeeksDays, todaysDate)
    }
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = 0
    }
  }

  render() {
    const {
      actions,
      selectedDate,
      datepickerIsVisible,
      currentMonthYear,
    } = this.props

    const { clinicianId } = this.props.match.params

    return (
      <article className="practitioner_header practitioner_header--single">
        <div className="datePicker_popup_container practitioner_detail practitioner_detail_info practitioner_detail--datePicker">
          <button
            type="button"
            className="datePicker_header_button"
            onClick={actions.A_ToggleDatePicker}
          >
            <span className="datePicker_header--primaryText">
              {(typeof selectedDate === 'string'
                ? selectedDate.substring(0, 2)
                : this.getFormattedDate(selectedDate)) +
                ' ' +
                currentMonthYear.month}{' '}
            </span>
            <span className="datePicker_header--secondaryText">
              {currentMonthYear.year}
            </span>
          </button>

          {datepickerIsVisible ? (
            <DatePickerHHL calendarType="practitionerCalendar" position="top" />
          ) : null}
        </div>
        <article className="practitioner_detail practitioner_detail_today">
          <button
            type="button"
            className="buttonTertiary button--small appointment__today"
            onClick={this.displayTodayCalendar}
          >
            Today
          </button>
        </article>
        <article className="practitioner_detail practitioner_detail_add practitioner_detail_spaced">
          <button
            type="button"
            className="buttonCircle--35 appointment__addButton icon-add appointment__addButton--practitioner"
            onClick={() =>
              this.showAppointmentModal(
                clinicianId
              )
            }
          />
        </article>
      </article>
    )
  }
}

ProfileHeader.defaultProps = {
  datepickerIsVisible: false,
}

ProfileHeader.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  currentMonthYear: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  datepickerIsVisible: PropTypes.bool,
  global: PropTypes.object.isRequired,
  clinician: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    currentMonthYear: state.calendar.currentMonthYear,
    datepickerIsVisible: state.calendar.toggleDatePicker,
    selectedDate: state.calendar.selectedDate,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_ToggleDatePicker,
        A_CalendarHeaderActions,
        openAppointmentModal,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileHeader),
)
