import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { jobTypeNames } from "../../utilities/ReusableObjects";
import {
  getFullName,
  getInitials,
  setOddEvenClass,
} from "../../utilities/ReusableFunctions";
import Avatar from "../../components/Avatar";
import { openAppointmentModal } from "../../features/AppointmentModal/actions";

class PractitionerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.setCurrentPractitioner = this.setCurrentPractitioner.bind(this);
    this.showAppointmentModal = this.showAppointmentModal.bind(this);
    this.onPractitionerKeyPress = this.onPractitionerKeyPress.bind(this);
  }

  /**
   * Open up the create appointment modal
   */
  showAppointmentModal(clinicianId) {
    const { actions, calendar, global } = this.props;

    const remainder = calendar.selectedDate.minute() % 5
    const startDate = calendar.selectedDate.subtract(remainder, "minutes").format('YYYY-MM-DDTHH:mm')

    const data = {
      clinicId: global.currentClinicID,
      initialValues: {
        clinicianId,
        startDate,
      }
    };

    //handles need to call this.getBookedAppointments() from weekly calendar

    actions.openAppointmentModal(data);
  }

  /**
   * Pass the practitioner and clinic values and navigate to the practitioner profile page
   */
  setCurrentPractitioner() {
    const { value, history } = this.props;
    history.push(`/clinics/profile/${value.id}`);
  }

  /**
   * Handle the practitioner key press event
   */
  onPractitionerKeyPress(event) {
    event.preventDefault();
    if (event.key === "Enter") {
      this.setCurrentPractitioner();
    }
  }

  render() {
    const { oddEvenKey, value } = this.props;
    let oddEvenClass = setOddEvenClass(oddEvenKey);
    return (
      <React.Fragment>
        <div className={`practitioner gridCol gridCol--${oddEvenClass}`}>
          <div className="practitioner_details">
            <div className="practitioner_detail practitioner_detail_avatar">
              <Avatar
                src={value.signed_avatar}
                type="practitionerProfileHeaderCalendar"
                initials={getInitials(value)}
              />
            </div>
            <div
              role="button"
              className="practitioner_detail practitioner_detail_info"
              onClick={this.setCurrentPractitioner}
              onKeyPress={this.onPractitionerKeyPress}
              tabIndex="0"
            >
              <h2 className="practitioner_name singleLine_truncatedText">
                {getFullName(value)}
              </h2>
              <div className="practitioner_role singleLine_truncatedText">
                {jobTypeNames[value.job_type]}
              </div>
            </div>
            <div className="practitioner_detail practitioner_detail_add">
              <button
                type="button"
                className="buttonCircle--35 appointment__addButton icon-add"
                onClick={() => this.showAppointmentModal(value.id)}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PractitionerHeader.defaultProps = {
  oddEvenKey: 0,
};

PractitionerHeader.propTypes = {
  actions: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  oddEvenKey: PropTypes.number,
  global: PropTypes.object.isRequired,
  calendar: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    global: state.global,
    calendar: state.calendar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        openAppointmentModal,
      },
      dispatch
    ),
  };
};

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PractitionerHeader)
);
