import React from 'react'
import PropTypes from 'prop-types'

const NotificationCard = ({ notification, handleClick }) => {
  return (
    <React.Fragment>
      {notification.unread ? (
        <button
          className="notification__content"
          onClick={() => handleClick(notification)}
        >
          <div className="notification__card">
            <h6 className="h4">{notification.title}</h6>
            <p className="h4">{notification.detail}</p>
            <span className="read__indicator" />
          </div>
        </button>
      ) : (
        <button
          className="notification__content--read"
          onClick={() => handleClick(notification)}
        >
          <div className="notification__card">
            <h6 className="h4">{notification.title}</h6>
            <p className="h4">{notification.detail}</p>
          </div>
        </button>
      )}
    </React.Fragment>
  )
}

NotificationCard.defaultProps = {
  notification: { title: '', detail: '' },
  title: '',
  details: '',
  read: false,
  handleClick: () => {},
}

NotificationCard.propTypes = {
  notification: PropTypes.object,
  title: PropTypes.string,
  details: PropTypes.string,
  read: PropTypes.bool,
  handleClick: PropTypes.func,
}

export default NotificationCard
