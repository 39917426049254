import React from 'react'
import { withRouter } from 'react-router-dom'
import { TutorialContext } from '../../contexts'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import FeedbackStrap from '../../components/Feedback/FeedbackStrap/'
import SearchHeader from '../../components/Search/Header'
import InventoryOverviewItem from '../../components/Inventory/InventoryOverviewItem'
import Bottombar from '../../components/Bottombar'
import InventoryModals from '../../components/Inventory/InventoryModals'
import TutorialBtn from '../Tutorials/TutorialBtn'
import {
  A_GetInventoryList,
  A_PostNewInventory,
  A_PatchInventoryDetails,
  A_DeleteInventory,
  A_GetClinicsList,
  A_GetInventoryOptionList,
  A_PostNewInventoryOption,
  A_PatchEditInventoryOptionDetails,
  A_DeleteInventoryOption,
  A_SetViewingClinic,
} from '../../actions'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'
import Placeholder from '../../components/Tutorials/Placeholder'
import { getTasks } from '../../services/OnBoarding/auth'

class Inventory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inventory: [],
      showModal: false,
      modals: {},
      productNameInput: '',
      productPriceInput: 0,
      searchInput: '',
      treatmentTypeId: null,
      errors: { titleError: false, priceError: false },
    }
  }

  async componentDidMount() {
    const { actions, formatTasks } = this.props
    const tasks = await getTasks()
    formatTasks(tasks)

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const clinic = global.currentClinicID

      if (clinic) {
        this.displayClinicList()
      }
    })
  }

  // ------ each modal function resets the other and the input values/errors

  showCreateProduct = e => {
    const modals = {
      showCreateOption: false,
      showEdit: false,
      showEditOption: false,
      showCreate: true,
    }
    if (e) {
      this.setState({
        productNameInput: '',
        modals: modals,
        errors: { titleError: false, priceError: false },
      })
      this.toggleModal(e)
    }
  }

  showEditProduct = (e, product) => {
    const modals = {
      showCreateOption: false,
      showEditOption: false,
      showCreate: false,
      showEdit: true,
    }
    if (e) {
      this.setState({
        modals: modals,
        productNameInput: product.title,
        currentProduct: product,
        errors: { titleError: false, priceError: false },
      })
      this.toggleModal(e)
    }
  }

  showCreateOption = (e, product) => {
    const modals = {
      showEditOption: false,
      showCreate: false,
      showEdit: false,
      showCreateOption: true,
    }
    if (e) {
      this.setState({
        modals: modals,
        currentProduct: product,
        productNameInput: '',
        productPriceInput: 0,
        errors: { titleError: false, priceError: false },
      })
      this.toggleModal(e)
    }
  }

  showEditOption = (e, product, option) => {
    const modals = {
      showCreateOption: false,
      showCreate: false,
      showEdit: false,
      showEditOption: true,
    }
    if ((product, option)) {
      let price = option.levels.length !== 0 ? option.levels[0].price : 0
      this.setState({
        modals: modals,
        currentProduct: product,
        currentOption: option,
        productNameInput: option.title,
        productPriceInput: price.toFixed(2),
        errors: { titleError: false, priceError: false },
      })
      this.toggleModal(true)
    }
  }

  toggleModal = e => {
    if (e) {
      this.setState({
        showModal: !this.state.showModal,
      })
    }
  }

  toggleBanner = e => {
    if (e) {
      this.setState(
        () => ({
          bannerShow: false,
        }),
        () => {
          localStorage.setItem('inventoryBannerShow', false)
        },
      )
    }
  }

  changeTitle = e => {
    if (e) {
      let inputValue = e.target.value
      this.setState({
        productNameInput: inputValue,
      })
    }
  }

  changeOption = e => {
    if (e) {
      let input = e.target
      if (input.id === 'productName') {
        this.setState(() => ({
          productNameInput: input.value,
        }))
      } else if (input.id === 'productPrice') {
        this.setState(() => ({
          productPriceInput: input.value,
        }))
      }
    }
  }

  changeClinic = e => {
    const { actions } = this.props
    const clinic = Number(e.target.value)
    actions.A_SetViewingClinic(clinic)
    this.displayClinicList(clinic)
  }

  searchFilter(e) {
    let searchResults = []
    if (e.target.value !== '' || e.target.value.length > 0) {
      let enteredValue = e.target.value
      enteredValue = enteredValue.toLowerCase()
      searchResults = this.applySearchFilter(enteredValue)
      this.setState(() => ({
        inventory: searchResults,
      }))
    } else {
      this.displayClinicList()
    }
  }

  applySearchFilter(enteredValue) {
    const { inventoryList } = this.props
    if (enteredValue) {
      let results = inventoryList.inventoryList.filter(product => {
        return product.title.toLowerCase().indexOf(enteredValue) > -1
      })
      return results
    }
  }

  displayClinicList = clinic => {
    const { actions, global } = this.props

    if (!clinic) {
      clinic = global.currentClinicID
    }

    actions.A_GetInventoryList(clinic).then(response => {
      const inventoryId =
        response.length > 0 && response[0].treatment_type
          ? response[0].treatment_type.id
          : null
      this.setState({
        inventory: response,
        showModal: false,
        productNameInput: '',
        productPriceInput: '',
        treatmentTypeId: inventoryId,
        errors: { titleError: false, priceError: false },
      })
    })
  }

  submitInventory = () => {
    const { actions, global } = this.props
    const title = this.state.productNameInput
    const clinicID = global.currentClinicID
    const product = this.state.currentProduct
    const option = this.state.currentOption
    /////// check if price is a invalid number
    const price = isNaN(this.state.productPriceInput)
      ? 0.0
      : Number(parseFloat(this.state.productPriceInput).toFixed(2))
    let error = { titleError: false, priceError: false }
    if (this.state.modals.showCreate || this.state.modals.showEdit) {
      if (title.length > 0) {
        if (this.state.modals.showCreate) {
          actions
            .A_PostNewInventory(clinicID, {
              title: title,
              treatment_type: { id: this.state.treatmentTypeId },
            })
            .then(() => {
              this.displayClinicList()
            })
        } else if (this.state.modals.showEdit) {
          actions
            .A_PatchInventoryDetails(clinicID, product.id, {
              title: title,
            })
            .then(() => {
              this.displayClinicList()
            })
        }
      } else {
        error = { titleError: true }
      }
    } else if (
      this.state.modals.showCreateOption ||
      this.state.modals.showEditOption
    ) {
      if (title.length > 0 && price >= 0.0) {
        let levels = [{ price: price }]
        if (this.state.modals.showCreateOption) {
          actions
            .A_PostNewInventoryOption(clinicID, product.id, {
              title: title,
              levels: levels,
            })
            .then(() => {
              this.displayClinicList()
            })
        } else if (this.state.modals.showEditOption) {
          actions
            .A_PatchEditInventoryOptionDetails(
              clinicID,
              product.id,
              option.id,
              {
                title: title,
                levels: levels,
              },
            )
            .then(() => {
              this.displayClinicList()
            })
        }
      } else if (title.length === 0) {
        error = { titleError: true }
      } else {
        error = { titleError: true, priceError: true }
      }
    }
    this.setState({ errors: error })
  }

  confirmedDelete = e => {
    const { actions, global } = this.props
    let clinicID = global.currentClinicID
    let product = this.state.currentProduct
    let option = this.state.currentOption
    if (e) {
      if (this.state.modals.showEdit) {
        actions.A_DeleteInventory(clinicID, product.id).then(() => {
          this.displayClinicList()
          this.toggleModal(true)
        })
      } else if (this.state.modals.showEditOption) {
        actions
          .A_DeleteInventoryOption(clinicID, product.id, option.id)
          .then(() => {
            this.displayClinicList()
            this.toggleModal(true)
          })
      }
    }
  }

  showBanner = () => {
    let banner = JSON.parse(localStorage.getItem('inventoryBannerShow'))
    let display = ''
    if (banner !== false) {
      display = (
        <FeedbackStrap
          hasMargin={false}
          message="Products without pricing options will not appear in invoices"
          linkUrl="#"
          buttonText="Don’t show this again"
          onClick={e => this.toggleBanner(e)}
        />
      )
    }
    return display
  }

  render() {
    const { global, activeTasks, hasCompletedAllTasks } = this.props

    const clinics = global.filterClinics
    const clinic = global.currentClinicID

    return (
      <React.Fragment>
        <main className="main">
          <>
            {!hasCompletedAllTasks ? (
              <Placeholder activeTasks={activeTasks} />
            ) : null}
          </>
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                  bottom={100}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
          <SearchHeader
            rightFilterId="RightFilter"
            rightFilterLabel="Clinic"
            rightFilter={clinics}
            rightFilterChange={e => this.changeClinic(e)}
            rightFilterSelected={clinic}
            onChange={e => this.searchFilter(e)}
          />
          {this.showBanner()}
          <section className="main__inner">
            <article className="col__12-8 fadeInFlyAnimate clinicForm--feedbackStrap">
              {clinics.length === 0 || this.state.inventory.length === 0 ? (
                <div className="noResults_message--page noResults_message--centered">
                  <p className="p noResults_message--page secondaryMarginBottom">
                    No products available
                  </p>
                </div>
              ) : this.state.inventory.length > 0 ? (
                this.state.inventory.map(product => {
                  return (
                    <InventoryOverviewItem
                      key={product.id}
                      headerText={product.title}
                      product={product}
                      showCreateOption={e => this.showCreateOption(e, product)}
                      showEditOption={this.showEditOption}
                      showEdit={e => this.showEditProduct(e, product)}
                      locale={global.currentClinic.locale}
                    />
                  )
                })
              ) : (
                <div className="noResults_message--page noResults_message--centered">
                  <p className="p noResults_message--page secondaryMarginBottom">
                    No products matched your search
                  </p>
                </div>
              )}
            </article>
            <Bottombar
              bar="inventory"
              clickHandler={e => this.showCreateProduct(e)}
              isDisabled={
                clinics.length === 0 ||
                !this.state.treatmentTypeId ||
                this.state.treatmentTypeId === null
                  ? true
                  : false
              }
            />
          </section>
        </main>
        <InventoryModals
          showModel={this.state.showModal}
          modals={this.state.modals}
          errors={this.state.errors}
          productNameInput={this.state.productNameInput}
          productPriceInput={this.state.productPriceInput}
          toggleModal={e => this.toggleModal(e)}
          changeOption={e => this.changeOption(e)}
          submitInventory={() => this.submitInventory()}
          showConfirm={e => this.confirmedDelete(e)}
          currencySymbol={
            global.currentClinic ? global.currentClinic.currencySymbol : ''
          }
        />
      </React.Fragment>
    )
  }
}

Inventory.propTypes = {
  actions: PropTypes.object.isRequired,
  inventoryList: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  formatTasks: PropTypes.func.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
  return {
    inventoryList: state.inventoryList,
    inventoryOptionList: state.inventoryOptionList,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetInventoryList,
        A_PostNewInventory,
        A_PatchInventoryDetails,
        A_DeleteInventory,
        A_GetClinicsList,
        A_GetInventoryOptionList,
        A_PostNewInventoryOption,
        A_PatchEditInventoryOptionDetails,
        A_DeleteInventoryOption,
        A_SetViewingClinic,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Inventory),
)
