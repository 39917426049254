export const financeReportHeaders = [
    {
      title: 'Patient Name',
      priority: 'primary',
      type: 'data',
      value: 'patient',
      width: 180,
    },
    {
      title: 'Appointment Purpose',
      priority: 'secondary',
      width: 180,
      type: 'data',
      value: 'purpose',
    },
    {
      title: 'Treatment',
      type: 'data',
      priority: 'secondary',
      value: 'category',
      width: 200,
    },
    {
      title: 'Treatment Options',
      type: 'data',
      priority: 'secondary',
      value: 'treatment',
      width: 200,
    },
    {
      title: 'Inventory Items',
      type: 'data',
      priority: 'secondary',
      value: 'inventory',
      width: 200,
    },
    {
      title: 'Clinic',
      type: 'data',
      priority: 'primary',
      value: 'clinic',
      width: 140,
    },
    {
      title: 'Clinician',
      type: 'data',
      priority: 'primary',
      value: 'clinician',
      width: 140,
    },
    {
      title: 'Taken By',
      type: 'data',
      priority: 'primary',
      value: 'taken_by',
      width: 140,
    },
    {
      title: 'Associated To',
      type: 'data',
      priority: 'primary',
      value: 'associated_to',
      width: 140,
    },
    {
      title: 'Payment taken',
      type: 'data',
      priority: 'secondary',
      value: 'taken',
      width: 140,
    },
    {
      title: 'Invoice Status',
      type: 'data',
      priority: 'secondary',
      value: 'status',
      width: 140,
    },
    {
      title: 'Bill ID',
      priority: 'secondary',
      type: 'data',
      value: 'id',
      width: 80,
    },
    {
      title: 'Total Treatments',
      type: 'data',
      priority: 'secondary',
      value: 'totalTreatments',
      width: 180,
    },
    {
      title: 'Total Inventory',
      type: 'data',
      priority: 'secondary',
      value: 'totalInventory',
      width: 180,
    },
    {
      title: 'Total VAT',
      type: 'data',
      priority: 'secondary',
      value: 'totalVAT',
      width: 140,
    },
    {
      title: 'Invoice Total',
      type: 'data',
      priority: 'secondary',
      value: 'total',
      width: 120,
    },
    {
      title: 'Amount Paid',
      type: 'data',
      priority: 'primary',
      value: 'paid',
      width: 120,
    },
    {
      title: 'Payment Type',
      type: 'data',
      priority: 'secondary',
      value: 'payment',
      width: 120,
    },
    {
      title: 'Card Type',
      type: 'data',
      priority: 'secondary',
      value: 'card',
      width: 120,
    },
    {
      title: 'Total due',
      type: 'data',
      priority: 'primary',
      value: 'remaining',
      width: 120,
    },
    {
      title: 'Notes?',
      type: 'data',
      priority: 'secondary',
      value: 'note',
      width: 340,
    },
  ]
  