import PropTypes from 'prop-types'

import ReactTooltip from 'react-tooltip'

const Setting = ({ setting, onClick, isActive, isDisabled }) => {
  const tooltipDataAttrs = {
    'data-tip':
      'You need an active Healthxchange account to view this support article.',
    'data-place': 'right',
    'data-background-color': '#242467',
  }
  const addTooltipDataAttributes = () => (isDisabled ? tooltipDataAttrs : null)
  return (
    <>
      <li
        {...addTooltipDataAttributes()}
        data-testid="data-testsetting"
        role="menuitem"
        className={`settings--controller-setting ${
          isDisabled
            ? 'settings--controller-setting-disabled'
            : 'settings-controller-setting'
        } ${
          isActive
            ? 'settings--controller-setting-active'
            : 'settings--controller-setting'
        }`}
        onClick={isDisabled ? null : onClick}
        onKeyDown={onClick}
      >
        {setting.title}
      </li>
      {isDisabled && <ReactTooltip />}
    </>
  )
}

Setting.defaultProps = {
  setting: {},
  onClick: () => {},
  isActive: false,
  isDisabled: false,
}

Setting.propTypes = {
  setting: PropTypes.object,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default Setting
