import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import TextInput from '../../forms/Input'
import SelectInput from '../../forms/Select'
import MainButton from '../../Buttons'
import DatePickerInput from '../../forms/datePickerInput'
import {
  A_GetClinicsList,
  A_GetReportTable,
  A_StoreReportData,
  A_FetchCliniciansList,
  A_FetchTreatmentTypeList,
} from '../../../actions'
import DatePickerRangeHHL from '../../DatePicker/DatePickerRange'
import moment from 'moment'
import MultiRangeSlider from '../../MultiRangeSlider'
import * as func from '../../../utilities/ReusableFunctions'
import FeedbackNotification from '../../Feedback/FeedbackNotification'
import * as report from '../../../services/Report/Report'

class FilterHeading extends React.Component {
  constructor(props) {
    super(props)
    this.startDateRef = React.createRef()
    this.endDateRef = React.createRef()
    this.dpContainer = React.createRef()

    this.state = {
      showStartPicker: false,
      showEndPicker: false,
      dateError: false,
      ShowAdvanceFilter: false,
      startDate: func.getPreviousWeekStartEndDays().startDate,
      endDate: func.getPreviousWeekStartEndDays().endDate,
      clinicSelected: '',
      staffSelected: '',
      treatmentValue: '',
      genderSelected: '',
      appointTypeSelected: '',
      appointStatusSelected: '',
      treatmentTypeSelected: '',
      clinicOptions: [{ id: '', title: 'Show all' }],
      staffOptions: [{ id: '', title: 'Show all' }],
      treatmentTypeOptions: [{ id: '', title: 'Show all' }],
      genderOptions: func.getItems('genderFilter'),
      appiontTypeOptions: [
        { id: '', title: 'Show all' },
        { id: 0, title: 'New' },
        { id: 1, title: 'Repeat' },
        { id: 2, title: 'Review' },
      ],
      appointStatusOptions: [
        { id: -3, title: 'Show all' },
        { id: -2, title: 'Cancelled' },
        { id: -1, title: 'No show' },
        { id: 0, title: 'Pending' },
        { id: 1, title: 'Patient arrived' },
        { id: 2, title: 'Ready for patient' },
        { id: 3, title: 'Active' },
        { id: 4, title: 'Complete' },
      ],
      patientValue: '',
      concernValue: '',
      anxMIN: 0,
      anxMAX: 5,
      depMIN: 0,
      depMAX: 5,
      bddMIN: 0,
      bddMAX: 3,
      billLineItemSubMIN: 0,
      billLineItemSubMAX: 1000000,
      billLineItemSubMAXRange: 1000000,
      billLineItemDiscMIN: 0,
      billLineItemDiscMAX: 1000000,
      billLineItemDiscMAXRange: 1000000,
      billLineItemVATMIN: 0,
      billLineItemVATMAX: 1000000,
      billLineItemVATMAXRange: 1000000,
      billLineItemTotMIN: 0,
      billLineItemTotMAX: 1000000,
      billLineItemTotMAXRange: 1000000,
      billLineItemSubSliderDisabled: false,
      billLineItemDiscSliderDisabled: false,
      billLineItemVatSliderDisabled: false,
      billLineSliderDisabled: false,
      localeSelected: props.global.currentClinic.locale,
      localeOptions: [],
    }
  }

  componentDidMount() {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const data = func.clinicsForLocale(
        global.clinics,
        global.currentClinicID,
        global.currentClinic.locale,
      )

      this.setState({
        clinicOptions: data.clinics,
        localeOptions: data.locales,
        clinicSelected: '',
        localeSelected: data.locale,
      })

      this.getStaffOptions()

      if (data.clinic !== '') {
        this.getTreatmentTypes(data.clinic)
      }

      this.applyFilter()

      this.updateSliderValues(data.locale)
    })
  }

  updateSliderValues = locale => {
    report.getReportBuilderSliderValues(locale).then(response => {
      const subMax = Math.ceil(response.bill_line_item_subtotal_max + 1)
      const discMax = Math.ceil(response.bill_line_item_discount_max + 1)
      const vatMax = Math.ceil(response.bill_line_item_vat_total_max + 1)
      const totMax = Math.ceil(response.bill_line_item_price_max + 1)
      this.setState(
        {
          billLineItemSubMAX: subMax,
          billLineItemSubMAXRange: subMax,

          billLineItemDiscMAX: discMax,
          billLineItemDiscMAXRange: discMax,

          billLineItemVATMAX: vatMax,
          billLineItemVATMAXRange: vatMax,

          billLineItemTotMAX: totMax,
          billLineItemTotMAXRange: totMax,

          billLineItemSubSliderDisabled:
            this.state.billLineItemSubMIN ===
            response.bill_line_item_subtotal_max,
          billLineItemDiscSliderDisabled:
            this.state.billLineItemDiscMIN ===
            response.bill_line_item_discount_max,
          billLineItemVatSliderDisabled:
            this.state.billLineItemVATMIN ===
            response.bill_line_item_vat_total_max,
          billLineSliderDisabled:
            this.state.billLineItemTotMIN === response.bill_line_item_price_max,
        },
        () => {},
      )
    })
  }

  getTreatmentTypes = id => {
    const { actions } = this.props
    let treatmentTypeOptions = this.state.treatmentTypeOptions
    actions.A_FetchTreatmentTypeList(id).then(response => {
      response.map(treatment => {
        return treatmentTypeOptions.push({
          id: treatment.id,
          title: treatment.title,
        })
      })
      this.setState({
        treatmentTypeOptions: treatmentTypeOptions,
      })
    })
  }

  getStaffOptions = () => {
    const { actions } = this.props

    actions.A_FetchCliniciansList().then(() => {
      const {
        global: { allFilterClinicians },
      } = this.props

      this.setState({
        staffOptions: allFilterClinicians,
      })
    })
  }

  toggleAdvanceFilter = e => {
    if (e) {
      this.setState(() => ({
        ShowAdvanceFilter: !this.state.ShowAdvanceFilter,
      }))
    }
  }

  showStartDatePicker = () => {
    this.setState(() => ({
      showStartPicker: true,
    }))
  }

  showEndDatePicker = () => {
    this.setState(() => ({
      showEndPicker: true,
    }))
  }

  hideStartDatePicker = day => {
    this.resetDateValue(true)
    let date = day && day <= this.state.endDate ? day : this.state.startDate
    let datePickerState = !day || day <= this.state.endDate ? false : true
    let showError = !day || day <= this.state.endDate ? false : true
    this.setState(() => ({
      showStartPicker: datePickerState,
      startDate: date,
      dateError: showError,
    }))
  }
  hideEndDatePicker = day => {
    this.resetDateValue(true)
    let date = day && day >= this.state.startDate ? day : this.state.endDate
    let datePickerState = !day || day >= this.state.startDate ? false : true
    let showError = !day || day >= this.state.startDate ? false : true
    this.setState(() => ({
      showEndPicker: datePickerState,
      endDate: date,
      dateError: showError,
    }))
  }

  resetDateValue = e => {
    if (e) {
      this.setState(() => ({
        dateError: false,
      }))
    }
  }

  // -------- Handle change for all selects and inputs inside the filter

  filterBasicChange = e => {
    if (e.target) {
      let selectedValue = e.target.value
      if (e.target.id === 'reportFilterLocale') {
        const { global } = this.props

        const data = func.clinicsForLocale(
          global.clinics,
          this.state.clinicSelected,
          selectedValue,
        )

        this.setState({
          localeSelected: data.locale,
          clinicOptions: data.clinics,
          clinicSelected: data.clinic,
        })

        this.updateSliderValues(data.locale)
      }
      if (e.target.id === 'reportFilterClinic') {
        this.setState({
          clinicSelected: selectedValue,
        })
      } else if (e.target.id === 'reportFilterStaff') {
        this.setState({
          staffSelected: selectedValue,
        })
      } else if (e.target.id === 'reportFilterTreatment') {
        this.setState({
          treatmentValue: selectedValue,
        })
      } else if (e.target.id === 'patientSearch') {
        this.setState({
          patientValue: selectedValue,
        })
      } else if (e.target.id === 'patientGender') {
        this.setState({
          genderSelected: selectedValue,
        })
      } else if (e.target.id === 'appointType') {
        this.setState({
          appointTypeSelected: Number(selectedValue),
        })
      } else if (e.target.id === 'appointStatus') {
        this.setState({
          appointStatusSelected: Number(selectedValue),
        })
      } else if (e.target.id === 'treatmentType') {
        this.setState({
          treatmentTypeSelected: Number(selectedValue),
        })
      } else if (e.target.id === 'concern') {
        this.setState({
          concernValue: selectedValue,
        })
      }
    }
  }

  // ------ on change for sliders, done seperately due to component onSlide function

  onSlideAnx = (render, handle, value) => {
    let min = Number(value[0].toFixed(0))
    let max = Number(value[1].toFixed(0))
    this.setState({
      anxMIN: min,
      anxMAX: max,
    })
  }

  onSlideDep = (render, handle, value) => {
    let min = Number(value[0].toFixed(0))
    let max = Number(value[1].toFixed(0))
    this.setState({
      depMIN: min,
      depMAX: max,
    })
  }

  onSlideBdd = (render, handle, value) => {
    let min = Number(value[0].toFixed(0))
    let max = Number(value[1].toFixed(0))
    this.setState({
      bddMIN: min,
      bddMAX: max,
    })
  }

  onSlideInvSub = (render, handle, value) => {
    let min = Number(value[0].toFixed(0))
    let max = Number(value[1].toFixed(0))
    this.setState({
      billLineItemSubMIN: min,
      billLineItemSubMAX: max,
    })
  }

  onSlideInvDisc = (render, handle, value) => {
    let min = Number(value[0].toFixed(0))
    let max = Number(value[1].toFixed(0))
    this.setState({
      billLineItemDiscMIN: min,
      billLineItemDiscMAX: max,
    })
  }

  onSlideInvVat = (render, handle, value) => {
    let min = Number(value[0].toFixed(0))
    let max = Number(value[1].toFixed(0))
    this.setState({
      billLineItemVATMIN: min,
      billLineItemVATMAX: max,
    })
  }

  onSlideInvTot = (render, handle, value) => {
    let min = Number(value[0].toFixed(0))
    let max = Number(value[1].toFixed(0))
    this.setState({
      billLineItemTotMIN: min,
      billLineItemTotMAX: max,
    })
  }

  clearAllFilters = e => {
    if (e) {
      this.setState({
        startDate: func.getPreviousWeekStartEndDays().startDate,
        endDate: func.getPreviousWeekStartEndDays().endDate,
        clinicSelected: '',
        staffSelected: '',
        treatmentValue: '',
        patientValue: '',
        genderSelected: '',
        appointTypeSelected: '',
        appointStatusSelected: '',
        treatmentTypeSelected: '',
        concernValue: '',
        anxMIN: 0,
        anxMAX: 5,
        depMIN: 0,
        depMAX: 5,
        bddMIN: 0,
        bddMAX: 3,
        billLineItemSubMIN: 0,
        billLineItemSubMAX: this.state.billLineItemSubMAXRange,
        billLineItemDiscMIN: 0,
        billLineItemDiscMAX: this.state.billLineItemDiscMAXRange,
        billLineItemVATMIN: 0,
        billLineItemVATMAX: this.state.billLineItemVATMAXRange,
        billLineItemTotMIN: 0,
        billLineItemTotMAX: this.state.billLineItemTotMAXRange,
      })
    }
  }

  applyFilter = e => {
    const { actions } = this.props
    const params = {
      start_date: moment(this.state.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.state.endDate).format('YYYY-MM-DD'),
      locale: this.state.localeSelected,
      clinic: this.state.clinicSelected,
      clinician: this.state.staffSelected,
      gender: this.state.genderSelected,
      patient: this.state.patientValue,
      treatment: this.state.treatmentValue,
      appt_type: this.state.appointTypeSelected,
      appt_status: this.state.appointStatusSelected,
      treatment_type: this.state.treatmentTypeSelected,
      concern: this.state.concernValue,
      anx_gt: this.state.anxMIN,
      anx_lt: this.state.anxMAX,
      dep_gt: this.state.depMIN,
      dep_lt: this.state.depMAX,
      bdd_gt: this.state.bddMIN,
      bdd_lt: this.state.bddMAX,
      inv_sub_gt: this.state.billLineItemSubMIN,
      inv_sub_lt: this.state.billLineItemSubMAX,
      inv_tot_gt: this.state.billLineItemTotMIN,
      inv_tot_lt: this.state.billLineItemTotMAX,
      inv_vat_gt: this.state.billLineItemVATMIN,
      inv_vat_lt: this.state.billLineItemVATMAX,
      inv_disc_gt: this.state.billLineItemDiscMIN,
      inv_disc_lt: this.state.billLineItemDiscMAX,
    }

    actions.A_GetReportTable(params).then(() => {
      if (this.state.ShowAdvanceFilter) {
        this.toggleAdvanceFilter(e)
      }
      // ------- stores filter data so change column action can call API
      actions.A_StoreReportData(params)
    })
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="reportFilters__wrapper"
          ref={this.dpContainer}
        >
          <article className="reportFilters__wrapper--inner">
            <div className="form__filterSection--dateinput">
              <label className="form__label">
                START DATE
              </label>
              <DatePickerInput
                id="startDate"
                value={moment(this.state.startDate).format('DD.MM.YYYY')}
                refVal={this.startDateRef}
                classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                  this.state.showStartPicker ? 'form__textField--active' : ''
                }`}
                onClick={this.showStartDatePicker}
              />
            </div>
            <div className="form__filterSection--dateinput">
              <label className="form__label">
                END DATE
              </label>
              <DatePickerInput
                id="endDate"
                value={
                  this.state.endDate
                    ? moment(this.state.endDate).format('DD.MM.YYYY')
                    : ''
                }
                refVal={this.endDateRef}
                classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                  this.state.showEndPicker ? 'form__textField--active' : ''
                }`}
                onClick={this.showEndDatePicker}
              />
            </div>
            {this.state.localeOptions.length > 1 ? (
              <div className="form__filterSection--select">
                <SelectInput
                  id="reportFilterLocale"
                  options={this.state.localeOptions}
                  label="Locale"
                  isSmallMargin={true}
                  hasOwnWrapper={false}
                  onChange={e => this.filterBasicChange(e)}
                  defaultSelected={this.state.localeSelected}
                />
              </div>
            ) : (
              ''
            )}
            <div className="form__filterSection--select">
              <SelectInput
                id="reportFilterClinic"
                options={this.state.clinicOptions}
                label="Clinic"
                isSmallMargin={true}
                hasOwnWrapper={false}
                onChange={e => this.filterBasicChange(e)}
                defaultSelected={this.state.clinicSelected}
              />
            </div>
            <div className="form__filterSection--select">
              <SelectInput
                id="reportFilterStaff"
                options={this.state.staffOptions}
                label="STAFF MEMBER"
                isSmallMargin={true}
                hasOwnWrapper={false}
                onChange={e => this.filterBasicChange(e)}
                defaultSelected={this.state.staffSelected}
              />
            </div>
            <div className="form__filterSection--textInput">
              <TextInput
                id="reportFilterTreatment"
                label="TREATMENT"
                noMargin={true}
                placeholder=""
                onChange={e => this.filterBasicChange(e)}
                value={this.state.treatmentValue}
              />
            </div>
            <div className="form__filterSection--button">
              <MainButton
                type="button"
                styleType={
                  this.state.ShowAdvanceFilter ? 'primary' : 'tertiary'
                }
                label={
                  this.state.ShowAdvanceFilter
                    ? 'Clear all filters'
                    : 'Advanced filters'
                }
                size="small"
                onClick={
                  this.state.ShowAdvanceFilter
                    ? e => this.clearAllFilters(e)
                    : e => this.toggleAdvanceFilter(e)
                }
              />
            </div>
          </article>
          {this.state.ShowAdvanceFilter ? (
            <React.Fragment>
              <div className="form__filterSection--advance fadeInFlyAnimate">
                <h4 className="h3 secondaryMarginBottom">Patient</h4>
                <div className="reportFilters__wrapper--inner">
                  <div className="form__filterSection--select">
                    <TextInput
                      id="patientSearch"
                      noMargin={false}
                      placeholder=""
                      label="Patient name"
                      onChange={e => this.filterBasicChange(e)}
                      value={this.state.patientValue}
                    />
                  </div>
                  <div className="form__filterSection--select">
                    <SelectInput
                      id="patientGender"
                      options={this.state.genderOptions}
                      label="Patient Gender"
                      hasOwnWrapper={true}
                      onChange={e => this.filterBasicChange(e)}
                      defaultSelected={this.state.genderSelected}
                    />
                  </div>
                </div>
                <h4 className="h3 secondaryMarginBottom">Appointment</h4>
                <div className="reportFilters__wrapper--inner">
                  <div className="form__filterSection--select">
                    <SelectInput
                      id="appointType"
                      options={this.state.appiontTypeOptions}
                      label="Appointment type"
                      hasOwnWrapper={true}
                      onChange={e => this.filterBasicChange(e)}
                      defaultSelected={this.state.appointTypeSelected}
                    />
                  </div>
                  <div className="form__filterSection--select hidden">
                    <SelectInput
                      id="appointStatus"
                      options={this.state.appointStatusOptions}
                      label="Appointment Status"
                      hasOwnWrapper={true}
                      onChange={e => this.filterBasicChange(e)}
                      defaultSelected={this.state.appointStatusSelected}
                    />
                  </div>
                </div>
                <h4 className="h3 secondaryMarginBottom">Consultation</h4>
                <div className="reportFilters__wrapper--inner reportFilters__wrapper--withSliders">
                  <div className="form__filterSection--select">
                    <SelectInput
                      id="treatmentType"
                      options={this.state.treatmentTypeOptions}
                      label="Treatment type"
                      hasOwnWrapper={true}
                      onChange={e => this.filterBasicChange(e)}
                      defaultSelected={this.state.treatmentTypeSelected}
                    />
                  </div>
                  <div className="form__filterSection--select">
                    <TextInput
                      id="concern"
                      noMargin={false}
                      placeholder=""
                      label="Concern"
                      onChange={e => this.filterBasicChange(e)}
                      value={this.state.concernValue}
                    />
                  </div>
                  <div className="form__filterSection--slider">
                    <MultiRangeSlider
                      id="anxSlider"
                      onSlide={this.onSlideAnx}
                      label="Anxiety Scale"
                      rangeMin={0}
                      rangeMax={5}
                      startMin={this.state.anxMIN}
                      startMax={this.state.anxMAX}
                      step={1}
                    />
                  </div>
                  <div className="form__filterSection--slider">
                    <MultiRangeSlider
                      id="depSlider"
                      onSlide={this.onSlideDep}
                      label="Depression Scale"
                      rangeMin={0}
                      rangeMax={5}
                      startMin={this.state.depMIN}
                      startMax={this.state.depMAX}
                      step={1}
                    />
                  </div>
                  <div className="form__filterSection--slider">
                    <MultiRangeSlider
                      id="depSlider"
                      onSlide={this.onSlideBdd}
                      label="BDD Scale"
                      rangeMin={0}
                      rangeMax={3}
                      startMin={this.state.bddMIN}
                      startMax={this.state.bddMAX}
                      step={1}
                    />
                  </div>
                </div>
                <h4 className="h3 secondaryMarginBottom">Finance</h4>
                <div className="reportFilters__wrapper--inner">
                  <div className="form__filterSection--slider">
                    <MultiRangeSlider
                      id="invSubSlider"
                      onSlide={this.onSlideInvSub}
                      label="Line item sub total"
                      rangeMin={0}
                      rangeMax={this.state.billLineItemSubMAXRange}
                      startMin={this.state.billLineItemSubMIN}
                      startMax={this.state.billLineItemSubMAX}
                      step={1}
                      disabled={this.state.billLineItemSubSliderDisabled}
                    />
                  </div>
                  <div className="form__filterSection--slider">
                    <MultiRangeSlider
                      id="invDiscSlider"
                      onSlide={this.onSlideInvDisc}
                      label="Line item discount"
                      rangeMin={0}
                      rangeMax={this.state.billLineItemDiscMAXRange}
                      startMin={this.state.billLineItemDiscMIN}
                      startMax={this.state.billLineItemDiscMAX}
                      step={1}
                      disabled={this.state.billLineItemDiscSliderDisabled}
                    />
                  </div>
                  <div className="form__filterSection--slider">
                    <MultiRangeSlider
                      id="invVATSlider"
                      onSlide={this.onSlideInvVat}
                      label="Line item VAT"
                      rangeMin={0}
                      rangeMax={this.state.billLineItemVATMAXRange}
                      startMin={this.state.billLineItemVATMIN}
                      startMax={this.state.billLineItemVATMAX}
                      step={1}
                      disabled={this.state.billLineItemVatSliderDisabled}
                    />
                  </div>
                  <div className="form__filterSection--slider">
                    <MultiRangeSlider
                      id="invTotSlider"
                      onSlide={this.onSlideInvTot}
                      label="Line item total"
                      rangeMin={0}
                      rangeMax={this.state.billLineItemTotMAXRange}
                      startMin={this.state.billLineItemTotMIN}
                      startMax={this.state.billLineItemTotMAX}
                      step={1}
                      disabled={this.state.billLineSliderDisabled}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
          <MainButton
            type="button"
            styleType="secondary"
            label="Apply"
            size="small"
            margin="right"
            onClick={e => this.applyFilter(e)}
          />
          {this.state.ShowAdvanceFilter ? (
            <MainButton
              type="button"
              styleType="tertiary"
              label="Cancel"
              size="small"
              onClick={e => this.toggleAdvanceFilter(e)}
            />
          ) : (
            ''
          )}
        </section>
        {this.state.showStartPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.startDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dpInput={this.startDateRef.current}
            position="leftTop"
            container={this.dpContainer.current}
            calendarType="manage_schedule_range"
            onHide={this.hideStartDatePicker}
          />
        ) : this.state.showEndPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.endDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dpInput={this.endDateRef.current}
            position="leftTop"
            container={this.dpContainer.current}
            calendarType="manage_schedule_range"
            onHide={this.hideEndDatePicker}
          />
        ) : (
          ''
        )}
        {this.state.dateError ? (
          <FeedbackNotification
            id="feedbackMessage"
            dataError={true}
            message={'End date cannot be before start date'}
            iserrorAlert={true}
            closeHandler={() => {
              this.resetDateValue(true)
            }}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

FilterHeading.propTypes = {
  actions: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    clinics: state.clinics,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_FetchTreatmentTypeList,
        A_GetReportTable,
        A_StoreReportData,
        A_FetchCliniciansList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterHeading),
)
