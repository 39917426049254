import PropTypes from 'prop-types'

const FeedbackStrap = ({
  message,
  linkText,
  linkUrl,
  hasMargin,
  buttonText,
  isAlert,
  hasOffset,
  ...props
}) => {
  return (
      <section
        className={`feedbackStrap ${hasMargin ? '' : 'noMargin'} ${
          isAlert ? 'feedbackStrap--red' : ''
        } ${hasOffset ? 'feedbackStrap--offset' : ''}`}
      >
        <p
          className={`feedbackStrap__message ${
            buttonText.length > 0 ? 'feedbackStrap__message--hasButton' : ''
          }`}
        >
          <span className="feedbackStrap__message__text">{message}</span>
          {linkText.length > 0 ? (
            <a href={linkUrl} className="bold">
              {linkText}
            </a>
          ) : (
            ''
          )}
          {buttonText.length > 0 ? (
            <button
              type="button"
              onClick={props.onClick}
              className="feedbackStrap__button bold"
            >
              {buttonText}
            </button>
          ) : (
            ''
          )}
        </p>
      </section>
  )
}

FeedbackStrap.defaultProps = {
  message: '',
  linkText: '',
  linkUrl: 'checkbox',
  hasMargin: true,
  buttonText: '',
  hasOffset: false,
  isAlert: false,
  onClick: () => {},
}

FeedbackStrap.propTypes = {
  message: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  hasMargin: PropTypes.bool,
  buttonText: PropTypes.string,
  hasOffset: PropTypes.bool,
  isAlert: PropTypes.bool,
  onClick: PropTypes.func,
}

export default FeedbackStrap
