import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  appointmentPurposeIds,
  appointmentPurposeTitles,
  appointmentReasonIds,
  appointmentReasonTitles,
} from '../../utilities/ReusableObjects'
import {
  getFullName,
  getPatientAlertData,
} from '../../utilities/ReusableFunctions'
import MainButton from '../../components/Buttons'
import SelectInput from '../../components/forms/Select'
import { isAlertAndAllergiesEnabled } from '../../utilities/featureToggle'

class PreOrdersReview extends Component {
  render() {
    const {
      appointments,
      treatmentsForOrdering,
      productsToOrder,
      viewOrderBy,
      setViewOrderBy,
      sendToHealthxchange,
      isOrdering,
    } = this.props

    const validAppointments = appointments.filter(
      ({ treatments }) =>
        treatments.find(({ products }) => products && products.length > 0) !==
        undefined,
    )

    let patients = []

    let patientBreakdown = validAppointments
      .map(app => {
        let products = []
        productsToOrder
          .filter(({ appointment }) => appointment.id === app.id)
          .forEach(({ prescriptions }) => {
            prescriptions
              .filter(({ quantity }) => quantity > 0)
              .forEach(prescription => {
                products.push(prescription)
              })
          })

        if (products.length > 0) {
          const colorClass = Object.keys(appointmentReasonIds)
            .filter(key => appointmentReasonIds[key] === app.appointment_type)
            .map(key => {
              switch (appointmentReasonIds[key]) {
                case appointmentReasonIds.APPOINTMENT:
                  return `weeklyOverviewBar--${Object.keys(
                    appointmentPurposeIds,
                  ).find(key => appointmentPurposeIds[key] === app.purpose)}`
                default:
                  return `weeklyOverviewBar--${key}`
              }
            })

          const appointmentType = Object.keys(appointmentReasonIds)
            .filter(key => appointmentReasonIds[key] === app.appointment_type)
            .map(key => {
              switch (appointmentReasonIds[key]) {
                case appointmentReasonIds.APPOINTMENT:
                  return appointmentPurposeTitles[
                    Object.keys(appointmentPurposeIds).find(
                      key => appointmentPurposeIds[key] === app.purpose,
                    )
                  ]
                default:
                  return appointmentReasonTitles[key]
              }
            })

          let title

          switch (app.appointment_type) {
            case appointmentReasonIds.APPOINTMENT:
              title = getFullName(app.patient)
              break
            default:
              title = app.title
          }

          return (
            <div key={app.id} className="avoid-break">
              <div className="page-break"></div>
              <div
                className={`${colorClass}`}
                style={{
                  borderRadius: '8px',
                  padding: '16px',
                  display: 'flex',
                  alignContent: 'space-between',
                  flexWrap: 'wrap',
                  marginBottom: '16px',
                  position: 'relative',
                }}
              >
                <div style={{ width: 'calc(100% * (3/12))' }}>
                  <div className="practitioner_name singleLine_truncatedText">
                    {title}
                  </div>
                  {app.patient ? (
                    <>
                      <div className="practitioner_role singleLine_truncatedText">
                        {app.patient.date_of_birth
                          ? `${moment
                              .tz(app.patient.date_of_birth, 'UTC')
                              .format('DD.MM.YYYY')} (${app.patient.age})`
                          : 'No date of birth registered'}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  <div className="weeklyOverviewBar--wrapper weeklyOverviewBar--background">
                    <span className={`p ${colorClass}`}>{appointmentType}</span>
                  </div>
                </div>
                <div style={{ width: 'calc(100% * (4/12))' }}>
                  <div className="practitioner_name singleLine_truncatedText">
                    {moment.tz(app.start_date, 'UTC').format('DD MMMM')}
                  </div>
                  <div className="practitioner_role singleLine_truncatedText">
                    {moment.tz(app.start_date, 'UTC').format('HH:mm')}
                    {moment.tz(app.end_date, 'UTC').format(' - HH:mm')}
                  </div>
                  {app.treatments.length > 0 ? (
                    <>
                      <div className="practitioner_role singleLine_truncatedText">
                        {app.treatment_type.title}
                      </div>
                      <div className="practitioner_role singleLine_truncatedText">
                        {app.treatments.map(({ title }) => title).join(', ')}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {isAlertAndAllergiesEnabled &&
                  getPatientAlertData(app.patient, 'allergies').length > 0 ? (
                    <div className="practitioner_name form__fieldIcon--invalid singleLine_truncatedText">
                      Allergy/Alert
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ width: 'calc(100% * (4/12))' }}>
                  {products.map(prescription => {
                    return (
                      <div
                        key={prescription.product.id}
                        className="practitioner_name singleLine_truncatedText"
                      >
                        <span className="bold">{prescription.quantity} x</span>{' '}
                        <span className="p" style={{ marginLeft: '16px' }}>
                          {prescription.title}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }

        return undefined
      })
      .filter(value => value !== undefined)

    let productBreakdown = []

    treatmentsForOrdering.forEach(({ products }) => {
      products.forEach(product => {
        let total = 0
        let productPatients = []
        productsToOrder.forEach(({ prescriptions, record }) => {
          let prescription = prescriptions.find(
            ({ product: { product_id } }) => product_id === product.product_id,
          )

          if (prescription !== undefined && prescription.quantity > 0) {
            total += prescription.quantity

            patients.push(record.id)
            productPatients.push(record.id)
          }
        })

        if (total > 0) {
          productBreakdown.push(
            <div
              key={`product-${product.product_id}`}
              className="overviewAppontmentsProduct__container"
            >
              <div className="overviewAppontmentsProduct__header">
                <h4 className="h4 bold">{product.product_description}</h4>
                <span style={{ width: '100%' }}>
                  For use with {[...new Set(productPatients)].length} patients
                </span>
                <span className="overviewAppontmentsTotal bold">{total}</span>
              </div>
            </div>,
          )
        }
      })
    })

    patients = [...new Set(patients)]

    const filteredAppointments = validAppointments.filter(({ patient }) =>
      patients.find(id => id === patient.id),
    )

    const allergyAlertPatients = filteredAppointments.filter(
      ({ patient }) => getPatientAlertData(patient, 'allergies').length > 0,
    ).length

    return (
      <section className="main__inner">
        <section className="patientImport__wrapper">
          <article className="overviewAppointments__oneThird no-print">
            <article className="form">
              <h2 className="h2 bold">Review Order</h2>
              <span className="heading">Total Products</span>
              <h1 className="h1">{productBreakdown.length}</h1>

              <span className="heading">Patients</span>
              <h1 className="h1">{patients.length}</h1>
              <br />

              <span className="heading">Appointment Types</span>
              {Object.keys(appointmentPurposeIds).map(key => {
                const amount = filteredAppointments.filter(
                  ({ purpose }) => purpose === appointmentPurposeIds[key],
                ).length
                if (amount > 0) {
                  const percentage =
                    (amount / filteredAppointments.length) * 100.0
                  return (
                    <div key={key} className="weeklyOverviewBar--wrapper">
                      <div
                        className={`weeklyOverviewBar weeklyOverviewBar--${key}`}
                        style={{ width: `${percentage}%` }}
                      ></div>
                      <span className={`weeklyOverviewLabel--${key}`}>
                        {amount} {appointmentPurposeTitles[key]}
                      </span>
                    </div>
                  )
                }
                return ''
              })}
              {isAlertAndAllergiesEnabled && allergyAlertPatients > 0 ? (
                <>
                  <span className="heading">Allergies & Alerts</span>
                  <div className="weeklyOverviewBar--wrapper">
                    <div
                      className={`weeklyOverviewBar weeklyOverviewBar--ALERT weeklyOverviewLabel--ALERT`}
                    >
                      <span className="icon-warning" />
                      <span>{allergyAlertPatients} Patients</span>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </article>
          </article>
          <article className="overviewAppointment__twoThirds overviewAppointment__breakdown">
            <article className="form">
              <section
                className="calendarActions_wrapper no-print"
                ref={this.dpContainer}
              >
                <article className="timePeriod__wrapper overviewDate__wrapper">
                  <h2 className="h2 bold">Order Breakdown</h2>
                </article>
                <article className="calendarActions">
                  <SelectInput
                    options={[
                      { id: 'Patient', title: 'Patient' },
                      { id: 'Product', title: 'Product' },
                    ]}
                    defaultSelected={viewOrderBy}
                    hasOwnWrapper={true}
                    label="View Order Per"
                    isLabelInline={true}
                    hasImage={true}
                    isInline={true}
                    isCalendarHeader={true}
                    onChange={({ currentTarget: { value } }) =>
                      setViewOrderBy(value)
                    }
                  />
                </article>
              </section>
              <br />
              {viewOrderBy === 'Product' ? productBreakdown : patientBreakdown}
              <br />
              <hr className="legacy" />
              <div style={{ paddingTop: '16px', textAlign: 'right' }}>
                <MainButton
                  type="button"
                  label="Place order"
                  styleType="secondary"
                  size="small"
                  onClick={sendToHealthxchange}
                  isDisabled={isOrdering}
                />
              </div>
            </article>
          </article>
        </section>
      </section>
    )
  }
}

PreOrdersReview.propTypes = {
  appointments: PropTypes.array.isRequired,
  treatmentsForOrdering: PropTypes.array.isRequired,
  productsToOrder: PropTypes.array.isRequired,
  viewOrderBy: PropTypes.string.isRequired,
  setViewOrderBy: PropTypes.func.isRequired,
  sendToHealthxchange: PropTypes.func.isRequired,
  isOrdering: PropTypes.bool.isRequired,
}

export default PreOrdersReview
