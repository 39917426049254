import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../Buttons'
import Avatar from '../../Avatar'

const OutstandingActionCard = ({
  id,
  title,
  titleHighlight,
  bodyText,
  actionButtons,
  titleAvatar,
  titleAvatarAlt,
}) => {
  const getButtons = buttonSet => {
    return buttonSet.map((button, index) => {
      return (
        <MainButton
          key={`${id}-${index}`}
          type={button.type}
          styleType={button.style}
          label={button.label}
          size={button.size}
          customClasses={
            index < buttonSet.length - 1
              ? 'modal--spacedButton--left modal_footer_button'
              : ''
          }
          {...button.events}
        />
      )
    })
  }

  return (
    <React.Fragment>
      <div className="notification__card">
        <div className="practitioner_details">
          {titleAvatar && titleAvatar.length === 0 ? (
            ''
          ) : (
            <Avatar
              src={titleAvatar}
              initials={titleAvatarAlt}
              type="practitionerProfileHeaderCalendar"
            />
          )}
          <span className="modal__body__text--bold">{titleHighlight}</span>
          {title}
        </div>
        <p className="p">{bodyText}</p>
        <div className="notification__buttons">{getButtons(actionButtons)}</div>
      </div>
    </React.Fragment>
  )
}

OutstandingActionCard.defaultProps = {
  id: '',
  title: '',
  titleHighlight: '',
  bodyText: '',
  actionButtons: [],
  titleAvatar: '',
  titleAvatarAlt: '',
}

OutstandingActionCard.propTypes = {
  title: PropTypes.string,
  titleHighlight: PropTypes.string,
  bodyText: PropTypes.string,
  actionButtons: PropTypes.array,
  titleAvatar: PropTypes.string,
  titleAvatarAlt: PropTypes.string,
  id: PropTypes.string,
}

export default OutstandingActionCard
