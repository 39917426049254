import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ProfileHeader from './ProfileHeader'
import TimeDividerContainer from '../Calendar/TimeDividerContainer'
import TimeSelector from '../Calendar/TimeSelector'
import * as func from '../../utilities/ReusableFunctions'
import TimeDivider from '../Calendar/TimeDivider'
import {
  A_GetPractitionerAppointmentDetails,
  A_GetClinicsList,
  A_SetAppointmentOpeningTime,
  A_IsAppointmentChange,
  A_GetClinicianDetails,
  A_SetTimeSlots,
  A_FetchClinicScheduleList,
} from '../../actions'

import dateFns from 'date-fns'
import moment from 'moment'

class ProfileCalendar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      styledProps: { divisionPos: 0, currentTimePos: 0 },
      bookedAppointments: null,
      outOfHoursTopHeight: 0,
      outOfHoursBottomHeight: 0,
    }
    this.getNewFormattedDate = this.getNewFormattedDate.bind(this)
    this.getWeekDayNumber = this.getWeekDayNumber.bind(this)
    this.getBookedAppointments = this.getBookedAppointments.bind(this)
    this.setTimeSlots = this.setTimeSlots.bind(this)
    this.setOutOfHoursArea = this.setOutOfHoursArea.bind(this)
    this.appointmentScrollArea = React.createRef()
  }

  refresh() {
    const { actions } = this.props
    const { clinicianId } = this.props.match.params

    actions.A_GetClinicianDetails(clinicianId).then(response => {
      const { default_appointment_length, global, history } = this.props

      if (response.user_type !== 1 || response.current_clinic === null) {
        if (Number(clinicianId) === global.currentUserID) {
          history.push(`/clinics/profile/${clinicianId}/edit`)
        } else {
          history.push('/clinics/calendar/appointments')
        }
        return
      }

      this.setTimeSlots()
      this.IntervalID = setInterval(
        function() {
          this.setState({
            styledProps: func.getPractitionerCurrentTimePositionValue(
              default_appointment_length,
            ),
          })
        }.bind(this),
        2000,
      )
    })

    this.load()
  }

  load() {
    const {
      actions,
      global: { currentClinic },
    } = this.props

    if (currentClinic) {
      let dayNumber = this.getWeekDayNumber()

      let opening_hours = currentClinic.opening_hours

      if (opening_hours && opening_hours.length > 0) {
        let current_day = opening_hours.find(function(hours) {
          return hours.day === dayNumber && hours.is_open
        })

        this.setState({ openingHoursArray: opening_hours }, () => {
          actions.A_SetAppointmentOpeningTime(current_day)
          this.setInitialScrollPos()
          this.setOutOfHoursArea()
        })

        this.reload()
      }
    }
  }

  componentDidMount = () => {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      this.refresh()
    })
  }

  setInitialScrollPos() {
    const { default_appointment_length } = this.props
    this.appointmentScrollArea.current.scrollTop =
      func.getPractitionerCurrentTimePositionValue(default_appointment_length)
        .currentTimePos - 10 // Initially set scroll position of appointment area 10px before current time
  }

  getNewFormattedDate(date) {
    return date.format('YYYY-MM-DD')
  }

  setOutOfHoursArea() {
    const { default_appointment_length } = this.props
    const { openingHoursArray } = this.state

    let openingTime = '0.00'
    let closingTime = '0.00'

    if (openingHoursArray && openingHoursArray.length > 0) {
      let opening_hours = openingHoursArray

      let dayNumber = this.getWeekDayNumber()

      let current_day = opening_hours.find(function(hours) {
        return hours.day === dayNumber && hours.is_open
      })

      if (current_day) {
        openingTime = current_day.opens
          .substr(0, 5)
          .split(':')
          .join('.')
        closingTime = current_day.closes
          .substr(0, 5)
          .split(':')
          .join('.')
      }
    }

    let opening = func.calculateOutOfHoursHeight(
      'open',
      openingTime,
      default_appointment_length,
      140,
    )
    let closing = func.calculateOutOfHoursHeight(
      'close',
      closingTime,
      default_appointment_length,
      140,
    )
    ///// Calculate out of hours state
    this.setState({
      outOfHoursTopHeight: opening,
      outOfHoursBottomHeight: closing,
    })
  }

  componentDidUpdate(prevProps) {
    const { calendar } = this.props

    const { clinicianId } = this.props.match.params

    if (prevProps.match.params.clinicianId !== clinicianId) {
      this.refresh()
    } else if (
      prevProps.calendar.selectedDate !== calendar.selectedDate ||
      prevProps.calendar.isAppointmentChanged !==
        this.props.calendar.isAppointmentChanged ||
      prevProps.calendar.defaultOpeningTime !== calendar.defaultOpeningTime
    ) {
      this.load()
      this.props.actions.A_IsAppointmentChange(false)
    }
  }

  componentWillUnmount() {
    clearInterval(this.IntervalID)
  }

  getBookedAppointments(newDate) {
    const { calendar, actions, global } = this.props

    const { clinicianId } = this.props.match.params

    let clinic = global.currentClinicID

    if (!newDate) {
      if (this.props) {
        newDate = this.getNewFormattedDate(calendar.selectedDate)
      }
    }

    if (this.props && clinic !== null) {
      actions
        .A_GetPractitionerAppointmentDetails(newDate, clinicianId, clinic)
        .then(response => {
          this.setState({ bookedAppointments: response }, () => {})
        })
    } else {
      this.setState({ bookedAppointments: [] })
    }
  }

  getClinicianSchedules() {
    const {
      actions,
      calendar: { selectedDate },
      global: { timezone },
    } = this.props
    const { clinicianId } = this.props.match.params
    const date = moment(selectedDate).tz(timezone)
    const selectedMonth = date.format('MM')
    const selectedYear = date.format('YYYY')
    const selectedDay = date.format('DD')
    actions.A_FetchClinicScheduleList(
      selectedYear,
      selectedMonth,
      selectedDay,
      clinicianId,
    )
  }

  reload = () => {
    this.getBookedAppointments()
    this.getClinicianSchedules()
  }

  getWeekDayNumber() {
    const { calendar } = this.props
    let formatdate = this.getNewFormattedDate(calendar.selectedDate)
    let dayNumber = dateFns.getDay(new Date(formatdate)) + 1

    return dayNumber
  }

  setTimeSlots() {
    const { default_appointment_length, actions } = this.props
    var startTime = new Date()
    startTime.setHours(0, 0, 0, 0)
    var interval = default_appointment_length
    var endTime = new Date()
    endTime.setHours(23, 59, 0, 0)
    var timeslots = []

    // to include 00:00
    timeslots.push(startTime)

    while (startTime.getTime() < endTime.getTime()) {
      startTime = dateFns.addMinutes(startTime, interval)
      timeslots.push(startTime)
    }
    actions.A_SetTimeSlots(timeslots)
  }

  render() {
    const {
      calendar,
      default_appointment_length,
      global,
      clinician,
      profile,
    } = this.props

    const timeSlots = profile.timeSlots

    let clinic = global.currentClinicID

    return (
      <section className="main__inner--calendar main__inner--calendar--practitioner">
        <section className="calendar__wrapper calendar__wrapper--practitioner">
          {clinic ? (
            ''
          ) : (
            <div className="timeDivision_outofhours timeDivision_outofhours--top timeDivision_outofhours--noClinic" />
          )}
          <div className="calendar_header">
            <div className="calendar_initial_cell" />
            {clinician ? <ProfileHeader clinician={clinician} /> : ''}
          </div>
          <div
            className="calendar_body calendar_body--practitioner"
            ref={this.appointmentScrollArea}
          >
            <TimeSelector
              calendarType="practitionerCalendar"
              styleProps={this.state.styledProps}
              selectedDate={calendar.appointmentDate}
              currentDate={calendar.defaultDate}
              isPractitionerProfile={true}
              timeSlots={timeSlots}
            />
            <article className="appointment_selector appointment_selector--practitioner">
              <div className="appointment_wrapper appointment_wrapper--practitioner">
                {calendar.defaultDate === calendar.appointmentDate ? (
                  <div
                    className="currentTimeIndicator"
                    id="currentTimeProfile"
                    style={{ top: this.state.styledProps.currentTimePos }}
                  />
                ) : (
                  ''
                )}
                <TimeDividerContainer
                  calendarType="practitionerCalendar"
                  practitionerSlots={timeSlots}
                  practitionerDetails={clinician}
                  defaultAppointmentLength={default_appointment_length}
                  outOfHoursTopHeight={this.state.outOfHoursTopHeight}
                  outOfHoursBottomHeight={this.state.outOfHoursBottomHeight}
                />
                {clinician ? (
                  <div className="appointment_column_wrapper">
                    <TimeDivider
                      styleProps={this.state.styledProps.currentTimePos}
                      defaultAppointmentLength={default_appointment_length}
                      clinician={clinician}
                      bookedAppointments={this.state.bookedAppointments}
                      calendarType="practitionerCalendar"
                      load={this.reload}
                    />
                  </div>
                ) : null}
              </div>
            </article>
          </div>
        </section>
      </section>
    )
  }
}

ProfileCalendar.defaultProps = {
  appointmentAlerts: [],
}

ProfileCalendar.propTypes = {
  actions: PropTypes.object.isRequired,
  calendar: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  default_appointment_length: PropTypes.number.isRequired,
  clinician: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  clinicAppointment: PropTypes.object.isRequired,
  bookedAppointment: PropTypes.object.isRequired,
  appointmentAlerts: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    calendar: state.calendar,
    profile: state.practitionerProfile,
    clinician: state.practitionerProfile.clinician,
    default_appointment_length: state.practitionerProfile.clinician
      ? state.practitionerProfile.clinician.default_appointment_length
      : 15,
    global: state.global,
    clinicAppointment: state.clinicAppointment,
    bookedAppointment: state.bookedAppointment.data,
    appointmentAlerts: state.bookedAppointment.alerts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetPractitionerAppointmentDetails,
        A_GetClinicianDetails,
        A_SetAppointmentOpeningTime,
        A_IsAppointmentChange,
        A_SetTimeSlots,
        A_GetClinicsList,
        A_FetchClinicScheduleList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileCalendar),
)
