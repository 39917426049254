import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_GetClinicsList,
  A_GetPatientDetail,
  A_UpdatePatientDetail,
} from '../../actions'
import MainButton from '../../components/Buttons'
import Loader from '../../components/PatientData/Loader'
import { TutorialContext } from '../../contexts'
import TutorialBtn from '../../containers/Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'
import TextArea from '../../components/forms/Textarea'

class PatientNotes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditMode: false,
      patientEditData: null,
      isLoading: true,
      isClinicUnavailable: false,
    }
  }

  /*  Send the patch request and handle the response  */
  submitForm = () => {
    const { actions, patient, global } = this.props

    let clinic = global.currentClinicID

    actions
      .A_UpdatePatientDetail(clinic, patient.id, this.state.patientEditData)
      .then(() => {
        const { patient } = this.props

        this.setState({
          patientEditData: {
            id: patient.id,
            patient_notes: patient.patient_notes,
          },
          isEditMode: false,
        })
      })
  }

  /*  Reset the data  */
  cancelEditMode = () => {
    const { patient } = this.props
    this.setState({
      patientEditData: {
        id: patient.id,
        patient_notes: patient.patient_notes,
      },
      isEditMode: false,
      isLoading: false,
    })
  }

  /*  Switch to editable mode  */
  setEditMode = () => {
    const editMode = !this.state.isEditMode
    this.setState({
      isEditMode: editMode,
    })
  }

  /*  Show correct footer for view and edit modes  */
  getFooterButtons = () => {
    let buttonList = []
    if (this.state.isEditMode) {
      buttonList.push(
        <MainButton
          key="EditButton"
          type="button"
          label="Save changes"
          styleType="primary"
          size="medium"
          margin="right"
          onClick={this.submitForm}
        />,
      )
      buttonList.push(
        <MainButton
          key="CancelButton"
          type="submit"
          label="Cancel"
          styleType="tertiary"
          size="medium"
          onClick={this.cancelEditMode}
        />,
      )
    } else {
      buttonList.push(
        <MainButton
          key="EditButton"
          type="button"
          label="Edit Patient Notes"
          styleType="primary"
          size="medium"
          onClick={this.setEditMode}
        />,
      )
    }
    return buttonList
  }

  componentDidMount = () => {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const { patientId } = this.props.match.params
      const clinic = global.currentClinicID

      if (clinic) {
        actions.A_GetPatientDetail(clinic, patientId).then(() => {
          this.cancelEditMode()
        })
      } else {
        this.setState(() => ({
          isLoading: false,
          isClinicUnavailable: true,
        }))
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        <main className="main">
          {this.state.isLoading ? (
            <Loader />
          ) : this.state.isClinicUnavailable ? (
            ''
          ) : (
            <React.Fragment>
              <section className="main__inner main__inner--spaced">
                {this.state.patientEditData ? (
                  <div className="generalInfo col__12-12">
                    <article className="generalInfo__card">
                      <div className="generalInfo__card__content">
                        {!this.state.isEditMode ? (
                          <div className="generalInfo__card__item generalInfo__card__item--fullWidth">
                            <label className="form__label">Patient Notes</label>
                            <p className="disabledFormEntry">
                              {this.state.patientEditData.patient_notes &&
                              this.state.patientEditData.patient_notes.length >
                                0
                                ? this.state.patientEditData.patient_notes
                                : 'No note added.'}
                            </p>
                          </div>
                        ) : (
                          <div className="generalInfo__card__item generalInfo__card__item--fullWidth">
                            <TextArea
                              rows="10"
                              defaultValue={
                                this.state.patientEditData.patient_notes
                              }
                              label="Patient Notes"
                              placeholder="Add patient notes..."
                              onChange={({ target: { value } }) => {
                                this.setState({
                                  patientEditData: {
                                    ...this.state.patientEditData,
                                    patient_notes: value,
                                  },
                                })
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </article>
                  </div>
                ) : (
                  ''
                )}
              </section>
              <aside className="clinicControls">
                {this.getFooterButtons()}
              </aside>
            </React.Fragment>
          )}
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
        </main>
      </React.Fragment>
    )
  }
}

PatientNotes.defaultProps = {
  patient: null,
}

PatientNotes.propTypes = {
  actions: PropTypes.object.isRequired,
  patient: PropTypes.object,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    patient: state.patients.selectedPatient,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_GetPatientDetail,
        A_UpdatePatientDetail,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientNotes),
)
