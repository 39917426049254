import PropTypes from 'prop-types'

const Credits = ({ credits, formatCredits, companyName }) => (
  <div className="settings--view settings--view-lower">
    <h2>Current Account Credits</h2>
    <p className="settings--view-credits-desc">{`Here is ${companyName ||
      'your clinic group'}'s total account credits. Credits represent how many total SMS messages you have remaining through purchasing bundles.`}</p>
    <div className="settings--view-credits">
      <p className="settings--view-credits-title">Current credits</p>
      <h1 className="settings--view-credits-amount" data-testid="credits">
        {credits ? formatCredits(credits) : 0}
      </h1>
    </div>
  </div>
)

Credits.propTypes = {
  credits: PropTypes.number,
  formatCredits: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
}

Credits.defaultProps = {
  credits: null,
}

export default Credits
