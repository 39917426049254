import { Component } from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../forms/Checkbox'

class CheckboxList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkboxes: props.checkboxes,
    }
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
  }

  handleCheckboxChange = event => {
    const { onChange, formId } = this.props
    let checkboxes = this.state.checkboxes
    let newCheckboxes = checkboxes.map(checkbox => {
      if (event.target.type === 'radio') {
        if (checkbox.checked) {
          checkbox.checked = false
        }
        if (checkbox.id === event.target.id) {
          checkbox.checked = true
        }
      } else {
        let boxId = event.target.id
        if (typeof checkbox.id === 'number') {
          boxId = Number(event.target.id)
        }
        if (checkbox.id === boxId) {
          checkbox.checked = !checkbox.checked
        }
      }
      return checkbox
    })
    this.setState(
      {
        checkboxes: newCheckboxes,
      },
      () => {
        if (onChange) {
          onChange(newCheckboxes, formId)
        }
      },
    )
  }

  getTitleLabel() {
    const { titleLabel } = this.props
    let labels = []
    if (titleLabel.length > 0) {
      labels.push(
        <label key="checkboxListLabel" className="form__label">
          {titleLabel}
        </label>,
      )
    }
    return labels
  }

  render() {
    const { type, inline, labelAbove } = this.props
    const checkboxEls = this.state.checkboxes.map(checkbox => {
      return (
        <Checkbox
          type={type}
          key={checkbox.id}
          id={checkbox.id}
          label={checkbox.label}
          classContainer={checkbox.classContainer}
          isListItem={true}
          checked={checkbox.checked}
          onChange={this.handleCheckboxChange}
        />
      )
    })

    return (
      <>
        {this.getTitleLabel()}
        <ul
          className={`radio__group
          ${inline ? 'radio__group--inline' : ''}
          ${labelAbove ? 'radio__group--labelAbove' : ''}`}
          role="group"
        >
          {checkboxEls}
        </ul>
      </>
    )
  }
}

CheckboxList.defaultProps = {
  formId: '',
  checkboxes: [],
  type: 'checkbox',
  inline: false,
  titleLabel: '',
  onChange: () => {},
  labelAbove: false,
}

CheckboxList.propTypes = {
  formId: PropTypes.string,
  checkboxes: PropTypes.array,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  inline: PropTypes.bool,
  titleLabel: PropTypes.string,
  onChange: PropTypes.func,
  labelAbove: PropTypes.bool,
}

export default CheckboxList
