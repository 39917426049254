import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { A_SetViewingClinic } from '../../actions'
import SelectInput from '../../components/forms/Select'
import { bindActionCreators } from 'redux'
import DatePickerInput from '../../components/forms/datePickerInput'
import DatePickerRangeHHL from '../../components/DatePicker/DatePickerRange'
import moment from 'moment'

class PreOrdersHeader extends Component {
  constructor(props) {
    super(props)
    this.startDateRef = React.createRef()
    this.endDateRef = React.createRef()
    this.dpContainer = React.createRef()
  }
  /*
   *  Set clinic from drop down select change
   */
  changeClinic = event => {
    const {
      actions,
      setClinicID,
      startDate,
      endDate,
      refreshAppointmentsOverview,
    } = this.props
    const clinicID = Number(event.currentTarget.value)
    actions.A_SetViewingClinic(clinicID)

    setClinicID(clinicID)
    refreshAppointmentsOverview(clinicID, startDate, endDate)
  }

  hideDatePicker = day => {
    const {
      clinicID,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      selectedInput,
      setSelectedInput,
      refreshAppointmentsOverview,
    } = this.props

    let start_date = startDate.clone()
    let end_date = endDate.clone()

    if (selectedInput === this.startDateRef) {
      start_date = day ? moment(day) : start_date
      end_date =
        start_date.diff(end_date, 'days') < 0
          ? end_date
          : start_date.clone().add(6, 'days')
      setStartDate(start_date)
      setEndDate(end_date)
    } else {
      end_date = day ? moment(day) : end_date
      start_date =
        end_date.diff(start_date, 'days') < 0
          ? end_date.clone().subtract(6, 'days')
          : start_date
      setStartDate(start_date)
      setEndDate(end_date)
    }

    setSelectedInput(null)
    refreshAppointmentsOverview(clinicID, start_date, end_date)
  }

  render() {
    const {
      global,
      startDate,
      endDate,
      selectedInput,
      setSelectedInput,
      isReviewing,
      setIsReviewing,
    } = this.props

    const clinic = global.currentClinicID
    const clinics = global.filterClinics

    return (
      <>
        <section
          className="calendarActions_wrapper no-print"
          ref={this.dpContainer}
        >
          <article className="timePeriod__wrapper overviewDate__wrapper">
            {isReviewing ? (
              <div
                role="button"
                style={{ display: 'flex', cursor: 'pointer' }}
                tabIndex={0}
                onClick={() => setIsReviewing(false)}
                onKeyDown={() => setIsReviewing(false)}
              >
                <div className="subTopBar__icon">
                  <span className="icon-arrow" />
                </div>
                <span>Back</span>
              </div>
            ) : (
              <>
                <span className="form__label form__label--inline">
                  Pre-order products from
                </span>
                <div className="form__filterSection--dateinput noMargin">
                  <DatePickerInput
                    id="startDate"
                    noLabel={true}
                    value={moment(startDate).format('DD.MM.YYYY')}
                    refVal={this.startDateRef}
                    classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                      selectedInput === this.startDateRef
                        ? 'form__textField--active'
                        : ''
                    }`}
                    onClick={() => {
                      setSelectedInput(this.startDateRef)
                    }}
                  />
                </div>
                <label
                  className="form__label form__label--inline"
                  style={{ marginLeft: '1.25rem' }}
                  htmlFor="endDate"
                >
                  To
                </label>
                <div className="form__filterSection--dateinput noMargin">
                  <DatePickerInput
                    id="endDate"
                    noLabel={true}
                    value={moment(endDate).format('DD.MM.YYYY')}
                    refVal={this.endDateRef}
                    classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                      selectedInput === this.endDateRef
                        ? 'form__textField--active'
                        : ''
                    }`}
                    onClick={() => {
                      setSelectedInput(this.endDateRef)
                    }}
                  />
                </div>
              </>
            )}
          </article>
          {isReviewing ? (
            ''
          ) : (
            <article className="calendarActions">
              <SelectInput
                id="calendarClinicSelect"
                options={clinics}
                defaultSelected={clinic}
                hasOwnWrapper={true}
                label="Clinic"
                isLabelInline={true}
                hasImage={true}
                isInline={true}
                isCalendarHeader={true}
                onChange={this.changeClinic}
              />
            </article>
          )}
        </section>
        {selectedInput ? (
          <DatePickerRangeHHL
            selectedDate={
              selectedInput === this.startDateRef ? startDate : endDate
            }
            startDate={startDate}
            endDate={endDate}
            dpInput={selectedInput.current}
            position="leftTop"
            container={this.dpContainer.current}
            calendarType="manage_schedule_range"
            onHide={this.hideDatePicker}
          />
        ) : (
          ''
        )}
      </>
    )
  }
}

PreOrdersHeader.defaultProps = {
  selectedInput: null,
}

PreOrdersHeader.propTypes = {
  actions: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  clinicID: PropTypes.number.isRequired,
  setClinicID: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  setEndDate: PropTypes.func.isRequired,
  selectedInput: PropTypes.object,
  setSelectedInput: PropTypes.func.isRequired,
  refreshAppointmentsOverview: PropTypes.func.isRequired,
  isReviewing: PropTypes.bool.isRequired,
  setIsReviewing: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  global: state.global,
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_SetViewingClinic,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreOrdersHeader)
