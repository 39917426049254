import { CleverAppointmentModal } from "@clever-plugins/clever-appointment-modal";
import {
  useCloseAppointmentModal,
  useCreatedAppointment,
  useDeletedAppointment,
  useHandleDateChange,
  useHandleMedicalHistory,
  useUpdatedAppointment,
} from "./hooks";
import { useSelector } from "react-redux";


export const AppointmentModalWrapper = ({ history }) => {
  const handleClose = useCloseAppointmentModal();
  const createdAppointment = useCreatedAppointment();
  const updatedAppointment = useUpdatedAppointment();
  const deletedAppointment = useDeletedAppointment();
  const handleDateChange = useHandleDateChange();
  const handleMedicalHistory = useHandleMedicalHistory(history);

  const appointmentModalProps = useSelector((state) => state.appointmentModal);

  return (
    appointmentModalProps.isOpen && (
      <CleverAppointmentModal
        handleCreate={createdAppointment}
        handleUpdate={updatedAppointment}
        handleDelete={deletedAppointment}
        handleDateChange={handleDateChange}
        handleMedicalHistory={handleMedicalHistory}
        {...appointmentModalProps}
        handleClose={handleClose}
        config={{ isDebug: false }}
      />
    )
  );
};
