import React from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../forms/Input'
import SelectInput from '../../forms/Select'
import CheckboxList from '../../forms/CheckboxList'
import MainButton from '../../Buttons'

const SearchHeader = ({
  filteredCount,
  totalCount,
  resultsLegend,
  showResults,
  buttonSet,
  filterSet,
  rightFilter,
  rightFilterId,
  rightFilterLabel,
  rightFilterSelected,
  rightFilterChange,
  wrapperClass,
  clickHandler,
  onFilterChange,
  onChange,
}) => {
  const getButtons = () => {
    const buttons = buttonSet.map((button, index) => {
      return (
        <MainButton
          key={index}
          type={button.type}
          styleType={button.style}
          label={button.label}
          size={button.size}
          onClick={clickHandler}
          customClasses={
            index < buttonSet.length - 1 ? 'modal--spacedButton--left' : ''
          }
          isDisabled={button.isDisabled}
          {...button.events}
        />
      )
    })
    return <article className="searchHeaderActions">{buttons}</article>
  }

  const getFilters = () => {
    return (
      <article className="searchHeaderFilter">
        <CheckboxList
          type="checkbox"
          inline={true}
          checkboxes={filterSet}
          onChange={onFilterChange}
        />
      </article>
    )
  }

  const getRightFilter = () => {
    return (
      <div className="calendarActions">
        <SelectInput
          id={rightFilterId}
          options={rightFilter}
          label={rightFilterLabel}
          isInline={true}
          isLabelInline={true}
          hasImage={true}
          hasOwnWrapper={true}
          onChange={rightFilterChange}
          defaultSelected={rightFilterSelected}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <section className={`searchHeader_wrapper ${wrapperClass}`}>
        <article className="search_wrapper">
          <TextInput
            type="text"
            id="search1"
            placeholder="Search..."
            leftIcon="icon-magnifying-glass"
            noMargin={true}
            handleOnChange={onChange}
            handleOnFocus={e => {
              e.target.setAttribute('autocomplete', 'off')
            }}
          />
          {showResults ? (
            <article className="search_notify">
              <span className="search_notify_results h4">
                {filteredCount} out of {totalCount}{' '}
                <span className="search_notify_results_type">
                  {resultsLegend}
                </span>
              </span>
            </article>
          ) : (
            ''
          )}
        </article>

        {filterSet.length > 0 ? getFilters() : ''}
        {buttonSet.length > 0 ? getButtons() : ''}
        {rightFilter.length > 0 ? getRightFilter() : ''}
      </section>
    </React.Fragment>
  )
}

SearchHeader.defaultProps = {
  filteredCount: 0,
  totalCount: 0,
  resultsLegend: '',
  showResults: false,
  buttonSet: [],
  filterSet: [],
  rightFilterId: 'rightHeaderFilter',
  rightFilterLabel: '',
  rightFilter: [],
  wrapperClass: '',
  rightFilterSelected: 0,
  rightFilterChange: () => {},
  onChange: () => {},
  clickHandler: () => {},
  onFilterChange: () => {},
}

SearchHeader.propTypes = {
  filteredCount: PropTypes.number,
  totalCount: PropTypes.number,
  resultsLegend: PropTypes.string,
  showResults: PropTypes.bool,
  buttonSet: PropTypes.array,
  filterSet: PropTypes.array,
  rightFilterId: PropTypes.string,
  rightFilterLabel: PropTypes.string,
  rightFilter: PropTypes.array,
  wrapperClass: PropTypes.string,
  rightFilterSelected: PropTypes.number,
  rightFilterChange: PropTypes.func,
  onChange: PropTypes.func,
  clickHandler: PropTypes.func,
  onFilterChange: PropTypes.func
}

export default SearchHeader
