import PropTypes from 'prop-types'

export default function IllustrationHelper({ illustration, header, desc }) {
  return (
    <section className="support-illustration--container">
      <img className="support-illustration" src={illustration} alt="" />
      <h3 className="support-illustration--header">{header}</h3>
      <p className="support-illustration--desc">{desc}</p>
    </section>
  )
}

IllustrationHelper.propTypes = {
  illustration: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}
