import PropTypes from 'prop-types'
import { formatCurrency } from '../../../../../utilities/ReusableFunctions'
import { ClinicLocaleTypes } from '../../../../../utilities/ReusableObjects'

const Bundle = props => (
  <div
    tabIndex="0"
    className={`bundle ${props.isActive ? 'bundle-active' : 'bundle'}`}
    onClick={props.onClick}
    onKeyDown={props.onClick}
    role="menuitem"
  >
    <p className="bundle-text">Credits</p>
    <h1
      className={`bundle-credits ${
        props.isActive ? 'bundle-credits-active' : 'bundle-credits'
      }`}
    >
      {props.formatCredit(props.credits)}
    </h1>
    <div className="bundle-cost">
      <p className="bundle-text">COST</p>
      <p
        className={`bundle-text ${
          props.isActive ? 'bundle-text-active' : 'bundle-text'
        }`}
      >
        <strong>{formatCurrency(props.price, ClinicLocaleTypes.en_GB)}</strong>
      </p>
    </div>
    <div
      className={`bundle-button button--small ${
        props.isActive ? 'bundle-button-active' : 'bundle-button'
      }`}
      data-testid="bundle-text"
    >
      {props.isActive ? 'Bundle selected' : 'Select bundle'}
    </div>
  </div>
)

Bundle.propTypes = {
  credits: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  formatCredit: PropTypes.func.isRequired,
}

export default Bundle
