import store from './store'
import * as Sentry from '@sentry/react'
import { TEST_ENVIRONMENT } from './api_urls'
import * as auth from './services/OnBoarding/auth'
import types from './actions/types'
import { useEffect } from 'react'
import {
  matchPath,
} from 'react-router'
import { History } from 'history'

export const useSentry = (history: History) => {
    useEffect(() => {
        if (import.meta.env.VITE_REACT_APP_SENTRY_ENABLED === 'true') {
            let user = auth.getUser()
            const userForSentry = user && user !== 'null' && user.token ? user.user : null
            if (userForSentry !== null) {
              store.dispatch({
                type: types.LOGIN.OK,
                token: user.token,
                user: user.user,
                local: true,
              })
            }
          
            const DEBUG = TEST_ENVIRONMENT !== 'PROD'
            Sentry.init({
              release: import.meta.env.VITE_REACT_APP_SENTRY_VERSION ?? TEST_ENVIRONMENT,
              dsn: 'https://276746209be4447abe18ad4573541118@o374851.ingest.sentry.io/5212171',
              integrations: [
                Sentry.reactRouterV5BrowserTracingIntegration({
                  history,
                  matchPath,
                }),
                // Sentry.replayIntegration(),
                Sentry.metrics.metricsAggregatorIntegration(),
              ],
              initialScope: {
                user: userForSentry,
              },
              // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
              // We recommend adjusting this value in production.
              tracesSampleRate: DEBUG ? 1.0 : 0.1,
              replaysSessionSampleRate: DEBUG ? 1.0 : 0.1,
              environment: TEST_ENVIRONMENT,
              debug: DEBUG,
              autoSessionTracking: true,
            })
          }
    }, [history])
}