import PropTypes from 'prop-types'

const IconTooltip = ({ title }) => {
  return (
    <section className="tooltipWrapper">
      <aside className="tooltip tooltip--icon">
        <p className="noMargin">{title}</p>
      </aside>
    </section>
  )
}

IconTooltip.defaultProps = {
  title: '',
}

IconTooltip.propTypes = {
  title: PropTypes.string,
}

export default IconTooltip
