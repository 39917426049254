import { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import IconTooltip from '../../components/Tooltip/IconTooltip'

class ClinicSideBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      calendarHover: false,
      staffHover: false,
      patientsHover: false,
      clinicsHover: false,
      treatmentsHover: false,
      inventoryHover: false,
      analyticsHover: false,
    }

    this.displayTooltip = this.displayTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
  }

  displayTooltip(title) {
    this.setState(() => ({
      calendarHover: title === 'calendar',
      staffHover: title === 'staff',
      patientsHover: title === 'patients',
      clinicsHover: title === 'clinics',
      treatmentsHover: title === 'treatments',
      inventoryHover: title === 'inventory',
      analyticsHover: title === 'analytics',
    }))
  }

  hideTooltip() {
    this.setState(() => ({
      calendarHover: false,
      staffHover: false,
      patientsHover: false,
      clinicsHover: false,
      treatmentsHover: false,
      inventoryHover: false,
      analyticsHover: false,
    }))
  }

  render() {
    const { location, is_admin } = this.props

    const regex = /\/clinics\/[0-9]/

    const isInClinics =
      regex.test(location.pathname) ||
      location.pathname === '/clinics' ||
      location.pathname === '/clinics/create'

    return (
      <nav className="sidebar no-print">
        <Link to="/clinics/calendar/appointments">
          <article
            className={`sidebar__item ${
              location.pathname.indexOf('/clinics/calendar') === 0
                ? 'sidebar__item--active'
                : ''
            }`}
            onFocus={() => {
              this.displayTooltip('calendar')
            }}
            onMouseOver={() => {
              this.displayTooltip('calendar')
            }}
            onMouseOut={this.hideTooltip}
            onBlur={this.hideTooltip}
          >
            <span
              className={`sidebar__itemIcon nav_icon ${
                location.pathname.indexOf('/clinics/calendar') === 0
                  ? 'nav_icon_calendar--selected'
                  : 'nav_icon_calendar'
              }`}
            />
            {this.state.calendarHover ? <IconTooltip title="Calendar" /> : ''}
          </article>
        </Link>
        <Link to="/clinics/patients">
          <article
            className={`sidebar__item ${
              location.pathname.indexOf('/clinics/patients') === 0
                ? 'sidebar__item--active'
                : ''
            }`}
            onFocus={() => {
              this.displayTooltip('patients')
            }}
            onMouseOver={() => {
              this.displayTooltip('patients')
            }}
            onMouseOut={this.hideTooltip}
            onBlur={this.hideTooltip}
          >
            <span
              className={`sidebar__itemIcon nav_icon ${
                location.pathname.indexOf('/clinics/patients') === 0
                  ? 'nav_icon_patients--selected'
                  : 'nav_icon_patients'
              }`}
            />
            {this.state.patientsHover ? <IconTooltip title="Patients" /> : ''}
          </article>
        </Link>
        <Link to="/clinics/staff">
          <article
            className={`sidebar__item ${
              location.pathname === '/clinics/staff'
                ? 'sidebar__item--active'
                : ''
            }`}
            onFocus={() => {
              this.displayTooltip('staff')
            }}
            onMouseOver={() => {
              this.displayTooltip('staff')
            }}
            onMouseOut={this.hideTooltip}
            onBlur={this.hideTooltip}
          >
            <span
              className={`sidebar__itemIcon nav_icon ${
                location.pathname === '/clinics/staff'
                  ? 'nav_icon_staff--selected'
                  : 'nav_icon_staff'
              }`}
            />
            {this.state.staffHover ? <IconTooltip title="Staff" /> : ''}
          </article>
        </Link>
        <Link to="/clinics">
          <article
            className={`sidebar__item ${
              isInClinics ? 'sidebar__item--active' : ''
            }`}
            onFocus={() => {
              this.displayTooltip('clinics')
            }}
            onMouseOver={() => {
              this.displayTooltip('clinics')
            }}
            onMouseOut={this.hideTooltip}
            onBlur={this.hideTooltip}
          >
            <span
              className={`sidebar__itemIcon nav_icon ${
                isInClinics ? 'nav_icon_clinics--selected' : 'nav_icon_clinics'
              }`}
            />
            {this.state.clinicsHover ? <IconTooltip title="Clinics" /> : ''}
          </article>
        </Link>
        <Link to="/clinics/treatments">
          <article
            className={`sidebar__item ${
              location.pathname.indexOf('/clinics/treatments') === 0
                ? 'sidebar__item--active'
                : ''
            }`}
            onFocus={() => {
              this.displayTooltip('treatments')
            }}
            onMouseOver={() => {
              this.displayTooltip('treatments')
            }}
            onMouseOut={this.hideTooltip}
            onBlur={this.hideTooltip}
          >
            <span
              className={`sidebar__itemIcon nav_icon ${
                location.pathname.indexOf('/clinics/treatments') === 0
                  ? 'nav_icon_treatments--selected'
                  : 'nav_icon_treatments'
              }`}
            />
            {this.state.treatmentsHover ? (
              <IconTooltip title="Treatments" />
            ) : (
              ''
            )}
          </article>
        </Link>
        <Link to="/clinics/inventory">
          <article
            className={`sidebar__item ${
              location.pathname.indexOf('/clinics/inventory') === 0
                ? 'sidebar__item--active'
                : ''
            }`}
            onFocus={() => {
              this.displayTooltip('inventory')
            }}
            onMouseOver={() => {
              this.displayTooltip('inventory')
            }}
            onMouseOut={this.hideTooltip}
            onBlur={this.hideTooltip}
          >
            <span
              className={`sidebar__itemIcon nav_icon ${
                location.pathname.indexOf('/clinics/inventory') === 0
                  ? 'nav_icon_products--selected'
                  : 'nav_icon_products'
              }`}
            />
            {this.state.inventoryHover ? <IconTooltip title="Inventory" /> : ''}
          </article>
        </Link>
        {is_admin ? (
          <Link to="/clinics/analytics/summary">
            <article
              className={`sidebar__item ${
                location.pathname.indexOf('/clinics/analytics') === 0
                  ? 'sidebar__item--active'
                  : ''
              }`}
              onFocus={() => {
                this.displayTooltip('analytics')
              }}
              onMouseOver={() => {
                this.displayTooltip('analytics')
              }}
              onMouseOut={this.hideTooltip}
              onBlur={this.hideTooltip}
            >
              <span
                className={`sidebar__itemIcon nav_icon ${
                  location.pathname.indexOf('/clinics/analytics') === 0
                    ? 'nav_icon_reports--selected'
                    : 'nav_icon_reports'
                }`}
              />
              {this.state.analyticsHover ? (
                <IconTooltip title="Analytics" />
              ) : (
                ''
              )}
            </article>
          </Link>
        ) : (
          ''
        )}
      </nav>
    )
  }
}

ClinicSideBar.propTypes = {
  is_admin: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return { is_admin: state.global.is_admin }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClinicSideBar),
)
