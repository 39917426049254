import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { A_PatchClinicianInvite, A_GetAccount } from '../../actions'

import SettingsController from '../../components/Settings/SettingController'
import ClinicSetting from '../../components/Settings/SettingContent/Clinic'
import SMSSetting from '../../components/Settings/SettingContent/SMS'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'

import { clinicSettings } from '../../utilities/ReusableObjects'
import { isUrlValid } from '../../utilities/ReusableFunctions'
import {
  getBundles,
  patchAccount as patchCallerId,
  addVoucherBundle,
} from '../../services/Bundles'
import { A_UpgradeToken } from '../../actions/auth'
import { upgradeToken } from '../../services/OnBoarding/auth'

const SettingsContainer = ({ account, actions, history, global }) => {
  const [activeSetting, setSetting] = useState({
    ...clinicSettings[0],
  })
  const [activeBundle, setBundle] = useState(null)
  const [bundles, setBundles] = useState([])
  const [credits, setCredits] = useState(account?.sms?.left || 0)
  const [notifMsg, setNotifMsg] = useState({
    status: null,
    message: '',
  })
  const [isFetching, setIsFetching] = useState(false)
  const [updatedCallerId, setCallerId] = useState('')
  const [isTooltipOpen, setTooltip] = useState(false)

  const [errors, setErrors] = useState({})

  const [isAddingVoucher, setIsAddingVoucher] = useState(false)
  const [voucherCode, setVoucherCode] = useState('')

  const [auth, setAuth] = useState()

  useEffect(() => {
    async function fetchToken() {
      const res = await upgradeToken()
      setAuth(res)
      actions.upgradeToken()
    }
    fetchToken()
    return () => actions.upgradeToken()
  }, [actions])

  useEffect(() => {
    if (!global.is_admin) {
      history.push('/clinics/calendar/appointments')
      return
    }

    async function fetchBundlesAndSMS() {
      const bundles = await getBundles()
      setBundles(bundles.results)
      actions.getAccount()
    }
    fetchBundlesAndSMS()
    return () => actions.getAccount()
  }, [actions, global.is_admin, history])

  useEffect(() => setCredits(account?.sms?.left || 0), [account?.sms])

  useEffect(
    () => setCallerId(account?.sms && account?.sms?.TWILIO_DEFAULT_CALLERID),
    [account?.sms, account?.sms?.TWILIO_DEFAULT_CALLERID],
  )

  const updateGroupDetails = async (clinicGroup, clinicSite) => {
    const formattedData = {
      company_name: clinicGroup,
      company_website: isUrlValid(clinicSite),
    }
    const patchDetailsRes = await actions.updateClinicGroup(formattedData)
    if (patchDetailsRes) {
      setNotificationMessage('Successfully updated your clinic group details')
    }
  }

  const updateCallerId = async callerId => {
    setIsFetching(true)
    const callerIdRes = await patchCallerId({
      text_messaging_configuration: {
        TWILIO_DEFAULT_CALLERID: callerId,
      },
    })
    if (callerIdRes.status === 200) {
      setNotificationMessage(
        `Successfully updated your clinic group CallerID`,
        callerIdRes.status,
      )
    } else {
      setNotificationMessage(
        `${callerIdRes.data.TWILIO_DEFAULT_CALLERID[0]}`,
        callerIdRes.status,
      )
    }
  }

  const setNotificationMessage = (message, status) => {
    setNotifMsg({ message: '' })
    setNotifMsg({ status, message })
    setIsFetching(false)
    setIsAddingVoucher(false)
    setVoucherCode('')
  }

  const applyVoucherCode = async voucherCode => {
    setIsAddingVoucher(true)
    const voucherCodeRes = await addVoucherBundle({
      bundle: { voucher_code: voucherCode },
    })
    if (voucherCodeRes.id) {
      setNotificationMessage(
        'Successfully applied voucher code to account',
        voucherCodeRes.status,
      )
      setCredits(credits + voucherCodeRes.bundle.amount)
      actions.getAccount()
    } else {
      setNotificationMessage(
        `${voucherCodeRes.bundle[0]}`,
        voucherCodeRes.status,
      )
    }
  }

  const componentSelector = {
    1: <p>Security component</p>,
    2: (
      <ClinicSetting
        name={activeSetting.title}
        account={account}
        updateGroupDetails={updateGroupDetails}
      />
    ),
    3: (
      <SMSSetting
        name={activeSetting.title}
        credits={credits}
        account={account}
        bundles={bundles}
        updatedCallerId={updatedCallerId}
        setTooltip={setTooltip}
        setCallerId={setCallerId}
        isTooltipOpen={isTooltipOpen}
        isFetching={isFetching}
        updateCallerId={updateCallerId}
        activeBundle={activeBundle}
        setBundle={setBundle}
        errors={errors}
        setErrors={setErrors}
        voucherCode={voucherCode}
        setVoucherCode={setVoucherCode}
        applyVoucherCode={applyVoucherCode}
        isAddingVoucher={isAddingVoucher}
        token={auth}
      />
    ),
  }

  const notifStatusChecker = {
    200: false,
    400: true,
  }

  return (
    <main className="main">
      <section className="main__inner">
        <div className="settings--container">
          {notifMsg.message && (
            <FeedbackNotification
              message={notifMsg.message}
              iserrorAlert={notifStatusChecker[notifMsg.status]}
            />
          )}
          <SettingsController
            activeSetting={activeSetting}
            setSetting={setSetting}
            clinicSettings={clinicSettings}
          />
          {componentSelector[activeSetting.id]}
        </div>
      </section>
    </main>
  )
}

SettingsContainer.defaultProps = {
  account: {},
}

SettingsContainer.propTypes = {
  account: PropTypes.object,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateClinicGroup: A_PatchClinicianInvite,
      getAccount: A_GetAccount,
      upgradeToken: A_UpgradeToken,
    },
    dispatch,
  ),
})

const mapStateToProps = ({ auth: { account }, global }) => ({
  account,
  global,
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer)
