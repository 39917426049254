import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { A_GetClinicsList } from '../../actions'

import {
  appointmentPurposeIds,
  appointmentReasonIds,
  ClinicLocaleTypes,
} from '../../utilities/ReusableObjects'
import { getFullName } from '../../utilities/ReusableFunctions'

import Placeholder from '../../components/Tutorials/Placeholder'
import { getTasks } from '../../services/OnBoarding/auth'
import {
  getAppointmentsOverview,
  getPreOrderType,
  getOutstandingOrders,
  preOrderProducts,
} from '../../services/WeeklyCalendar/CalendarWeeklyService'
import PreOrdersHeader from './PreOrdersHeader'
import PreOrdersAppointments from './PreOrdersAppointments'
import PreOrdersReview from './PreOrdersReview'
import moment from 'moment'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'

const CalendarPreOrdersContainer = props => {
  useEffect(() => {
    const { actions, formatTasks } = props

    async function fetchTasks() {
      const tasks = await getTasks()
      formatTasks(tasks)
    }

    actions.A_GetClinicsList()

    fetchTasks()
    fetchPreOrderType()
    refreshAppointmentsOverview(clinicID, startDate, endDate)
  }, [])

  const [clinicID, setClinicID] = useState(props.clinicID)
  const [selectedInput, setSelectedInput] = useState(null)
  const [startDate, setStartDate] = useState(
    moment
      .tz('UTC')
      .add(1, 'week')
      .startOf('isoWeek'),
  )
  const [endDate, setEndDate] = useState(
    moment
      .tz('UTC')
      .add(1, 'week')
      .startOf('isoWeek')
      .add(6, 'days'),
  )
  const [isLoading, setIsLoading] = useState(true)
  const [appointments, setAppointments] = useState([])
  const [visibleClinician, setVisibleClinician] = useState(0)
  const [treatmentsForOrdering, setTreatmentsForOrdering] = useState([])
  const [visibleProduct, setVisibleProduct] = useState(0)
  const [visibleTreatment, setVisibleTreatment] = useState(0)
  const [productsToOrder, setProductsToOrder] = useState([])

  const [isReviewing, setIsReviewing] = useState(false)
  const [viewOrderBy, setViewOrderBy] = useState('Patient')
  const [isOrdering, setIsOrdering] = useState(false)
  const [hasCreatedOrder, setHasCreatedOrder] = useState(false)

  const [preOrderType, setPreOrderType] = useState({ locale: '' })
  const [outstandingOrders, setOutstandingOrders] = useState({
    count: 0,
    results: [],
  })

  const fetchPreOrderType = async () => {
    const { history } = props

    const order_type = await getPreOrderType(clinicID).catch(response => {
      if (response.status === 404) {
        history.push('/clinics/calendar/appointments')
      }
    })

    setPreOrderType(order_type)

    if (
      order_type !== undefined &&
      order_type.locale === ClinicLocaleTypes.en_GB
    ) {
      fetchOutstandingOrders()
    }
  }

  const fetchOutstandingOrders = async () => {
    const outstanding_orders = await getOutstandingOrders(clinicID)

    setOutstandingOrders(outstanding_orders)
  }

  const availableClinicians = () => {
    const { clinicians } = props
    return clinicians.filter(clinician => {
      const clinicianAppointments = appointments.filter(
        ({ clinician: { id } }) => id === clinician.id,
      )

      const validAppointments = clinicianAppointments.filter(
        ({ treatments }) =>
          treatments.find(({ products }) => products && products.length > 0) !==
          undefined,
      )

      return validAppointments.length > 0
    })
  }

  const filterClinicians = () => {
    return availableClinicians().map(clinician => {
      return {
        id: clinician.id,
        title: getFullName(clinician),
      }
    })
  }

  const refreshAppointmentsOverview = async (
    clinicID,
    start_date,
    end_date,
  ) => {
    setIsLoading(true)
    setAppointments([])
    setTreatmentsForOrdering([])
    setProductsToOrder([])

    const appointments = await getAppointmentsOverview(clinicID, {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD'),
    })

    setIsLoading(false)
    setAppointments(appointments)

    const clinicianIds = availableClinicians().map(({ id }) => id)
    if (
      (clinicianIds.includes(visibleClinician) === false &&
        clinicianIds.length > 0) ||
      (visibleClinician === 0 && clinicianIds.length > 0)
    ) {
      updateWithClinician(clinicianIds[0])
    } else if (visibleClinician !== 0) {
      updateWithClinician(visibleClinician)
    }
  }

  const sendToHealthxchange = async () => {
    setIsOrdering(true)

    await preOrderProducts(clinicID, {
      epharm_patient_orders: productsToOrder
        .filter(
          ({ prescriptions }) =>
            prescriptions.filter(({ quantity }) => quantity > 0).length > 0,
        )
        .map(order => {
          return {
            ...order,
            prescriptions: order.prescriptions.filter(
              ({ quantity }) => quantity > 0,
            ),
            first_name: order.record.first_name,
            last_name: order.record.last_name,
            date_of_birth: order.record.date_of_birth,
          }
        }),
    })

    setVisibleProduct(0)
    setVisibleTreatment(0)

    setAppointments([])
    setVisibleClinician(0)

    setIsOrdering(false)
    setIsReviewing(false)

    setHasCreatedOrder(true)

    refreshAppointmentsOverview(clinicID, startDate, endDate)
  }

  const updateWithClinician = clinician_id => {
    setVisibleClinician(clinician_id)

    const clinicianAppointments = appointments.filter(
      ({ clinician: { id } }) => id === clinician_id,
    )

    let allTreatments = []
    let allPatients = []

    clinicianAppointments
      .filter(
        ({ appointment_type }) =>
          appointment_type === appointmentReasonIds.APPOINTMENT,
      )
      .forEach(appointment => {
        const {
          id,
          treatments,
          patient,
          purpose,
          appointment_type,
        } = appointment

        treatments
          .filter(({ products }) => products && products.length > 0)
          .forEach(treatment => {
            let patientRecord = allPatients.find(
              ({ record: { id } }) => id === patient.id,
            )

            if (patientRecord === undefined) {
              patientRecord = {
                appointment: { id: id },
                record: patient,
                prescriptions: [],
                order_type: {
                  id: preOrderType.id,
                },
              }

              allPatients.push(patientRecord)
            }

            let treatmentRecord = allTreatments.find(
              ({ id }) => id === treatment.id,
            )

            if (treatmentRecord === undefined) {
              treatmentRecord = {
                ...treatment,
                patients: [],
                NEW: 0,
                REPEAT: 0,
                REVIEW: 0,
              }
              allTreatments.push(treatmentRecord)
            }

            treatment.products.forEach(product => {
              if (
                patientRecord.prescriptions.find(
                  ({ product: { product_id } }) =>
                    product_id === product.product_id,
                ) === undefined
              ) {
                patientRecord.prescriptions.push({
                  id: 0,
                  title: product.product_description,
                  product: product,
                  quantity: 0,
                  prescription_type: 0,
                })
              }
            })

            let purposeKey

            Object.keys(appointmentPurposeIds).forEach(key => {
              const amount =
                purpose === appointmentPurposeIds[key] &&
                appointment_type === appointmentReasonIds.APPOINTMENT
                  ? 1
                  : 0

              treatmentRecord[key] += amount

              if (amount > 0) {
                purposeKey = key
              }
            })

            if (!treatmentRecord.patients.find(({ id }) => id === patient.id)) {
              treatmentRecord.patients.push({
                ...patient,
                purpose: purposeKey,
                appointment: appointment,
              })
            }
          })
      })

    allTreatments.sort((a, b) => {
      if (a.title > b.title) {
        return 1
      } else if (a.title < b.title) {
        return -1
      } else {
        return 0
      }
    })

    setTreatmentsForOrdering(allTreatments)
    setProductsToOrder(allPatients)
  }

  return (
    <main className="main main--calendar">
      {!props.hasCompletedAllTasks ? (
        <Placeholder activeTasks={props.activeTasks} />
      ) : null}
      <PreOrdersHeader
        clinicID={clinicID}
        setClinicID={setClinicID}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedInput={selectedInput}
        setSelectedInput={setSelectedInput}
        refreshAppointmentsOverview={refreshAppointmentsOverview}
        appointments={appointments}
        isReviewing={isReviewing}
        setIsReviewing={setIsReviewing}
      />
      {isReviewing ? (
        <PreOrdersReview
          appointments={appointments}
          treatmentsForOrdering={treatmentsForOrdering}
          setTreatmentsForOrdering={setTreatmentsForOrdering}
          productsToOrder={productsToOrder}
          setIsReviewing={setIsReviewing}
          viewOrderBy={viewOrderBy}
          setViewOrderBy={setViewOrderBy}
          sendToHealthxchange={sendToHealthxchange}
          isOrdering={isOrdering}
        />
      ) : (
        <PreOrdersAppointments
          isLoading={isLoading}
          clinicians={filterClinicians()}
          appointments={appointments}
          visibleClinician={visibleClinician}
          setVisibleClinician={updateWithClinician}
          treatmentsForOrdering={treatmentsForOrdering}
          visibleProduct={visibleProduct}
          setVisibleProduct={setVisibleProduct}
          visibleTreatment={visibleTreatment}
          setVisibleTreatment={setVisibleTreatment}
          productsToOrder={productsToOrder}
          setProductsToOrder={setProductsToOrder}
          setIsReviewing={setIsReviewing}
          preOrderType={preOrderType}
          startDate={startDate}
          endDate={endDate}
          outstandingOrders={outstandingOrders}
        />
      )}
      {hasCreatedOrder ? (
        <div className="notificationFeedback">
          <FeedbackNotification
            id="feedbackMessage"
            message="Order has been sent to Healthxchange, don't forget to go and sign for it"
            isWarning={false}
            hideFeedback={() => {
              setHasCreatedOrder(false)
            }}
          />
        </div>
      ) : (
        ''
      )}
    </main>
  )
}

CalendarPreOrdersContainer.defaultProps = {
  clinicID: 0,
}

CalendarPreOrdersContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  formatTasks: PropTypes.func.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
  clinicID: PropTypes.number,
  clinicians: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  clinicID: state.global.currentClinicID,
  clinicians: state.global.visibleClinicians,
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalendarPreOrdersContainer),
)
