import { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import ReportsTable from '../../../components/Reports/ReportsTable'
import FinanceFilter from '../../../components/Reports/Finance/FinanceFilter'
import ExportCSV from '../../../components/forms/ExportCSV'
import Loader from '../../../components/PatientData/Loader'

import MainButton from '../../../components/Buttons'
import ChangeColumnModal from '../../../components/Reports/changeColumns'
import Modal from '../../../components/Modal'

import {
  invoiceStatuses,
  invoiceTypes,
  dailyTransactionListcolumns,
} from '../../../utilities/ReusableObjects'
import {
  clinicsForLocale,
  formatFinanceData,
  getPreviousWeekStartEndDays as getDays,
} from '../../../utilities/ReusableFunctions'

import { getDailyTransactions } from '../../../services/Report/Report'
import {
  A_GetBasicDetails,
  A_GetClinicsList,
  A_FetchCliniciansList,
} from '../../../actions'
import Placeholder from '../../../components/Tutorials/Placeholder'
import { getTasks } from '../../../services/OnBoarding/auth'
import { financeReportHeaders } from './headers'

export default function FinanceReportContainer({
  activeTasks,
  hasCompletedAllTasks,
  formatTasks,
}) {
  const [transactions, setTransactions] = useState([])
  const [startDate, setStartDate] = useState(getDays().startDate)
  const [endDate, setEndDate] = useState(getDays().endDate)
  const [isFetching, setFetching] = useState(false)
  const [showColumnModal, setShowColumnModal] = useState(false)
  const [tableColumns, setTableColumns] = useState(financeReportHeaders)
  const [columnList, setColumnList] = useState(dailyTransactionListcolumns)
  const [changeColumnModal, setChangeColumnModal] = useState(null)

  const dispatch = useDispatch()

  const currentClinic = useSelector(({ global: { currentClinic } }) => currentClinic)

  const { locale } = currentClinic

  const locales = useSelector(
    ({
      global: {
        clinics,
        currentClinic: { id, locale },
      },
    }) => clinicsForLocale(clinics, id, locale).locales,
  )

  const clinicId = useSelector(
    ({ global: { currentClinicID } }) => currentClinicID,
  )
  const clinics = useSelector(({ global: { clinics } }) => clinics)
  const clinicians = useSelector(({ global: { filterClinicians } }) => [
    {
      id: '',
      title: 'Show all',
    },
    ...filterClinicians,
  ])

  const allClinicians = useSelector(
    ({ global: { allFilterClinicians } }) => allFilterClinicians,
  )

  let tableWidth = 0

  const [selectedInvoiceStatuses, setSelectedInvoiceStatuses] = useState(
    Object.entries(invoiceStatuses).map(([id, title]) => ({
      id: Number(id),
      title,
      checked: 'checked',
    })),
  )

  const [selectedInvoiceTypes, setSelectedInvoiceTypes] = useState(
    Object.entries(invoiceTypes).map(([id, title]) => ({
      id: Number(id),
      title,
      checked: 'checked',
    })),
  )

  useEffect(() => {
    function fetchDailyTransactions() {
      getTasks().then(tasks => {
        formatTasks(tasks)
      })

      dispatch(A_GetClinicsList())

      if (parseInt(clinicId)) {
        dispatch(A_GetBasicDetails(clinicId))
      }

      dispatch(A_FetchCliniciansList())

      const initialFilters = {
        clinicId: '',
        clinician: '',
        taken_by: '',
        associated_to: '',
        startDate: moment(getDays().startDate).format('YYYY-MM-DD'),
        endDate: moment(getDays().endDate).format('YYYY-MM-DD'),
        paymentType: '',
        cardType: '',
        invoiceStatuses: reduceInvoiceOptions(selectedInvoiceStatuses),
        invoiceTypes: reduceInvoiceOptions(selectedInvoiceTypes),
        locale: locale,
      }
      refereshTransactions(initialFilters)
    }
    fetchDailyTransactions()
  }, [dispatch])

  const clickCheckbox = useCallback((e) => {
    let list = columnList
    if (e) {
      list.forEach(item => {
        item.rows.forEach(ele => {
          if (e.target.id === ele.heading) {
            ele.checked = !ele.checked
          }
        })
      })
      setColumnList(list)

      let modal = (
        <ChangeColumnModal
          data={list}
          checkboxHandler={e => clickCheckbox(e)}
        />
      )

      setChangeColumnModal(modal)
    }
  }, [columnList])

  useEffect(() => {
    let modal = (
      <ChangeColumnModal
        data={columnList}
        checkboxHandler={e => clickCheckbox(e)}
      />
    )

    setChangeColumnModal(modal)
  }, [columnList, clickCheckbox])

  const displayTable = () => {
    tableWidth += tableColumns.reduce((acc, val) => {
      return acc + Number(val.width)
    }, 0)

    let table = (
      <ReportsTable
        data={transactions}
        nodeStructures={tableColumns}
        tableWidth={tableWidth}
      />
    )
    return table
  }

  const reportTableRef = useRef(null)

  const toggleInvoiceStatuses = option => {
    let newOptions = [...selectedInvoiceStatuses]
    let selectedOption = newOptions.find(({ id }) => id === Number(option))
    selectedOption.checked =
      selectedOption.checked === 'checked' ? '' : 'checked'
    setSelectedInvoiceStatuses(newOptions)
  }

  const toggleInvoiceTypes = option => {
    let newOptions = [...selectedInvoiceTypes]
    let selectedOption = newOptions.find(({ id }) => id === Number(option))
    selectedOption.checked =
      selectedOption.checked === 'checked' ? '' : 'checked'
    setSelectedInvoiceTypes(newOptions)
  }

  const invoiceHandlers = {
    selectedInvoiceStatuses,
    toggleInvoiceStatuses,
    selectedInvoiceTypes,
    toggleInvoiceTypes,
  }

  const dateHandlers = {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }

  const filterReport = e => {
    e.preventDefault()
    const {
      target: {
        clinic: { value: clinic },
        clinicianId: { value: clinicianId },
        paymentType: { value: paymentType },
        cardType: { value: cardType },
        takenById: { value: takenById },
        associatedToId: { value: associatedToId },
      },
    } = e
    const filters = {
      clinicId: clinic,
      clinician: clinicianId,
      taken_by: takenById,
      associated_to: associatedToId,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      paymentType: Number(paymentType) ? Number(paymentType) : paymentType,
      cardType: Number(cardType) ? Number(cardType) : cardType,
      invoiceStatuses: reduceInvoiceOptions(selectedInvoiceStatuses),
      invoiceTypes: reduceInvoiceOptions(selectedInvoiceTypes),
      locale:
        e.target.locale === undefined
          ? clinicsForLocale(clinics, clinic, locale).locale
          : e.target.locale.value,
    }

    refereshTransactions(filters)
  }

  const reduceInvoiceOptions = options =>
    options.filter(({ checked }) => checked === 'checked').map(({ id }) => id)

  const refereshTransactions = async filters => {
    setFetching(true)
    const transactions = await getDailyTransactions(filters)
    setFetching(false)
    setTransactions(formatFinanceData(transactions))
  }

  const submitColumnChange = e => {
    let newTableColumn = []

    if (e) {
      tableColumns.forEach(ele => {
        return columnList.forEach(item => {
          return item.rows.forEach(row => {
            if (row.checked && row.heading === ele.value) {
              newTableColumn.push(ele)
            }
          })
        })
      })

      setTableColumns(newTableColumn)

      if (e.target) {
        setShowColumnModal(false)
      }
    }
  }

  return (
    <main className="main">
      <FinanceFilter
        {...invoiceHandlers}
        {...dateHandlers}
        initialClinic={currentClinic}
        allClinics={clinics}
        clinicians={clinicians}
        allClinicians={allClinicians}
        filterReport={filterReport}
        locale={locale}
        locales={locales}
      />
      <section className="main__inner" style={{ overflowY: 'scroll' }}>
        {isFetching ? (
          <Loader />
        ) : (
          <div ref={reportTableRef}>
            {transactions.length ? (
              displayTable()
            ) : (
              <div className="noResults_message--page noResults_message--centered">
                <p className="p noResults_message--page secondaryMarginBottom">
                  No information available for this filter criteria
                </p>
              </div>
            )}
          </div>
        )}
        <aside className="clinicControls">
          <ExportCSV
            label="Export report"
            styleType="primary"
            size="small"
            table={() => reportTableRef.current}
          />
          <span className="button--marginRight" />
          <MainButton
            styleType="tertiary"
            size="small"
            label="Change columns"
            onClick={() => setShowColumnModal(!showColumnModal)}
          />
        </aside>
      </section>
      {showColumnModal ? (
        <Modal
          id="changeColumnModal"
          title="Change report columns"
          closeModal={() => setShowColumnModal(!showColumnModal)}
          rightButtons={[
            {
              type: 'button',
              style: 'tertiary',
              label: 'Close',
              size: 'small',
              events: { onClick: () => setShowColumnModal(!showColumnModal) },
            },
            {
              type: 'button',
              style: 'secondary',
              label: 'Save',
              size: 'small',
              events: { onClick: e => submitColumnChange(e) },
            },
          ]}
        >
          {changeColumnModal}
        </Modal>
      ) : (
        ''
      )}
      {!hasCompletedAllTasks ? <Placeholder activeTasks={activeTasks} /> : null}
    </main>
  )
}

FinanceReportContainer.propTypes = {
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
  formatTasks: PropTypes.func.isRequired,
}
